export default{
    config:[
        {
            id:1,
            title:'CATV集中供电方法和计算',
            html:`<div class="Section0" style="layout-grid:15.6000pt;"><h1 align="center" style="text-align:center;"><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
            mso-bidi-font-family:'Times New Roman';mso-ansi-font-weight:bold;font-size:22.0000pt;
            mso-font-kerning:22.0000pt;">CATV<font face="宋体">集中供电方法和计算</font></span></b><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
            mso-bidi-font-family:'Times New Roman';mso-ansi-font-weight:bold;font-size:22.0000pt;
            mso-font-kerning:22.0000pt;"><o:p></o:p></span></b></h1><p class="MsoNormal"><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(68,68,68);
            letter-spacing:0.0000pt;mso-ansi-font-weight:normal;text-transform:none;
            mso-ansi-font-style:normal;font-size:10.5000pt;mso-font-kerning:1.0000pt;
            background:rgb(255,255,255);mso-shading:rgb(255,255,255);">&nbsp;&nbsp;&nbsp; 在有线电视系统中有很多放大器进行信号放大，提供给用户足够的信号电平，它们大都采用电缆馈送60V交流电集中供给各级放大器工作。集中供电可以集中管理电源，保证电源质量，也可避免区域停电或分散供电电源的不稳定造成的停播或其他故障。</span><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(68,68,68);
            letter-spacing:0.0000pt;mso-ansi-font-weight:normal;text-transform:none;
            mso-ansi-font-style:normal;font-size:10.5000pt;mso-font-kerning:1.0000pt;
            background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><br></span><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(68,68,68);
            letter-spacing:0.0000pt;mso-ansi-font-weight:normal;text-transform:none;
            mso-ansi-font-style:normal;font-size:10.5000pt;mso-font-kerning:1.0000pt;
            background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><br></span><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(68,68,68);
            letter-spacing:0.0000pt;mso-ansi-font-weight:normal;text-transform:none;
            mso-ansi-font-style:normal;font-size:10.5000pt;mso-font-kerning:1.0000pt;
            background:rgb(255,255,255);mso-shading:rgb(255,255,255);">&nbsp; 集中供电电源的负载是线路上的集中供电放大器和同轴电缆的回路电阻，它所能带动放大器的台数取决于线路上集中供电放大器电源的输入特性和系统中放大器级间同轴电缆回路的电阻大小，目前使用的集中供电放大器工作电压一般为AC 30V-65V 50Hz。由于电缆回路电阻的降压作用，离集中供电电源越远的放大器，得到的工作电压越低，集中供电一般有两种方法：1）从前端机房直接馈送AC60V电源传向后级；2）在线路中间插入供电器，使AC60V电源分别向前级和后级馈电。此两种供电方法各有优缺点，下面通过计算来分别说明：</span><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(68,68,68);
            letter-spacing:0.0000pt;mso-ansi-font-weight:normal;text-transform:none;
            mso-ansi-font-style:normal;font-size:10.5000pt;mso-font-kerning:1.0000pt;
            background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><br></span><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(68,68,68);
            letter-spacing:0.0000pt;mso-ansi-font-weight:normal;text-transform:none;
            mso-ansi-font-style:normal;font-size:10.5000pt;mso-font-kerning:1.0000pt;
            background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><br></span><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(68,68,68);
            letter-spacing:0.0000pt;mso-ansi-font-weight:normal;text-transform:none;
            mso-ansi-font-style:normal;font-size:10.5000pt;mso-font-kerning:1.0000pt;
            background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><font face="Tahoma">一、从前端机房直接馈送</font>AC60V电源传向后级。</span><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(68,68,68);
            letter-spacing:0.0000pt;mso-ansi-font-weight:normal;text-transform:none;
            mso-ansi-font-style:normal;font-size:10.5000pt;mso-font-kerning:1.0000pt;
            background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><br></span><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(68,68,68);
            letter-spacing:0.0000pt;mso-ansi-font-weight:normal;text-transform:none;
            mso-ansi-font-style:normal;font-size:10.5000pt;mso-font-kerning:1.0000pt;
            background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><font face="Tahoma">这是一个复杂的电路，不能简单地按欧姆定律来计算，其中，</font>R是放大器级间的电缆回路电阻，通常为7-8Ω，这里以7Ω作为回路电阻进行计算。R1-Rn分别为第一级放大器至第n级放大器的电源输入电压（有效值），II-In分别为第一级放大器至第n级放大器的电源输入电流（有效值）。此时：R1=V1/I1，R2=V2/I2……，Rn=Vn/In，因为现在大量使用的60V供电器是开关稳压电源，故：V1×I1=V2×I2=……Vn×In，由于电缆回路电阻上的电压降V1、V2……Vn依次逐步减小，R1-R2不是一个常数，计算这类网络不能用电阻串、并联来简化，必须由末级起逐步推算。</span><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(68,68,68);
            letter-spacing:0.0000pt;mso-ansi-font-weight:normal;text-transform:none;
            mso-ansi-font-style:normal;font-size:10.5000pt;mso-font-kerning:1.0000pt;
            background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><br></span><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(68,68,68);
            letter-spacing:0.0000pt;mso-ansi-font-weight:normal;text-transform:none;
            mso-ansi-font-style:normal;font-size:10.5000pt;mso-font-kerning:1.0000pt;
            background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><br></span><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(68,68,68);
            letter-spacing:0.0000pt;mso-ansi-font-weight:normal;text-transform:none;
            mso-ansi-font-style:normal;font-size:10.5000pt;mso-font-kerning:1.0000pt;
            background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><font face="Tahoma">我们已知现在集中供电放大器的最低工作电压为</font>30V，以此作为末级（第n级）放大器的供电电压Vn，它的功率为25W，电缆回路电阻R=7Ω，集中供电电源输出电压为AC60V，6A，n级；输入电压Vn=30V，电流：In=25W/30V=0.8A</span><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(68,68,68);
            letter-spacing:0.0000pt;mso-ansi-font-weight:normal;text-transform:none;
            mso-ansi-font-style:normal;font-size:10.5000pt;mso-font-kerning:1.0000pt;
            background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><br></span><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(68,68,68);
            letter-spacing:0.0000pt;mso-ansi-font-weight:normal;text-transform:none;
            mso-ansi-font-style:normal;font-size:10.5000pt;mso-font-kerning:1.0000pt;
            background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><br></span><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(68,68,68);
            letter-spacing:0.0000pt;mso-ansi-font-weight:normal;text-transform:none;
            mso-ansi-font-style:normal;font-size:10.5000pt;mso-font-kerning:1.0000pt;
            background:rgb(255,255,255);mso-shading:rgb(255,255,255);">n-1级：输入电压Vn-1=30V+7Ω×0.8A=35.6A,电流：In-1=25W/35.6V=0.7A</span><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(68,68,68);
            letter-spacing:0.0000pt;mso-ansi-font-weight:normal;text-transform:none;
            mso-ansi-font-style:normal;font-size:10.5000pt;mso-font-kerning:1.0000pt;
            background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><br></span><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(68,68,68);
            letter-spacing:0.0000pt;mso-ansi-font-weight:normal;text-transform:none;
            mso-ansi-font-style:normal;font-size:10.5000pt;mso-font-kerning:1.0000pt;
            background:rgb(255,255,255);mso-shading:rgb(255,255,255);">n-2级：输入电压Vn-2=35.6V+7Ω×(0.8A+0.7A)=46.1V,电流：In-2=25W/46.1V=0.54A</span><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(68,68,68);
            letter-spacing:0.0000pt;mso-ansi-font-weight:normal;text-transform:none;
            mso-ansi-font-style:normal;font-size:10.5000pt;mso-font-kerning:1.0000pt;
            background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><br></span><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(68,68,68);
            letter-spacing:0.0000pt;mso-ansi-font-weight:normal;text-transform:none;
            mso-ansi-font-style:normal;font-size:10.5000pt;mso-font-kerning:1.0000pt;
            background:rgb(255,255,255);mso-shading:rgb(255,255,255);">n-3级：输入电压Vn-3=46.1V+7Ω×(0.8A+0.7A+0.54A)=60V</span><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(68,68,68);
            letter-spacing:0.0000pt;mso-ansi-font-weight:normal;text-transform:none;
            mso-ansi-font-style:normal;font-size:10.5000pt;mso-font-kerning:1.0000pt;
            background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><br></span><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(68,68,68);
            letter-spacing:0.0000pt;mso-ansi-font-weight:normal;text-transform:none;
            mso-ansi-font-style:normal;font-size:10.5000pt;mso-font-kerning:1.0000pt;
            background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><br></span><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(68,68,68);
            letter-spacing:0.0000pt;mso-ansi-font-weight:normal;text-transform:none;
            mso-ansi-font-style:normal;font-size:10.5000pt;mso-font-kerning:1.0000pt;
            background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><font face="Tahoma">这时已经和集中供电器的输出电压一致，说明照这样的供电方法，一个集中供电器可带动</font>4个集中供电器，而总的输出电流为：I=0.8A+0.7A+0.54A+25W/60V=2.4A，这个电流值虽还远小于集中供电器的输出电流值，集中供电器功率余量还很大，似乎还可再连接放大器，但通过计算我们得到；如果在第四级后再连续接一级放大器，Rn-4上的电压Vn-4=60V+7Ω×(0.8A+0.7A+0.54A+0.47A)=77V，通过倒推计算出来的结果，第一级放大器上的电压应该为77V，这个值远大于集中电器的输出电压60V，说明再串接一级放大器，系统将无法正常工作。</span><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(68,68,68);
            letter-spacing:0.0000pt;mso-ansi-font-weight:normal;text-transform:none;
            mso-ansi-font-style:normal;font-size:10.5000pt;mso-font-kerning:1.0000pt;
            background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><br></span><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(68,68,68);
            letter-spacing:0.0000pt;mso-ansi-font-weight:normal;text-transform:none;
            mso-ansi-font-style:normal;font-size:10.5000pt;mso-font-kerning:1.0000pt;
            background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><br></span><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(68,68,68);
            letter-spacing:0.0000pt;mso-ansi-font-weight:normal;text-transform:none;
            mso-ansi-font-style:normal;font-size:10.5000pt;mso-font-kerning:1.0000pt;
            background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><font face="Tahoma">采用该供电方法的优点是能保证电视信号不中断（除非停电），便于维修。缺点是所带的放大器级数少，传输距离短，资源浪费严重。</font></span><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(68,68,68);
            letter-spacing:0.0000pt;mso-ansi-font-weight:normal;text-transform:none;
            mso-ansi-font-style:normal;font-size:10.5000pt;mso-font-kerning:1.0000pt;
            background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><br></span><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(68,68,68);
            letter-spacing:0.0000pt;mso-ansi-font-weight:normal;text-transform:none;
            mso-ansi-font-style:normal;font-size:10.5000pt;mso-font-kerning:1.0000pt;
            background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><br></span><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(68,68,68);
            letter-spacing:0.0000pt;mso-ansi-font-weight:normal;text-transform:none;
            mso-ansi-font-style:normal;font-size:10.5000pt;mso-font-kerning:1.0000pt;
            background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><font face="Tahoma">二、在线路中间插入供电器，使</font>AC60V电源分别向前级和后级馈电。</span><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(68,68,68);
            letter-spacing:0.0000pt;mso-ansi-font-weight:normal;text-transform:none;
            mso-ansi-font-style:normal;font-size:10.5000pt;mso-font-kerning:1.0000pt;
            background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><br></span><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(68,68,68);
            letter-spacing:0.0000pt;mso-ansi-font-weight:normal;text-transform:none;
            mso-ansi-font-style:normal;font-size:10.5000pt;mso-font-kerning:1.0000pt;
            background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><br></span><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(68,68,68);
            letter-spacing:0.0000pt;mso-ansi-font-weight:normal;text-transform:none;
            mso-ansi-font-style:normal;font-size:10.5000pt;mso-font-kerning:1.0000pt;
            background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><font face="Tahoma">这种供电方法是在有线电视主干线电缆的中间插入供电器，按照上面的年算方法可以计算出所连接的放大器的输入电压：</font>Vn′=Vn=30V,Vn-1′=Vn-1=35.6V,Vn-2′=Vn-2=46.1V,Vn-3′=Vn-3=60V。这样看来，从中间向两边馈电的方法所带来的放大器级数比单一的从一端向另一端馈电的方法所带的放大器级数多一倍。而总电流I总=2.4A+2.4A=4.8A，还小于集中供电器的额定输出电流，这样连接既可保证放大器正常工作，又可让集中供电器不过载，充分利用资源，保证整条线路正常工作。</span><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(68,68,68);
            letter-spacing:0.0000pt;mso-ansi-font-weight:normal;text-transform:none;
            mso-ansi-font-style:normal;font-size:10.5000pt;mso-font-kerning:1.0000pt;
            background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><br></span><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(68,68,68);
            letter-spacing:0.0000pt;mso-ansi-font-weight:normal;text-transform:none;
            mso-ansi-font-style:normal;font-size:10.5000pt;mso-font-kerning:1.0000pt;
            background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><br></span><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(68,68,68);
            letter-spacing:0.0000pt;mso-ansi-font-weight:normal;text-transform:none;
            mso-ansi-font-style:normal;font-size:10.5000pt;mso-font-kerning:1.0000pt;
            background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><font face="Tahoma">该供电方法的缺点是调试和维修困难，如果中间一级放大器损坏会导致前后级放大器均无供电，导致一大片用户电视无信号。</font></span><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
            mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></div>`
        },
        {
            id:2,
            title:'PoE技术的两种供电方式哪种好',
            html:`<div class="Section0" style="layout-grid:15.6000pt;"><p class="MsoNormal" style="margin-left:0.0000pt;text-indent:21.0000pt;mso-char-indent-count:0.0000;
            mso-pagination:widow-orphan;text-align:left;line-height:15.7500pt;
            background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(68,68,68);
            letter-spacing:0.0000pt;mso-ansi-font-weight:normal;text-transform:none;
            mso-ansi-font-style:normal;font-size:10.5000pt;mso-font-kerning:0.0000pt;
            background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><font face="Tahoma">我们都知道</font>PoE技术有两种供电方式，即末端跨接法、中间跨接法。但是哪种供电方式好，是一样的吗？估计很多朋友了解不是甚多了。</span><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(68,68,68);
            letter-spacing:0.0000pt;mso-ansi-font-weight:normal;text-transform:none;
            mso-ansi-font-style:normal;font-size:10.5000pt;mso-font-kerning:0.0000pt;
            background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:0.0000pt;text-indent:21.0000pt;mso-char-indent-count:0.0000;
            mso-pagination:widow-orphan;text-align:left;line-height:15.7500pt;
            background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(68,68,68);
            letter-spacing:0.0000pt;mso-ansi-font-weight:normal;text-transform:none;
            mso-ansi-font-style:normal;font-size:10.5000pt;mso-font-kerning:0.0000pt;
            background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><font face="Tahoma">中间跨接法：使用以太网电缆中没有被使用的空闲线对来传输直流电。</font></span><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(68,68,68);
            letter-spacing:0.0000pt;mso-ansi-font-weight:normal;text-transform:none;
            mso-ansi-font-style:normal;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:0.0000pt;text-indent:0.0000pt;mso-pagination:widow-orphan;
            text-align:left;line-height:15.7500pt;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(68,68,68);
            letter-spacing:0.0000pt;mso-ansi-font-weight:normal;text-transform:none;
            mso-ansi-font-style:normal;font-size:10.5000pt;mso-font-kerning:0.0000pt;
            background:rgb(255,255,255);mso-shading:rgb(255,255,255);">&nbsp; &nbsp;&nbsp; 末端跨接法：在传输数据所用的芯线上同时传输直流电,其输电采用与以太 网数据信号不同的频率。</span><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(68,68,68);
            letter-spacing:0.0000pt;mso-ansi-font-weight:normal;text-transform:none;
            mso-ansi-font-style:normal;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:0.0000pt;text-indent:0.0000pt;mso-pagination:widow-orphan;
            text-align:left;line-height:15.7500pt;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(68,68,68);
            letter-spacing:0.0000pt;mso-ansi-font-weight:normal;text-transform:none;
            mso-ansi-font-style:normal;font-size:10.5000pt;mso-font-kerning:0.0000pt;
            background:rgb(255,255,255);mso-shading:rgb(255,255,255);">&nbsp; &nbsp;&nbsp; &nbsp;无论是中间跨接法，还是末端跨接法都是符合国际标准的PoE供电方式，传输效果都是一样的有保障。伴随着千兆网络应用面逐步扩大，再加上，百兆网络中的4578线常常用作其它用途，安防监控行业大多厂家采用1236即传输网络信号又传输直流电源的末端跨接法来供电，因此末端跨接法应用更方便，匹配度更广，如丰润达PoE交换机。</span><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(68,68,68);
            letter-spacing:0.0000pt;mso-ansi-font-weight:normal;text-transform:none;
            mso-ansi-font-style:normal;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:0.0000pt;text-indent:0.0000pt;mso-pagination:widow-orphan;
            text-align:left;line-height:15.7500pt;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(68,68,68);
            letter-spacing:0.0000pt;mso-ansi-font-weight:normal;text-transform:none;
            mso-ansi-font-style:normal;font-size:10.5000pt;mso-font-kerning:0.0000pt;
            background:rgb(255,255,255);mso-shading:rgb(255,255,255);">&nbsp; &nbsp;&nbsp; &nbsp; 目前主流的PSE设备包括丰润达公司默认采用末端跨接法，而PD设备同时支持中间跨接法和末端跨接法。</span><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(68,68,68);
            letter-spacing:0.0000pt;mso-ansi-font-weight:normal;text-transform:none;
            mso-ansi-font-style:normal;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal"><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
            mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;"><o:p>&nbsp;</o:p></span></p></div>`
        }, 
        {
            id:3,
            title:'安防监控从入门到精通技术学习资料',
            html:`<div class="Section0" style="layout-grid:15.6000pt;"><p class="MsoNormal"><span style="mso-spacerun:'yes';font-family:ˎ̥;color:rgb(0,0,255);
            font-size:9.0000pt;mso-font-kerning:1.0000pt;"><font face="ˎ̥">监控初学者学习园地</font></span><span style="mso-spacerun:'yes';font-family:ˎ̥;color:rgb(0,0,255);
            font-size:9.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal"><span style="mso-spacerun:'yes';font-family:ˎ̥;color:rgb(0,0,255);
            font-size:9.0000pt;mso-font-kerning:1.0000pt;"><o:p>&nbsp;</o:p></span></p><p class="MsoNormal"><span style="mso-spacerun:'yes';font-family:ˎ̥;color:rgb(0,0,255);
            font-size:9.0000pt;mso-font-kerning:1.0000pt;"><font face="ˎ̥">监控系统常用设备介绍</font></span><span style="mso-spacerun:'yes';font-family:ˎ̥;color:rgb(0,0,255);
            font-size:9.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal"><span style="mso-spacerun:'yes';font-family:ˎ̥;color:rgb(0,0,255);
            font-size:9.0000pt;mso-font-kerning:1.0000pt;"><font face="ˎ̥">①云台　　云台就是两个交流电组成的安装平台，可以水平和垂直的运动。我们所说的云台区别于照相器材中的云台，照相器材的云台一般来说只是一个三脚架，只能通过手来调节方位；而监控系统所说的云台是通过控制系统在远端可以控制其转动方向的。 云台有多种类型： 按使用环境分为室内型和室外型，主要区别是室外型密封性能好，防水、防尘，负载大。 按安装方式分为侧装和吊装，即云台是安装在天花板上还是安装在墙壁上。 按外形分为普通型和球型，球型云台是把云台安置在一个半球形、球形防护罩中，除了防止灰尘干扰图像外，还隐蔽、美观、快速。 在挑选云台时要考虑安装环境、安装方式、工作电压、负载大小，也要考虑性能价格比和外型是否美观。</font></span><span style="mso-spacerun:'yes';font-family:ˎ̥;color:rgb(0,0,255);
            font-size:9.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal"><span style="mso-spacerun:'yes';font-family:ˎ̥;color:rgb(0,0,255);
            font-size:9.0000pt;mso-font-kerning:1.0000pt;"><o:p>&nbsp;</o:p></span></p><p class="MsoNormal"><span style="mso-spacerun:'yes';font-family:ˎ̥;color:rgb(0,0,255);
            font-size:9.0000pt;mso-font-kerning:1.0000pt;"><font face="ˎ̥">②支架　　如果摄像机只是固定监控某个位置不需要转动，那么只用摄像机支架就可以满足要求了。普通摄像机支架安装简单，价格低廉，而且种类繁多。 普通支架有短的、长的、直的、弯的，根据不同的要求选择不同的型号。室外支架主要考虑负载能力是否合乎要求，再有就是安装位置，因为从实践中我们发现，很多室外摄像机安装位置特殊，有的安装在电线杆上，有的立于塔吊上，有的安装在铁架上……由于种种原因，现有的支架可能难以满足要求，需要另外加工或改进，这里就不再多说了。</font></span><span style="mso-spacerun:'yes';font-family:ˎ̥;color:rgb(0,0,255);
            font-size:9.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal"><span style="mso-spacerun:'yes';font-family:ˎ̥;color:rgb(0,0,255);
            font-size:9.0000pt;mso-font-kerning:1.0000pt;"><o:p>&nbsp;</o:p></span></p><p class="MsoNormal"><span style="mso-spacerun:'yes';font-family:ˎ̥;color:rgb(0,0,255);
            font-size:9.0000pt;mso-font-kerning:1.0000pt;">&nbsp;<font face="ˎ̥">③防护罩　　防护罩也是监控系统中最常用的设备之一，主要分为室内和室外两种。室内防护罩主要区别是体积大小，外形是否美观，表面处理是否合格。功能主要是防尘、防破坏。 室外防护罩密封性能一定要好，保证雨水不能进入防护罩内部侵蚀摄像机。有的室外防护罩还带有排风扇、加热板、雨刮器，可以更好的保护设备。当天气太热时，排风扇自动工作；太冷时加热板自动工作；当防护罩玻璃上有雨水时，可以通过控制系统启动雨刮器。 挑选防护罩时先看整体结构，安装孔越少越利于防水，再看内部线路是否便于联接，最后还要考虑外观、重量、安装座等等。</font></span><span style="mso-spacerun:'yes';font-family:ˎ̥;color:rgb(0,0,255);
            font-size:9.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal"><span style="mso-spacerun:'yes';font-family:ˎ̥;color:rgb(0,0,255);
            font-size:9.0000pt;mso-font-kerning:1.0000pt;"><o:p>&nbsp;</o:p></span></p><p class="MsoNormal"><span style="mso-spacerun:'yes';font-family:ˎ̥;color:rgb(0,0,255);
            font-size:9.0000pt;mso-font-kerning:1.0000pt;">&nbsp;<font face="ˎ̥">④监视器　　监视器是监控系统的标准输出，有了监视器我们才能观看前端送过来的图像。监视器分彩色、黑白两，尺寸有9、10、12、14、15、17、21英寸等，常用的是14英寸。 监视器也有分辨率，同摄像机一样用线数表示，实际使用时一般要求监视器线数要与摄像机匹配。 另外，有些监视器还有音频输入、S-video输入、RGB分量输入等，除了音频输入监控系统用到外，其余功能大部分用于图像处理工作，在此不作介绍。</font></span><span style="mso-spacerun:'yes';font-family:ˎ̥;color:rgb(0,0,255);
            font-size:9.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal"><span style="mso-spacerun:'yes';font-family:ˎ̥;color:rgb(0,0,255);
            font-size:9.0000pt;mso-font-kerning:1.0000pt;"><o:p>&nbsp;</o:p></span></p><p class="MsoNormal"><span style="mso-spacerun:'yes';font-family:ˎ̥;color:rgb(0,0,255);
            font-size:9.0000pt;mso-font-kerning:1.0000pt;">&nbsp;<font face="ˎ̥">⑤视频放大器 　　当视频传输距离比较远时，最好采用线径较粗的视频线，同时可以在线路内增加视频放大器增强信号强度达到远距离传输目的。视频放大器可以增强视频的亮度、色度和同步信号，但线路内干扰信号也会被放大，另外，回路中不能串接太多视频放大器，否则会出现饱和现象，导致图像失真。</font></span><span style="mso-spacerun:'yes';font-family:ˎ̥;color:rgb(0,0,255);
            font-size:9.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal"><span style="mso-spacerun:'yes';font-family:ˎ̥;color:rgb(0,0,255);
            font-size:9.0000pt;mso-font-kerning:1.0000pt;"><o:p>&nbsp;</o:p></span></p><p class="MsoNormal"><span style="mso-spacerun:'yes';font-family:ˎ̥;color:rgb(0,0,255);
            font-size:9.0000pt;mso-font-kerning:1.0000pt;">&nbsp;<font face="ˎ̥">⑥视频分配器　　一路视频信号对应一台监视器或录像机，若想一台摄像机的图像送给多个管理者看，最好选择视频分配器。因为并联视频信号衰减较大，送给多个输出设备后由于阻抗不匹配等原因，图像会严重失真，线路也不稳定。 视频分配器除了阻抗匹配，还有视频增益，使视频信号可以同时送给多个输出设备而不受影响。</font></span><span style="mso-spacerun:'yes';font-family:ˎ̥;color:rgb(0,0,255);
            font-size:9.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal"><span style="mso-spacerun:'yes';font-family:ˎ̥;color:rgb(0,0,255);
            font-size:9.0000pt;mso-font-kerning:1.0000pt;"><o:p>&nbsp;</o:p></span></p><p class="MsoNormal"><span style="mso-spacerun:'yes';font-family:ˎ̥;color:rgb(0,0,255);
            font-size:9.0000pt;mso-font-kerning:1.0000pt;"><font face="ˎ̥">⑦视频切换器　　多路视频信号要送到同一处监控，可以一路视频对应一台监视器，但监视器占地大，价格贵，如果不要求时时刻刻监控，可以在监控室增设一台切换器，把摄像机输出信号接到切换器的输入端，切换器的输出端接监视器，切换器的输入端分为2、4、 6、8、12、16路，输出端分为单路和双路，而且还可以同步切换音频（视型号而定）。 切换器有手动切换、自动切换两种工作方式，手动方式是想看哪一路就把开关拨到哪一路；自动方式是让预设的视频按顺序延时切换，切换时间通过一个旋钮可以调节，一般在1秒到35秒之间。 切换器的价格便宜（一般只有三五百元），联接简单，操作方便，但在一个时间段内只能看输入中的一个图像。要在一台监视器上同时观看多个摄像机图像，就需要用画面分割器。</font></span><span style="mso-spacerun:'yes';font-family:ˎ̥;color:rgb(0,0,255);
            font-size:9.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal"><span style="mso-spacerun:'yes';font-family:ˎ̥;color:rgb(0,0,255);
            font-size:9.0000pt;mso-font-kerning:1.0000pt;"><o:p>&nbsp;</o:p></span></p><p class="MsoNormal"><span style="mso-spacerun:'yes';font-family:ˎ̥;color:rgb(0,0,255);
            font-size:9.0000pt;mso-font-kerning:1.0000pt;"><font face="ˎ̥">⑧画面分割器　　画面分割器有四分割、九分割、十六分割几种，可以在一台监视器上同时显示 4、9、16个摄像机的图像，也可以送到录像机上记录。 四分割是最常用的设备之一，其性能价格比也较好，图像的质量和连续性可以满足大部分要求。九分割和十六分割价格较贵，而且分割后每路图像的分辨率和连续性都会下降，录像效果不好。 另外还有六分割、八分割、双四分割设备，但图像比率、清晰度、连续性并不理想，市场使用率更小。 大部分分割器除了可以同时显示图像外，也可以显示单幅画面，可以叠加时间和字符，设置自动切换，联接报警器材。</font></span><span style="mso-spacerun:'yes';font-family:ˎ̥;color:rgb(0,0,255);
            font-size:9.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal"><span style="mso-spacerun:'yes';font-family:ˎ̥;color:rgb(0,0,255);
            font-size:9.0000pt;mso-font-kerning:1.0000pt;"><o:p>&nbsp;</o:p></span></p><p class="MsoNormal"><span style="mso-spacerun:'yes';font-family:ˎ̥;color:rgb(0,0,255);
            font-size:9.0000pt;mso-font-kerning:1.0000pt;"><font face="ˎ̥">⑨录像机　　监控系统中最常用的记录设备是民用录像机和长延时录像机，因其操作简单易学，录像带也容易保存和购买。 与家用录像机不同，延时录像机可以长时间工作，可以录制24小时（用普通VHS 录像带）甚至上百小时的图像，可以联接报警器材，收到报警信号自动启动录像，可以叠加时间日期，可以编制录像机自动录像程序，选择录像速度，录像带到头后是自动停止还是倒带重录…… 延时录像机的性能虽然出众，但价格不菲，而且目前分辨率不是很高，在延时录像时图像也会丢失一部分，回放的图像是一顿一顿跳跃的。</font></span><span style="mso-spacerun:'yes';font-family:ˎ̥;color:rgb(0,0,255);
            font-size:9.0000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:ˎ̥;color:rgb(0,0,255);
            font-size:9.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal"><b><span style="mso-spacerun:'yes';font-family:ˎ̥;color:rgb(0,0,255);
            font-weight:bold;font-size:9.0000pt;mso-font-kerning:1.0000pt;"><font face="ˎ̥">初学者园地之弱电系统与应用电视（一）</font></span></b><b><span style="mso-spacerun:'yes';font-family:ˎ̥;color:rgb(0,0,255);
            font-weight:bold;font-size:9.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></b></p><p class="MsoNormal"><span style="mso-spacerun:'yes';font-family:ˎ̥;color:rgb(0,0,255);
            font-size:9.0000pt;mso-font-kerning:1.0000pt;"><font face="ˎ̥">第一章</font> &nbsp;弱电系统与应用电视系统</span><span style="mso-spacerun:'yes';font-family:ˎ̥;color:rgb(0,0,255);
            font-size:9.0000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:ˎ̥;color:rgb(0,0,255);
            font-size:9.0000pt;mso-font-kerning:1.0000pt;"><font face="ˎ̥">&nbsp; &nbsp; 所谓弱电是针对电力、照明用电相对而言的。通常情况下，把电力、照明用的电能称为强电；而把传播信号、进行信息交换的电能称为弱电。强电的处理对象是能源（电力），其特点是电压高、电流大、功耗大、频率低，主要考虑的问题是减小损耗、提高效率；弱点的处理对象主要是信息，即信息的传送与控制，其特点是电压低、电流小、功率小、频率高，主要考虑的问题是信息传送的效果，如保真度、速度、广度和可靠性等。</font></span><span style="mso-spacerun:'yes';font-family:ˎ̥;color:rgb(0,0,255);
            font-size:9.0000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:ˎ̥;color:rgb(0,0,255);
            font-size:9.0000pt;mso-font-kerning:1.0000pt;"><font face="ˎ̥">第一节、弱电系统的内容与分类</font></span><span style="mso-spacerun:'yes';font-family:ˎ̥;color:rgb(0,0,255);
            font-size:9.0000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:ˎ̥;color:rgb(0,0,255);
            font-size:9.0000pt;mso-font-kerning:1.0000pt;"><font face="ˎ̥">一、火灾自动报警与自动灭火系统</font></span><span style="mso-spacerun:'yes';font-family:ˎ̥;color:rgb(0,0,255);
            font-size:9.0000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:ˎ̥;color:rgb(0,0,255);
            font-size:9.0000pt;mso-font-kerning:1.0000pt;"><font face="ˎ̥">&nbsp; &nbsp; 该系统也称火灾自动报警与联动控制系统，或火灾自动报警与消防控制系统，是通过安装在现场的各种火灾探测器对现场进行监控，一旦发生火灾警情产生报警并联动相应的灭火、疏散、广播等设备，达到预防火灾的目的。</font></span><span style="mso-spacerun:'yes';font-family:ˎ̥;color:rgb(0,0,255);
            font-size:9.0000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:ˎ̥;color:rgb(0,0,255);
            font-size:9.0000pt;mso-font-kerning:1.0000pt;"><font face="ˎ̥">二、通信系统</font></span><span style="mso-spacerun:'yes';font-family:ˎ̥;color:rgb(0,0,255);
            font-size:9.0000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:ˎ̥;color:rgb(0,0,255);
            font-size:9.0000pt;mso-font-kerning:1.0000pt;"><font face="ˎ̥">&nbsp; &nbsp; 随着信息时代、知识经济时代的到来，自动控制技术、计算机技术和通信技术的高度发展对建筑物内的信息系统的建设祈祷了很大的推动作用，真正实现建筑物自动化（BA）、通信自动化（CA）、办公自动化（OA）以及住宅自动化（HA），而独立系统之间必然要通过各种方式（如局域网）连接起来，共享所积累的数据资源，转送和处理数据，并由通信系统与外界公用通信网连接，形成综合通信系统。</font></span><span style="mso-spacerun:'yes';font-family:ˎ̥;color:rgb(0,0,255);
            font-size:9.0000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:ˎ̥;color:rgb(0,0,255);
            font-size:9.0000pt;mso-font-kerning:1.0000pt;"><font face="ˎ̥">三、电缆电视和卫星电视接收系统</font></span><span style="mso-spacerun:'yes';font-family:ˎ̥;color:rgb(0,0,255);
            font-size:9.0000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:ˎ̥;color:rgb(0,0,255);
            font-size:9.0000pt;mso-font-kerning:1.0000pt;"><font face="ˎ̥">&nbsp; &nbsp; 电缆电视和卫星电视接收的应用和推广是为了解决大城市高层建筑或电视信号覆盖区外的边远地区因电视信号反射或评比严重而影响电视信号良好接收问题和丰富节目内容而设置的。</font></span><span style="mso-spacerun:'yes';font-family:ˎ̥;color:rgb(0,0,255);
            font-size:9.0000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:ˎ̥;color:rgb(0,0,255);
            font-size:9.0000pt;mso-font-kerning:1.0000pt;"><font face="ˎ̥">&nbsp; &nbsp; 电缆电视系统（Cable Television），缩写CATV，是由早期的功用天线电视系统（Community Antenna Television）发展而来，开始时是以共用一组接收天线的系统传送，后来发展到以闭路形式或以有线传输方法传送各种电视信号，尤其是扩宽到卫星直播电视节目的接收、微波中继、录像和摄像、自办节目等，使CATV系统合成信息社会综合信息网的组成部分。</font></span><span style="mso-spacerun:'yes';font-family:ˎ̥;color:rgb(0,0,255);
            font-size:9.0000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:ˎ̥;color:rgb(0,0,255);
            font-size:9.0000pt;mso-font-kerning:1.0000pt;"><font face="ˎ̥">&nbsp; &nbsp; 电缆电视系统也是属于应用电视系统，但是由于与当前使用的以监视控制为目的的电视监控系统从传输的信号方式到应用范围都有很大的差别，因此现在已经单独作为一个弱电系统出现。</font></span><span style="mso-spacerun:'yes';font-family:ˎ̥;color:rgb(0,0,255);
            font-size:9.0000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:ˎ̥;color:rgb(0,0,255);
            font-size:9.0000pt;mso-font-kerning:1.0000pt;"><font face="ˎ̥">四、扩音与音响系统</font></span><span style="mso-spacerun:'yes';font-family:ˎ̥;color:rgb(0,0,255);
            font-size:9.0000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:ˎ̥;color:rgb(0,0,255);
            font-size:9.0000pt;mso-font-kerning:1.0000pt;"><font face="ˎ̥">&nbsp; &nbsp; 扩音与音响系统基本上有三种类型：一是公共广播（PA），属于有线广播系统，包括背景音乐和紧急广播功能；二是厅堂扩音系统；三是专用的会议系统</font></span><span style="mso-spacerun:'yes';font-family:ˎ̥;color:rgb(0,0,255);
            font-size:9.0000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:ˎ̥;color:rgb(0,0,255);
            font-size:9.0000pt;mso-font-kerning:1.0000pt;"><font face="ˎ̥">五、安全防范系统</font></span><span style="mso-spacerun:'yes';font-family:ˎ̥;color:rgb(0,0,255);
            font-size:9.0000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:ˎ̥;color:rgb(0,0,255);
            font-size:9.0000pt;mso-font-kerning:1.0000pt;"><font face="ˎ̥">&nbsp; &nbsp; 安全防范系统的全称为公共安全防范系统，是以保护人身财产安全、信息与通讯安全，达到损失预防与犯罪预防目的。</font></span><span style="mso-spacerun:'yes';font-family:ˎ̥;color:rgb(0,0,255);
            font-size:9.0000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:ˎ̥;color:rgb(0,0,255);
            font-size:9.0000pt;mso-font-kerning:1.0000pt;"><font face="ˎ̥">㈠防盗报警系统</font></span><span style="mso-spacerun:'yes';font-family:ˎ̥;color:rgb(0,0,255);
            font-size:9.0000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:ˎ̥;color:rgb(0,0,255);
            font-size:9.0000pt;mso-font-kerning:1.0000pt;"><font face="ˎ̥">&nbsp; &nbsp; 防盗报警系统是通过安装在防护现场的各种入侵探测器对所保护的区域进行人员活动的探测（入侵），一旦发现有入侵行为将产生报警信息，以达到防盗的目的。</font></span><span style="mso-spacerun:'yes';font-family:ˎ̥;color:rgb(0,0,255);
            font-size:9.0000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:ˎ̥;color:rgb(0,0,255);
            font-size:9.0000pt;mso-font-kerning:1.0000pt;"><font face="ˎ̥">㈡电视监控系统</font></span><span style="mso-spacerun:'yes';font-family:ˎ̥;color:rgb(0,0,255);
            font-size:9.0000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:ˎ̥;color:rgb(0,0,255);
            font-size:9.0000pt;mso-font-kerning:1.0000pt;"><font face="ˎ̥">&nbsp; &nbsp; 电视监控系统是以图像监视为手段，对现场图像进行实时监视与录像。监视监控系统可以让保安人员直观地掌握现场情况，并能够通过录像回放进行分析。电视监控系统是应用电视系统的重要组成部分，也是安防系统的重要组成部分。当前电视监控系统已经与防盗报警系统有机地结合到一起，形成一个更为可靠的监控系统。</font></span><span style="mso-spacerun:'yes';font-family:ˎ̥;color:rgb(0,0,255);
            font-size:9.0000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:ˎ̥;color:rgb(0,0,255);
            font-size:9.0000pt;mso-font-kerning:1.0000pt;"><font face="ˎ̥">㈢出入口控制系统</font></span><span style="mso-spacerun:'yes';font-family:ˎ̥;color:rgb(0,0,255);
            font-size:9.0000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:ˎ̥;color:rgb(0,0,255);
            font-size:9.0000pt;mso-font-kerning:1.0000pt;"><font face="ˎ̥">&nbsp; &nbsp; 出入口控制系统又称门禁系统，其功能是控制人员的出入，还能控制人员在防范区域内的活动。在防范区域内，必须使用各类卡片、密码或通过生物识别技术经控制装置识别确认，才能通过。停车场管理系统实际上也属于出入口控制系统。</font></span><span style="mso-spacerun:'yes';font-family:ˎ̥;color:rgb(0,0,255);
            font-size:9.0000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:ˎ̥;color:rgb(0,0,255);
            font-size:9.0000pt;mso-font-kerning:1.0000pt;"><font face="ˎ̥">㈣楼宇保安对讲系统</font></span><span style="mso-spacerun:'yes';font-family:ˎ̥;color:rgb(0,0,255);
            font-size:9.0000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:ˎ̥;color:rgb(0,0,255);
            font-size:9.0000pt;mso-font-kerning:1.0000pt;"><font face="ˎ̥">&nbsp; &nbsp; 楼宇保安对讲系统为访客与室内人员提供双向通话或可视通话、遥控开锁以及报警功能。</font></span><span style="mso-spacerun:'yes';font-family:ˎ̥;color:rgb(0,0,255);
            font-size:9.0000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:ˎ̥;color:rgb(0,0,255);
            font-size:9.0000pt;mso-font-kerning:1.0000pt;"><font face="ˎ̥">㈤电子巡更系统</font></span><span style="mso-spacerun:'yes';font-family:ˎ̥;color:rgb(0,0,255);
            font-size:9.0000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:ˎ̥;color:rgb(0,0,255);
            font-size:9.0000pt;mso-font-kerning:1.0000pt;"><font face="ˎ̥">&nbsp; &nbsp; 在大型楼宇或场院中，出入口很多，来往人员复杂，必须有专人巡逻，较为重要的地点应设巡更站，定期进行巡逻。电子巡更系统是保安人员在规定的巡逻路线上，在指定的时间和地点向中心控制室发回信号以示正常。</font></span><span style="mso-spacerun:'yes';font-family:ˎ̥;color:rgb(0,0,255);
            font-size:9.0000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:ˎ̥;color:rgb(0,0,255);
            font-size:9.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal"><span style="mso-spacerun:'yes';font-family:ˎ̥;color:rgb(0,0,255);
            font-size:9.0000pt;mso-font-kerning:1.0000pt;"><o:p>&nbsp;</o:p></span></p><p class="MsoNormal"><span style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;
            color:rgb(0,0,255);font-size:10.5000pt;mso-font-kerning:1.0000pt;"><o:p>&nbsp;</o:p></span></p><p class="MsoNormal"><span style="mso-spacerun:'yes';font-family:ˎ̥;color:rgb(0,0,255);
            font-size:9.0000pt;mso-font-kerning:1.0000pt;"><o:p>&nbsp;</o:p></span></p></div>`
        }, 
        {
            id:4,
            title:'防盗报警系统培训资料',
            html:`<div class="Section0" style="layout-grid:15.6000pt;"><p class="MsoNormal" style="margin-left:18.0000pt;text-indent:-18.0000pt;mso-list:l6 level1 lfo1;"><!--[if !supportLists]--><span style="font-family:宋体;mso-ansi-font-weight:bold;font-size:12.0000pt;
            mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">1、<span>&nbsp;</span></span></span><!--[endif]--><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:宋体;mso-ansi-font-weight:bold;
            font-size:12.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">防盗报警系统（电子防盗报警系统）</font></span></b><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:宋体;mso-ansi-font-weight:bold;
            font-size:12.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></b></p><p class="MsoNormal" style="text-indent:20.0000pt;mso-char-indent-count:2.0000;"><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">防盗系统是在</font></span><i style="mso-bidi-font-style:normal"><u><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,255);
            text-decoration:underline;text-underline:single;mso-ansi-font-style:italic;
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">无人值守</font></span></u></i><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">的地方，通过</font></span><i style="mso-bidi-font-style:normal"><u><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,255);
            text-decoration:underline;text-underline:single;mso-ansi-font-style:italic;
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">电子探测技术</font></span></u></i><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">判断非法入侵行为，然后自动在</font></span><i style="mso-bidi-font-style:normal"><u><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,255);
            text-decoration:underline;text-underline:single;mso-ansi-font-style:italic;
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">现场发出警报</font></span></u></i><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">，并同时</font></span><i style="mso-bidi-font-style:normal"><u><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,255);
            text-decoration:underline;text-underline:single;mso-ansi-font-style:italic;
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">自动传达警情</font></span></u></i><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">到专门处理警情的</font></span><i style="mso-bidi-font-style:normal"><u><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,255);
            text-decoration:underline;text-underline:single;mso-ansi-font-style:italic;
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">报警中心</font></span></u></i><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">。一个完整的区域性防盗系统由</font></span><i style="mso-bidi-font-style:normal"><u><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,255);
            text-decoration:underline;text-underline:single;mso-ansi-font-style:italic;
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">用户端报警系统</font></span></u></i><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">和</font></span><i style="mso-bidi-font-style:normal"><u><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,255);
            text-decoration:underline;text-underline:single;mso-ansi-font-style:italic;
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">报警中心</font></span></u></i><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">两大部分组成。用户端报警系统包括</font></span><i style="mso-bidi-font-style:normal"><u><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,255);
            text-decoration:underline;text-underline:single;mso-ansi-font-style:italic;
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">输入</font>(探测器)</span></u></i><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">、</font></span><i style="mso-bidi-font-style:normal"><u><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,255);
            text-decoration:underline;text-underline:single;mso-ansi-font-style:italic;
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">控制</font>(报警控制/通讯主机)</span></u></i><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">、</font></span><i style="mso-bidi-font-style:normal"><u><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,255);
            text-decoration:underline;text-underline:single;mso-ansi-font-style:italic;
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">输出</font>(警号闪灯/电话联网)</span></u></i><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">三个部分；报警中心端是</font></span><i style="mso-bidi-font-style:normal"><u><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,255);
            text-decoration:underline;text-underline:single;mso-ansi-font-style:italic;
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">专门接收处理警情</font></span></u></i><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">的地方，一般利用</font></span><i style="mso-bidi-font-style:normal"><u><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,255);
            text-decoration:underline;text-underline:single;mso-ansi-font-style:italic;
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">公共电话网</font></span></u></i><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">传输报警信号，包括</font></span><i style="mso-bidi-font-style:normal"><u><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,255);
            text-decoration:underline;text-underline:single;mso-ansi-font-style:italic;
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">数字接警机</font></span></u></i><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">和警情管理软件。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal"><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p>&nbsp;</o:p></span></p><p class="MsoNormal" style="margin-left:18.0000pt;text-indent:-18.0000pt;mso-list:l6 level1 lfo1;"><!--[if !supportLists]--><span style="font-family:宋体;mso-ansi-font-weight:bold;font-size:12.0000pt;
            mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">2、<span>&nbsp;</span></span></span><!--[endif]--><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:宋体;mso-ansi-font-weight:bold;
            font-size:12.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">有线防盗报警系统</font></span></b><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:宋体;mso-ansi-font-weight:bold;
            font-size:12.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></b></p><p class="MsoNormal" style="margin-left:17.9500pt;mso-para-margin-left:1.7100gd;text-indent:20.0000pt;
            mso-char-indent-count:2.0000;"><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">是指</font></span><i style="mso-bidi-font-style:normal"><u><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,255);
            text-decoration:underline;text-underline:single;mso-ansi-font-style:italic;
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">探测器和报警主机</font></span></u></i><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">之间采用的是</font></span><i style="mso-bidi-font-style:normal"><u><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,255);
            text-decoration:underline;text-underline:single;mso-ansi-font-style:italic;
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">有线传输</font></span></u></i><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">的方式。同时报警主机与上一级接警中心也采用的是有线传输方式。适合于被警戒的现场与值班中心的报警主机距离不太远的情况。一般应在房屋建筑设计时预先考虑安装线路的铺设。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:-18.0000pt;mso-list:l6 level5 lfo1;"><!--[if !supportLists]--><span style="font-family:Wingdings;mso-fareast-font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">l<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">利用</font></span><i style="mso-bidi-font-style:normal"><u><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,255);
            text-decoration:underline;text-underline:single;mso-ansi-font-style:italic;
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">专线传输</font></span></u></i><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">的有线报警系统：在探测器、报警主机、上一级接警中心之间铺设专用的传输线路，来完成所有信息的传输。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:-18.0000pt;mso-list:l6 level5 lfo1;"><!--[if !supportLists]--><span style="font-family:Wingdings;mso-fareast-font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">l<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">利用</font></span><i style="mso-bidi-font-style:normal"><u><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,255);
            text-decoration:underline;text-underline:single;mso-ansi-font-style:italic;
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">公用电话网传输</font></span></u></i><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">的联网报警系统：在探测器、报警主机之间一般还是铺设专用的传输线路，但在报警主机与上一级接警中心之间的传输线路则是利用现有的公用电话通讯网。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal"><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p>&nbsp;</o:p></span></p><p class="MsoNormal" style="margin-left:18.0000pt;text-indent:-18.0000pt;mso-list:l6 level1 lfo1;"><!--[if !supportLists]--><span style="font-family:宋体;mso-ansi-font-weight:bold;font-size:12.0000pt;
            mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">3、<span>&nbsp;</span></span></span><!--[endif]--><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:宋体;mso-ansi-font-weight:bold;
            font-size:12.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">无线防盗报警系统</font></span></b><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:宋体;mso-ansi-font-weight:bold;
            font-size:12.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></b></p><p class="MsoNormal" style="margin-left:17.9500pt;mso-para-margin-left:1.7100gd;text-indent:20.0000pt;
            mso-char-indent-count:2.0000;"><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">是指探测器和报警主机之间采用</font></span><i style="mso-bidi-font-style:normal"><u><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,255);
            text-decoration:underline;text-underline:single;mso-ansi-font-style:italic;
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">无线通信的方式</font></span></u></i><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">将电信号连通，即借助空间电磁波来传输电信号。如果报警主机需要向上一级接警中心发送报警信号时，同样也可以采用这种类似的传输方式。无线报警系统特别适合于在监控点较多，防范现场的分布又较分散、较远或不便架设传输线的场所采用。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:-18.0000pt;mso-list:l6 level5 lfo1;"><!--[if !supportLists]--><span style="font-family:Wingdings;mso-fareast-font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">l<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">无线报警接收机和无线报警发射机共同的技术指标</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:54.0000pt;text-indent:-18.0000pt;mso-list:l8 level1 lfo2;"><!--[if !supportLists]--><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">①<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">无线通讯方式，即调制与解调的方式。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:54.0000pt;text-indent:-18.0000pt;mso-list:l8 level1 lfo2;"><!--[if !supportLists]--><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">②<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">报警频点，即工作频率（</font>MHz）。</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:54.0000pt;text-indent:-18.0000pt;mso-list:l8 level1 lfo2;"><!--[if !supportLists]--><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">③<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">频率准确度（</font>±xxKHz）：工作频率偏离规定工作频率的程度。</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:54.0000pt;text-indent:-18.0000pt;mso-list:l8 level1 lfo2;"><!--[if !supportLists]--><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">④<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">频率稳定度（</font>±xxPPM）：工作过程中，振荡器保持频率不变的能力。</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:-18.0000pt;mso-list:l6 level5 lfo1;"><!--[if !supportLists]--><span style="font-family:Wingdings;mso-fareast-font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">l<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">无线报警接收机的主要技术指标</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:54.0000pt;text-indent:-18.0000pt;mso-list:l1 level1 lfo3;"><!--[if !supportLists]--><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">①<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">灵敏度（微伏</font>—µV）：可以触发接收机发出报警信号的最小输入电压信号。</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:54.0000pt;text-indent:-18.0000pt;mso-list:l1 level1 lfo3;"><!--[if !supportLists]--><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">②<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">控制距离（米</font>m或公里km）：正常工作下，发射机与接收机之间的最大无线传输距离。</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:54.0000pt;text-indent:-18.0000pt;mso-list:l1 level1 lfo3;"><!--[if !supportLists]--><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">③<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">最大容量：无线报警系统中可接入无线报警发射机的最大数量或路数。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:54.0000pt;text-indent:-18.0000pt;mso-list:l1 level1 lfo3;"><!--[if !supportLists]--><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">④<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">可显示的报警类型的种类。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:-18.0000pt;mso-list:l6 level5 lfo1;"><!--[if !supportLists]--><span style="font-family:Wingdings;mso-fareast-font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">l<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">无线报警发射机的主要技术指标</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:0.0000pt;mso-list:l6 level6 lfo1;"><!--[if !supportLists]--><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">①<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">报警方式：触点开或触点闭报警。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:0.0000pt;mso-list:l6 level6 lfo1;"><!--[if !supportLists]--><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">②<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">发射功率（瓦</font>—W）。</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:0.0000pt;mso-list:l6 level6 lfo1;"><!--[if !supportLists]--><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">③<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">探测器输入接口的个数和要求：可接探测器的数量及所接探测器的类型（如红外、微波、烟感等）。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:0.0000pt;mso-list:l6 level6 lfo1;"><!--[if !supportLists]--><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">④<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">静态警戒电流，即在非报警时的工作电流（</font>mA）。</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:0.0000pt;mso-list:l6 level6 lfo1;"><!--[if !supportLists]--><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">⑤<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">发射工作电流，即在报警时的工作电流（</font>mA）。</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:0.0000pt;mso-list:l6 level6 lfo1;"><!--[if !supportLists]--><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">⑥<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">发射机可向探测器提供的电源。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal"><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p>&nbsp;</o:p></span></p><p class="MsoNormal" style="margin-left:18.0000pt;text-indent:-18.0000pt;mso-list:l6 level1 lfo1;"><!--[if !supportLists]--><span style="font-family:宋体;mso-ansi-font-weight:bold;font-size:12.0000pt;
            mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">4、<span>&nbsp;</span></span></span><!--[endif]--><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:宋体;mso-ansi-font-weight:bold;
            font-size:12.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">基本概念</font></span></b><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:宋体;mso-ansi-font-weight:bold;
            font-size:12.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></b></p><p class="MsoNormal" style="margin-left:18.0000pt;"><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(255,0,0);
            mso-ansi-font-weight:bold;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">探测器（</font>Detector）</span></b><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:宋体;mso-ansi-font-weight:bold;
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">：</font></span></b><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">自动通过</font></span><i style="mso-bidi-font-style:normal"><u><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,255);
            text-decoration:underline;text-underline:single;mso-ansi-font-style:italic;
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">电子手段侦测</font></span></u></i><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">各类异常情况，并且把得到的</font></span><i style="mso-bidi-font-style:normal"><u><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,255);
            text-decoration:underline;text-underline:single;mso-ansi-font-style:italic;
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">信号（一般为开关信号）</font></span></u></i><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">送往报警主机处理的器件。如</font></span><i style="mso-bidi-font-style:normal"><u><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,255);
            text-decoration:underline;text-underline:single;mso-ansi-font-style:italic;
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">被动红外探头</font></span></u></i><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">、</font></span><i style="mso-bidi-font-style:normal"><u><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,255);
            text-decoration:underline;text-underline:single;mso-ansi-font-style:italic;
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">门磁开关</font></span></u></i><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">、紧急按钮等。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:18.0000pt;"><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(255,0,0);
            mso-ansi-font-weight:bold;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">报警主机（</font>Control Panel）</span></b><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:宋体;mso-ansi-font-weight:bold;
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">：</font></span></b><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">通常又称为报警控制</font>/通讯主机。在报警系统中，处理探测器的信号，并且使用键盘等手段提供布撤防操作去控制系统，在报警时可以提供声或光提示，同时还可以（通过电话线）将警情传送到报警中心。</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:18.0000pt;"><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(255,0,0);
            mso-ansi-font-weight:bold;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">防区（</font>Zone）</span></b><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:宋体;mso-ansi-font-weight:bold;
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">：</font></span></b><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">每个防区是指一个在防盗系统中可以</font></span><i style="mso-bidi-font-style:normal"><u><span style="mso-spacerun:'yes';font-family:宋体;text-decoration:underline;
            text-underline:single;mso-ansi-font-style:italic;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">识别的位置</font></span></u></i><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;
            mso-font-kerning:0.0000pt;"><font face="宋体">（</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:0.0000pt;"><font face="宋体">单独识别的一个最小单位</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;
            mso-font-kerning:0.0000pt;"><font face="宋体">）</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">。每一个防盗系统都包含有多个探测器，这些探测器连接在防盗系统中构成</font></span><i style="mso-bidi-font-style:normal"><u><span style="mso-spacerun:'yes';font-family:宋体;text-decoration:underline;
            text-underline:single;mso-ansi-font-style:italic;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">防区</font></span></u></i><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">。每一个防区需设置为一种</font></span><i style="mso-bidi-font-style:normal"><u><span style="mso-spacerun:'yes';font-family:宋体;text-decoration:underline;
            text-underline:single;mso-ansi-font-style:italic;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">防区类型</font></span></u></i><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">来对探测器的动作作出特定的反应。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:0.0000pt;mso-list:l6 level5 lfo1;"><!--[if !supportLists]--><span style="font-family:Wingdings;mso-fareast-font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">l<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;">&nbsp;</span><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,255);
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">有线扩充</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">（</font>Wired）：即主机外加防区扩充板，从板上接入常规四线制探头（即开关信号输出的探头），从而达到增加防区的目的。该扩充方法只是单纯增加防区数量，而不会给系统增加功能。</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:0.0000pt;mso-list:l6 level5 lfo1;"><!--[if !supportLists]--><span style="font-family:Wingdings;mso-fareast-font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">l<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;">&nbsp;</span><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,255);
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">无线扩充</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">（</font>Wireless）：主机外加无线接收机，使用配套的无线探头。使用该种扩充方法，可以达到安装简单、不破坏原建筑的目的，还可以利用无线按钮达到遥控开关、遥控布撤防的功能。</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:0.0000pt;mso-list:l6 level5 lfo1;"><!--[if !supportLists]--><span style="font-family:Wingdings;mso-fareast-font-family:宋体;color:rgb(0,0,255);
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">l<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,255);
            font-size:10.0000pt;mso-font-kerning:1.0000pt;">&nbsp;<font face="宋体">总线扩充</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">（</font>Polling Loop）：主机可直接接入两线总线制探头（编址信号输出，电源与信号共用2芯线），而且所有总线制探头采用并联方式连接；又能使用“总线扩充器</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;">”</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">的编码器，接入常规四线制探头。该种扩充方法使得接入线更为简洁，尤其适合于防区数目多和长距离传输信号的大系统中。</font></span><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:宋体;mso-ansi-font-weight:bold;
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></b></p><p class="MsoNormal" style="margin-left:18.0000pt;"><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(255,0,0);
            mso-ansi-font-weight:bold;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">防区类型（</font>Zone Type）</span></b><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:宋体;mso-ansi-font-weight:bold;
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">：</font></span></b><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">各种探测器安装在不同位置，根据其探测原理可以起到不同的保护作用。按照用户的实际情况，可以分成多种</font></span><i style="mso-bidi-font-style:normal"><u><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,255);
            text-decoration:underline;text-underline:single;mso-ansi-font-style:italic;
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">防护类型</font></span></u></i><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">来构成对用户的可靠保护，这些防护类型在系统中的设置就是防区类型。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:0.0000pt;mso-list:l6 level5 lfo1;"><!--[if !supportLists]--><span style="font-family:Wingdings;mso-fareast-font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">l<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;">&nbsp;</span><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,255);
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">出入防区</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">：在布防后外出延时结束时生效，在外出延时期间，探测器触发后不产生报警。在触发该防区时，有进入延时，必须在进入延时结束前对系统进行撤防，否则会发出报警。控制器会在进入延时时间里发出蜂鸣（作为撤防系统的提示信号）。用于主要出入口处（如正门、主要入口）。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:0.0000pt;mso-list:l6 level5 lfo1;"><!--[if !supportLists]--><span style="font-family:Wingdings;mso-fareast-font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">l<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;">&nbsp;</span><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,255);
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">周边防区</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">：布防后有效，没有延时。触发后立即发出报警。用于周边场所（如外部门窗或围墙）。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:0.0000pt;mso-list:l6 level5 lfo1;"><!--[if !supportLists]--><span style="font-family:Wingdings;mso-fareast-font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">l<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;">&nbsp;</span><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,255);
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">内部防区（跟随）</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">：外出布防后有效，留守布防无效。如果出入防区未首先触发，该防区触发后会立即报警；否则要延时结束后才报警。使用该防区的前提是必须要有出入防区，否则采用具有延时的内部防区类型。用于出入防区首先触发而需要进入延时的地方（如大厅、过厅）。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:0.0000pt;mso-list:l6 level5 lfo1;"><!--[if !supportLists]--><span style="font-family:Wingdings;mso-fareast-font-family:宋体;color:rgb(0,0,255);
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">l<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,255);
            font-size:10.0000pt;mso-font-kerning:1.0000pt;">&nbsp;<font face="宋体">内部防区（延时）</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">：外出布防后有效，留守布防无效。不论出入防区是否被首先触发，该防区触发后都要在延时结束后才报警。用于室内空间的保护。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:0.0000pt;mso-list:l6 level5 lfo1;"><!--[if !supportLists]--><span style="font-family:Wingdings;mso-fareast-font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">l<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;">&nbsp;</span><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,255);
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">日夜防区</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">：</font>24小时处于警戒状态。在撤防状态下（白天），触发该防区键盘会发出快速蜂鸣并显示防区号与检查显示（如果需要可向中心站报告），但不产生报警；在布防状态下（夜晚），触发该防区会发出报警，控制键盘和外部警号会发出警报，通讯设备也会报告警情。不提供延时。用于装有薄箔保护的门、窗（如商店），或“敏感”地区，如商品库、药品仓库等，或其他需要密切注意入口的控制进入区。</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:0.0000pt;mso-list:l6 level5 lfo1;"><!--[if !supportLists]--><span style="font-family:Wingdings;mso-fareast-font-family:宋体;color:rgb(0,0,255);
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">l<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,255);
            font-size:10.0000pt;mso-font-kerning:1.0000pt;">&nbsp;<font face="宋体">24小时防区（无声）</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">：无论布防与否，</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:0.0000pt;"><font face="宋体">这种防区都处于有效保护状态，</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">触发后立即报警，但不会显示防区号，也不会发出警报。用于紧急按钮（如银行、珠宝柜台、办公桌）。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:0.0000pt;"><font face="宋体">这些报警只有有效的密码持有人才可能清除。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:0.0000pt;mso-list:l6 level5 lfo1;"><!--[if !supportLists]--><span style="font-family:Wingdings;mso-fareast-font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">l<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;">&nbsp;</span><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,255);
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">24小时防区（有声）</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">：无论布防与否，</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:0.0000pt;"><font face="宋体">这种防区都处于有效保护状态，</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">触发后引发外接警号警报，在键盘报警及显示。用于紧急按钮（如床边应急报警）。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:0.0000pt;"><font face="宋体">这些报警只有有效的密码持有人才可能清除。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:0.0000pt;mso-list:l6 level5 lfo1;"><!--[if !supportLists]--><span style="font-family:Wingdings;mso-fareast-font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">l<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;">&nbsp;</span><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,255);
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">24小时防区（辅助）</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">：无论布防与否，触发后仅提供键盘有声报警及显示，而外接警号不发声。用于个人突发事件使用的紧急按钮（医疗急救报警）或各类紧急事件（如水传感器、温度传感器）。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:0.0000pt;mso-list:l6 level5 lfo1;"><!--[if !supportLists]--><span style="font-family:Wingdings;mso-fareast-font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">l<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;">&nbsp;</span><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,255);
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">火警防区</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">：触发时发出火警信号，键盘显示防区号，并触发外接警号发出特别的报警声，同时向中心站报告。该防区在撤防时也有效，同时不提供延时。用于装有烟雾探测器、热探测器或紧急按钮的</font>24小时设防的区域。</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:17.9500pt;mso-para-margin-left:1.7100gd;mso-layout-grid-align:none;
            text-autospace:none;text-align:left;"><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:AdobeSongStd-Light-Acro;
            color:rgb(255,0,0);mso-ansi-font-weight:bold;font-size:10.0000pt;
            mso-font-kerning:0.0000pt;"><font face="宋体">外出延时</font></span></b><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:AdobeSongStd-Light-Acro;
            font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="宋体">：</font></span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:黑体;
            font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="宋体">是指布防后对出入或内部防区提供一段延时时间（</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:0.0000pt;"><font face="宋体">这段延时可以编程为</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:0.0000pt;">10</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:0.0000pt;"><font face="宋体">到</font>25</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:0.0000pt;">5</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:0.0000pt;"><font face="宋体">秒</font></span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:黑体;
            font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="宋体">），让操作人员离开报警区域，在该时间内触发出入或内部防区不会引起报警。</font></span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:黑体;
            font-size:10.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:17.8500pt;mso-para-margin-left:1.7000gd;mso-layout-grid-align:none;
            text-autospace:none;text-align:left;"><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:AdobeSongStd-Light-Acro;
            color:rgb(255,0,0);mso-ansi-font-weight:bold;font-size:10.0000pt;
            mso-font-kerning:0.0000pt;"><font face="宋体">进入延时</font></span></b><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:AdobeSongStd-Light-Acro;
            font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="宋体">：</font></span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:黑体;
            font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="宋体">是指进入已布防的区域时，对出入或内部防区提供一段延时时间（</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:0.0000pt;"><font face="宋体">这段延时可以编程为</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:0.0000pt;">10</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:0.0000pt;"><font face="宋体">到</font>25</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:0.0000pt;">5</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:0.0000pt;"><font face="宋体">秒</font></span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:黑体;
            font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="宋体">），让操作人员对系统撤防。在该时间内触发出入或内部防区不会引起报警。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:18.0000pt;"><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(255,0,0);
            mso-ansi-font-weight:bold;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">布防（</font>Arm）</span></b><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:宋体;mso-ansi-font-weight:bold;
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">：</font></span></b><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">是指操作人员执行指令（从键盘输入密码）后，使该系统的探测器已开始工作（俗称</font>“开机”），并进入</span><i style="mso-bidi-font-style:normal"><u><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,255);
            text-decoration:underline;text-underline:single;mso-ansi-font-style:italic;
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">正常警戒</font></span></u></i><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">状态。此时，系统对探测器探测到的入侵行为作出报警。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:0.0000pt;mso-list:l6 level5 lfo1;"><!--[if !supportLists]--><span style="font-family:Wingdings;mso-fareast-font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">l<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;">&nbsp;</span><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,255);
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">外出布防</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">：</font></span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:黑体;
            font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="宋体">是指需要对全体防区进行布防时进行的操作。此时全部防区都有效，提供延时</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:0.0000pt;mso-list:l6 level5 lfo1;"><!--[if !supportLists]--><span style="font-family:Wingdings;mso-fareast-font-family:宋体;color:rgb(0,0,255);
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">l<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,255);
            font-size:10.0000pt;mso-font-kerning:1.0000pt;">&nbsp;<font face="宋体">留守布防</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">：</font></span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:黑体;
            font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="宋体">是指只对周界防区进行布防时进行的操作，</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">布防时系统自动旁路内部防区，</font></span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:黑体;
            font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="宋体">提供延时</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:0.0000pt;mso-list:l6 level5 lfo1;"><!--[if !supportLists]--><span style="font-family:Wingdings;mso-fareast-font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">l<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;">&nbsp;</span><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,255);
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">快速布防</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">：布防时系统自动旁路内部防区，只有外出延时，而没有进入延时。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:0.0000pt;mso-list:l6 level5 lfo1;"><!--[if !supportLists]--><span style="font-family:Wingdings;mso-fareast-font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">l<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;">&nbsp;</span><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,255);
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">全防布防</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">：布防时整个系统所有防区都有效，只有外出延时，而没有进入延时。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><table class="MsoTableGrid" border="1" cellspacing="0" style="border-collapse:collapse;width:333.0000pt;margin-left:59.4000pt;
            border:none;mso-border-left-alt:0.5000pt solid windowtext;mso-border-top-alt:0.5000pt solid windowtext;
            mso-border-right-alt:0.5000pt solid windowtext;mso-border-bottom-alt:0.5000pt solid windowtext;mso-border-insideh:0.5000pt solid windowtext;
            mso-border-insidev:0.5000pt solid windowtext;mso-padding-alt:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;"><tbody><tr><td width="108" valign="top" style="width:54.0000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid windowtext;
            mso-border-left-alt:0.5000pt solid windowtext;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
            border-top:1.0000pt solid windowtext;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
            mso-border-bottom-alt:0.5000pt solid windowtext;"><p class="MsoNormal" align="center" style="text-align:center;"><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">布防方式</font></span><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td><td width="108" valign="top" style="width:54.0000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid windowtext;
            mso-border-left-alt:0.5000pt solid windowtext;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
            border-top:1.0000pt solid windowtext;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
            mso-border-bottom-alt:0.5000pt solid windowtext;"><p class="MsoNormal" align="center" style="text-align:center;"><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">外出延时</font></span><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td><td width="108" valign="top" style="width:54.0000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid windowtext;
            mso-border-left-alt:0.5000pt solid windowtext;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
            border-top:1.0000pt solid windowtext;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
            mso-border-bottom-alt:0.5000pt solid windowtext;"><p class="MsoNormal" align="center" style="text-align:center;"><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">进入延时</font></span><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td><td width="126" valign="top" style="width:63.0000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid windowtext;
            mso-border-left-alt:0.5000pt solid windowtext;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
            border-top:1.0000pt solid windowtext;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
            mso-border-bottom-alt:0.5000pt solid windowtext;"><p class="MsoNormal" align="center" style="text-align:center;"><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">防区类型</font></span><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td><td width="108" valign="top" style="width:54.0000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid windowtext;
            mso-border-left-alt:0.5000pt solid windowtext;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
            border-top:1.0000pt solid windowtext;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
            mso-border-bottom-alt:0.5000pt solid windowtext;"><p class="MsoNormal" align="center" style="text-align:center;"><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">旁路防区</font></span><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td><td width="108" valign="top" style="width:54.0000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid windowtext;
            mso-border-left-alt:0.5000pt solid windowtext;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
            border-top:1.0000pt solid windowtext;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
            mso-border-bottom-alt:0.5000pt solid windowtext;"><p class="MsoNormal" align="center" style="text-align:center;"><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">使用情况</font></span><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td></tr><tr><td width="108" valign="top" style="width:54.0000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid windowtext;
            mso-border-left-alt:0.5000pt solid windowtext;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
            border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
            mso-border-bottom-alt:0.5000pt solid windowtext;"><p class="MsoNormal"><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">外出布防</font></span><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td><td width="108" valign="top" style="width:54.0000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid windowtext;
            mso-border-left-alt:0.5000pt solid windowtext;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
            border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
            mso-border-bottom-alt:0.5000pt solid windowtext;"><p class="MsoNormal"><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">有</font></span><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td><td width="108" valign="top" style="width:54.0000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid windowtext;
            mso-border-left-alt:0.5000pt solid windowtext;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
            border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
            mso-border-bottom-alt:0.5000pt solid windowtext;"><p class="MsoNormal"><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">有</font></span><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td><td width="126" valign="top" style="width:63.0000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid windowtext;
            mso-border-left-alt:0.5000pt solid windowtext;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
            border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
            mso-border-bottom-alt:0.5000pt solid windowtext;"><p class="MsoNormal"><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">所有</font></span><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td><td width="108" valign="top" style="width:54.0000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid windowtext;
            mso-border-left-alt:0.5000pt solid windowtext;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
            border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
            mso-border-bottom-alt:0.5000pt solid windowtext;"><p class="MsoNormal"><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">无</font></span><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td><td width="108" valign="top" style="width:54.0000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid windowtext;
            mso-border-left-alt:0.5000pt solid windowtext;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
            border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
            mso-border-bottom-alt:0.5000pt solid windowtext;"><p class="MsoNormal"><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">外出无人</font></span><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td></tr><tr><td width="108" valign="top" style="width:54.0000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid windowtext;
            mso-border-left-alt:0.5000pt solid windowtext;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
            border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
            mso-border-bottom-alt:0.5000pt solid windowtext;"><p class="MsoNormal"><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">留守布防</font></span><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td><td width="108" valign="top" style="width:54.0000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid windowtext;
            mso-border-left-alt:0.5000pt solid windowtext;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
            border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
            mso-border-bottom-alt:0.5000pt solid windowtext;"><p class="MsoNormal"><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">有</font></span><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td><td width="108" valign="top" style="width:54.0000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid windowtext;
            mso-border-left-alt:0.5000pt solid windowtext;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
            border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
            mso-border-bottom-alt:0.5000pt solid windowtext;"><p class="MsoNormal"><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">有</font></span><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td><td width="126" valign="top" style="width:63.0000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid windowtext;
            mso-border-left-alt:0.5000pt solid windowtext;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
            border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
            mso-border-bottom-alt:0.5000pt solid windowtext;"><p class="MsoNormal"><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">除内部防区</font></span><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td><td width="108" valign="top" style="width:54.0000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid windowtext;
            mso-border-left-alt:0.5000pt solid windowtext;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
            border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
            mso-border-bottom-alt:0.5000pt solid windowtext;"><p class="MsoNormal"><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">内部防区</font></span><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td><td width="108" valign="top" style="width:54.0000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid windowtext;
            mso-border-left-alt:0.5000pt solid windowtext;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
            border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
            mso-border-bottom-alt:0.5000pt solid windowtext;"><p class="MsoNormal"><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">室内有人</font></span><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td></tr><tr><td width="108" valign="top" style="width:54.0000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid windowtext;
            mso-border-left-alt:0.5000pt solid windowtext;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
            border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
            mso-border-bottom-alt:0.5000pt solid windowtext;"><p class="MsoNormal"><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">快速布防</font></span><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td><td width="108" valign="top" style="width:54.0000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid windowtext;
            mso-border-left-alt:0.5000pt solid windowtext;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
            border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
            mso-border-bottom-alt:0.5000pt solid windowtext;"><p class="MsoNormal"><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">有</font></span><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td><td width="108" valign="top" style="width:54.0000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid windowtext;
            mso-border-left-alt:0.5000pt solid windowtext;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
            border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
            mso-border-bottom-alt:0.5000pt solid windowtext;"><p class="MsoNormal"><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">无</font></span><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td><td width="126" valign="top" style="width:63.0000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid windowtext;
            mso-border-left-alt:0.5000pt solid windowtext;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
            border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
            mso-border-bottom-alt:0.5000pt solid windowtext;"><p class="MsoNormal"><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">除内部防区</font></span><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td><td width="108" valign="top" style="width:54.0000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid windowtext;
            mso-border-left-alt:0.5000pt solid windowtext;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
            border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
            mso-border-bottom-alt:0.5000pt solid windowtext;"><p class="MsoNormal"><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">内部防区</font></span><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td><td width="108" valign="top" style="width:54.0000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid windowtext;
            mso-border-left-alt:0.5000pt solid windowtext;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
            border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
            mso-border-bottom-alt:0.5000pt solid windowtext;"><p class="MsoNormal"><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">夜晚休息</font></span><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td></tr><tr><td width="108" valign="top" style="width:54.0000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid windowtext;
            mso-border-left-alt:0.5000pt solid windowtext;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
            border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
            mso-border-bottom-alt:0.5000pt solid windowtext;"><p class="MsoNormal"><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">全防布防</font></span><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td><td width="108" valign="top" style="width:54.0000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid windowtext;
            mso-border-left-alt:0.5000pt solid windowtext;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
            border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
            mso-border-bottom-alt:0.5000pt solid windowtext;"><p class="MsoNormal"><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">有</font></span><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td><td width="108" valign="top" style="width:54.0000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid windowtext;
            mso-border-left-alt:0.5000pt solid windowtext;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
            border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
            mso-border-bottom-alt:0.5000pt solid windowtext;"><p class="MsoNormal"><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">无</font></span><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td><td width="126" valign="top" style="width:63.0000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid windowtext;
            mso-border-left-alt:0.5000pt solid windowtext;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
            border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
            mso-border-bottom-alt:0.5000pt solid windowtext;"><p class="MsoNormal"><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">所有</font></span><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td><td width="108" valign="top" style="width:54.0000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid windowtext;
            mso-border-left-alt:0.5000pt solid windowtext;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
            border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
            mso-border-bottom-alt:0.5000pt solid windowtext;"><p class="MsoNormal"><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">无</font></span><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td><td width="108" valign="top" style="width:54.0000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid windowtext;
            mso-border-left-alt:0.5000pt solid windowtext;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
            border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
            mso-border-bottom-alt:0.5000pt solid windowtext;"><p class="MsoNormal"><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">长期外出</font></span><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td></tr></tbody></table><p class="MsoNormal" style="margin-left:18.0000pt;"><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(255,0,0);
            mso-ansi-font-weight:bold;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">撤防（</font>Disarm）</span></b><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:宋体;mso-ansi-font-weight:bold;
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">：</font></span></b><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">是指操作人员执行指令（从键盘输入密码）后，使该系统的探测器不能进入</font></span><i style="mso-bidi-font-style:normal"><u><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,255);
            text-decoration:underline;text-underline:single;mso-ansi-font-style:italic;
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">正常警戒工作</font></span></u></i><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">状态，或从警戒状态下退出，使探测器</font></span><i style="mso-bidi-font-style:normal"><u><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,255);
            text-decoration:underline;text-underline:single;mso-ansi-font-style:italic;
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">无效</font></span></u></i><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">（俗称</font>“关机”）。此时，系统对探测器探测到的动作</span><i style="mso-bidi-font-style:normal"><u><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,255);
            text-decoration:underline;text-underline:single;mso-ansi-font-style:italic;
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">不作反应</font></span></u></i><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">（</font>24小时类型防区除外）。</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:18.0000pt;"><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(255,0,0);
            mso-ansi-font-weight:bold;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">旁路（</font>Bypass）</span></b><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:宋体;mso-ansi-font-weight:bold;
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">：</font></span></b><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">是指操作人员执行指令后，指定防区的探测器就会从整个探测器的群体中失效，而不能进入工作状态。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:0.0000pt;"><font face="宋体">把某个防区从系统中排除，旁路问题防区，其他的防区可以正常布防。旁路内部防区可以让允许人在保护区内自由走动，而周边防区处于保护状态。注意：</font></span><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,255);
            font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="宋体">旁路的防区不受保护</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:17.8500pt;mso-para-margin-left:1.7000gd;mso-layout-grid-align:none;
            text-autospace:none;text-align:left;"><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(255,0,0);
            mso-ansi-font-weight:bold;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">报警中心（</font>Central Station）</span></b><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:宋体;mso-ansi-font-weight:bold;
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">：</font></span></b><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:0.0000pt;"><font face="宋体">报警时，报警主机通过电话线发送报告去的处警单位。报警中心接到报警后会采取相应的处理措施，</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">是专门处理警情的地方。通过电话线或其他媒介（如数字接警机），自动接收用户报警主机发送的报警信号，通过计算机及管理软件管理警情、用户资料，由专人处理。通常安装在具有保安力量的派出所、公安局、保安部等地方。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:17.9500pt;mso-para-margin-left:1.7100gd;mso-layout-grid-align:none;
            text-autospace:none;text-align:left;"><b><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:宋体,Bold;
            color:rgb(255,0,0);font-weight:bold;font-size:10.0000pt;
            mso-font-kerning:0.0000pt;"><font face="宋体">报警</font></span></b><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:0.0000pt;"><font face="宋体">：系统布防时，某个防区的探测器触发即引发报警（火警或</font>24小时防区除外）。</span><b><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:宋体,Bold;
            color:rgb(255,0,0);font-weight:bold;font-size:10.0000pt;
            mso-font-kerning:0.0000pt;"><o:p></o:p></span></b></p><p class="MsoNormal" style="margin-left:17.8500pt;mso-para-margin-left:1.7000gd;mso-layout-grid-align:none;
            text-autospace:none;text-align:left;"><b><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:宋体,Bold;
            color:rgb(255,0,0);font-weight:bold;font-size:10.0000pt;
            mso-font-kerning:0.0000pt;"><font face="宋体">报警记忆</font></span></b><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:0.0000pt;"><font face="宋体">：报警状态保存在报警主机内存中直到被清除。</font></span><b><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:宋体,Bold;
            color:rgb(255,0,0);font-weight:bold;font-size:10.0000pt;
            mso-font-kerning:0.0000pt;"><o:p></o:p></span></b></p><p class="MsoNormal" style="margin-left:17.9500pt;mso-para-margin-left:1.7100gd;mso-layout-grid-align:none;
            text-autospace:none;text-align:left;"><b><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:宋体,Bold;
            color:rgb(255,0,0);font-weight:bold;font-size:10.0000pt;
            mso-font-kerning:0.0000pt;"><font face="宋体">失效</font></span></b><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:0.0000pt;"><font face="宋体">：系统撤防时，某个防区的探测器触发或故障等不正常情况。</font></span><b><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:宋体,Bold;
            font-weight:bold;font-size:10.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></b></p><p class="MsoNormal" style="margin-left:17.8500pt;mso-para-margin-left:1.7000gd;mso-layout-grid-align:none;
            text-autospace:none;text-align:left;"><b><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:宋体,Bold;
            color:rgb(255,0,0);font-weight:bold;font-size:10.0000pt;
            mso-font-kerning:0.0000pt;"><font face="宋体">级别</font></span></b><b><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:宋体,Bold;
            color:rgb(255,0,0);font-weight:bold;font-size:10.0000pt;
            mso-font-kerning:0.0000pt;">&nbsp;"A"</span></b><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:0.0000pt;"><font face="宋体">：提供发射器或原始设备监测的系统。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:0.0000pt;">(</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:0.0000pt;"><font face="宋体">如</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:0.0000pt;">, </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:0.0000pt;"><font face="宋体">探测设备和接线</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:0.0000pt;">)</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:0.0000pt;"><font face="宋体">。</font></span><b><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:宋体,Bold;
            color:rgb(255,0,0);font-weight:bold;font-size:10.0000pt;
            mso-font-kerning:0.0000pt;"><o:p></o:p></span></b></p><p class="MsoNormal" style="margin-left:17.8500pt;mso-para-margin-left:1.7000gd;mso-layout-grid-align:none;
            text-autospace:none;text-align:left;"><b><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:宋体,Bold;
            color:rgb(255,0,0);font-weight:bold;font-size:10.0000pt;
            mso-font-kerning:0.0000pt;"><font face="宋体">密码</font></span></b><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:0.0000pt;"><font face="宋体">：用于布撤防或其他特殊功能需要输入的数码组合，密码长度必须是</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:0.0000pt;">4 </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:0.0000pt;"><font face="宋体">位。</font></span><b><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:宋体,Bold;
            color:rgb(255,0,0);font-weight:bold;font-size:10.0000pt;
            mso-font-kerning:0.0000pt;"><o:p></o:p></span></b></p><p class="MsoNormal"><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p>&nbsp;</o:p></span></p><p class="MsoNormal" style="margin-left:18.0000pt;text-indent:-18.0000pt;mso-list:l6 level1 lfo1;"><!--[if !supportLists]--><span style="font-family:宋体;mso-ansi-font-weight:bold;font-size:12.0000pt;
            mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">5、<span>&nbsp;</span></span></span><!--[endif]--><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:宋体;mso-ansi-font-weight:bold;
            font-size:12.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">防盗系统的功能</font></span></b><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:宋体;mso-ansi-font-weight:bold;
            font-size:12.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></b></p><p class="MsoNormal" style="margin-left:27.0000pt;text-indent:0.0000pt;mso-list:l6 level5 lfo1;"><!--[if !supportLists]--><span style="font-family:Wingdings;mso-fareast-font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">l<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">可同时接入多个探头，对每个探头的位置都可清楚报告。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:27.0000pt;text-indent:0.0000pt;mso-list:l6 level5 lfo1;"><!--[if !supportLists]--><span style="font-family:Wingdings;mso-fareast-font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">l<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">可设定</font>4种不同的布防方式：留守、外出、快速（即时）、全防。</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:27.0000pt;text-indent:0.0000pt;mso-list:l6 level5 lfo1;"><!--[if !supportLists]--><span style="font-family:Wingdings;mso-fareast-font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">l<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">可使用键盘操作。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:27.0000pt;text-indent:0.0000pt;mso-list:l6 level5 lfo1;"><!--[if !supportLists]--><span style="font-family:Wingdings;mso-fareast-font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">l<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">必要时可将某些探头旁路，不影响系统工作。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:27.0000pt;text-indent:0.0000pt;mso-list:l6 level5 lfo1;"><!--[if !supportLists]--><span style="font-family:Wingdings;mso-fareast-font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">l<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">可设置为响铃模式，监控门窗开关情况。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:27.0000pt;text-indent:0.0000pt;mso-list:l6 level5 lfo1;"><!--[if !supportLists]--><span style="font-family:Wingdings;mso-fareast-font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">l<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">可使用钥匙开关锁设定工作方式，免除记密码的烦恼。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:27.0000pt;text-indent:0.0000pt;mso-list:l6 level5 lfo1;"><!--[if !supportLists]--><span style="font-family:Wingdings;mso-fareast-font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">l<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">可将报警类型、发生位置迅速准确报告，方便报警中心处理。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:27.0000pt;text-indent:0.0000pt;mso-list:l6 level5 lfo1;"><!--[if !supportLists]--><span style="font-family:Wingdings;mso-fareast-font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">l<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">某些主机可使用无线的探头，安装灵活美观。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:27.0000pt;text-indent:0.0000pt;mso-list:l6 level5 lfo1;"><!--[if !supportLists]--><span style="font-family:Wingdings;mso-fareast-font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">l<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">使用无线系统的主机可用遥控键盘操作，还可遥控家里的电器开关。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:27.0000pt;text-indent:0.0000pt;mso-list:l6 level5 lfo1;"><!--[if !supportLists]--><span style="font-family:Wingdings;mso-fareast-font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">l<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">某些主机有定时控制功能，可定时将系统布防、撤防，还能定时控制电器的开关。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:27.0000pt;text-indent:0.0000pt;mso-list:l6 level5 lfo1;"><!--[if !supportLists]--><span style="font-family:Wingdings;mso-fareast-font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">l<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">某些主机可记录过去发生的各种情况，如报警、布防、撤防、掉电等。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:18.0000pt;"><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p>&nbsp;</o:p></span></p><p class="MsoNormal" style="margin-left:18.0000pt;text-indent:-18.0000pt;mso-list:l6 level1 lfo1;"><!--[if !supportLists]--><span style="font-family:宋体;mso-ansi-font-weight:bold;font-size:12.0000pt;
            mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">6、<span>&nbsp;</span></span></span><!--[endif]--><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:宋体;mso-ansi-font-weight:bold;
            font-size:12.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">常用的探测器类型</font></span></b><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:宋体;mso-ansi-font-weight:bold;
            font-size:12.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></b></p><p class="MsoNormal" style="margin-left:39.0000pt;text-indent:-18.0000pt;mso-list:l6 level2 lfo1;"><!--[if !supportLists]--><span style="font-family:宋体;color:rgb(0,0,255);font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">A、<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,255);
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">按用途或使用的场所不同来分</font></span><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,255);
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:39.0000pt;"><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">可分为：户内型、户外型、周界、重点物体保护探测器等。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:39.0000pt;text-indent:-18.0000pt;mso-list:l6 level2 lfo1;"><!--[if !supportLists]--><span style="font-family:宋体;color:rgb(0,0,255);font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">B、<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,255);
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">按探测原理不同或应用的传感器不同来分</font></span><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,255);
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:39.0000pt;"><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">可分为：雷达式微波、微波墙式、主动式红外、被动式红外、开关式、超声波、声控、振</font>(震)动、玻璃破碎、电场感应式、电容变化、视频、微波-被动红外双技术、超声波-被动红外双技术探测器。</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:39.0000pt;text-indent:-18.0000pt;mso-list:l6 level2 lfo1;"><!--[if !supportLists]--><span style="font-family:宋体;color:rgb(0,0,255);font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">C、<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,255);
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">按警戒范围来分</font></span><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,255);
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:39.0000pt;"><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">可分为：点控制型、线控制型、面控制型、空间控制型探测器。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:39.0000pt;"><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">点控制型探测器的警戒范围是一个点，线控制型探测器的警戒范围是一条线，面控制型探测器的警戒范围是一条面，空间控制型探测器的警戒范围是一条空间。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><table class="MsoTableGrid" border="1" cellspacing="0" style="border-collapse:collapse;margin-left:41.4000pt;border:none;
            mso-border-left-alt:0.5000pt solid windowtext;mso-border-top-alt:0.5000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
            mso-border-bottom-alt:0.5000pt solid windowtext;mso-border-insideh:0.5000pt solid windowtext;mso-border-insidev:0.5000pt solid windowtext;
            mso-padding-alt:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;"><tbody><tr><td width="162" valign="top" style="width:81.0000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid windowtext;
            mso-border-left-alt:0.5000pt solid windowtext;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
            border-top:1.0000pt solid windowtext;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
            mso-border-bottom-alt:0.5000pt solid windowtext;"><p class="MsoNormal"><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">警戒范围</font></span><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td><td width="607" valign="top" style="width:303.7000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid windowtext;
            mso-border-left-alt:0.5000pt solid windowtext;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
            border-top:1.0000pt solid windowtext;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
            mso-border-bottom-alt:0.5000pt solid windowtext;"><p class="MsoNormal"><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">探测器种类</font></span><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td></tr><tr><td width="162" valign="top" style="width:81.0000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid windowtext;
            mso-border-left-alt:0.5000pt solid windowtext;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
            border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
            mso-border-bottom-alt:0.5000pt solid windowtext;"><p class="MsoNormal"><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">点控制型</font></span><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td><td width="607" valign="top" style="width:303.7000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid windowtext;
            mso-border-left-alt:0.5000pt solid windowtext;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
            border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
            mso-border-bottom-alt:0.5000pt solid windowtext;"><p class="MsoNormal"><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">开关式探测器</font></span><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td></tr><tr><td width="162" valign="top" style="width:81.0000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid windowtext;
            mso-border-left-alt:0.5000pt solid windowtext;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
            border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
            mso-border-bottom-alt:0.5000pt solid windowtext;"><p class="MsoNormal"><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">线控制型</font></span><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td><td width="607" valign="top" style="width:303.7000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid windowtext;
            mso-border-left-alt:0.5000pt solid windowtext;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
            border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
            mso-border-bottom-alt:0.5000pt solid windowtext;"><p class="MsoNormal"><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">主动式红外、激光式、光纤式周界探测器</font></span><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td></tr><tr><td width="162" valign="top" style="width:81.0000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid windowtext;
            mso-border-left-alt:0.5000pt solid windowtext;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
            border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
            mso-border-bottom-alt:0.5000pt solid windowtext;"><p class="MsoNormal"><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">面控制型</font></span><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td><td width="607" valign="top" style="width:303.7000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid windowtext;
            mso-border-left-alt:0.5000pt solid windowtext;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
            border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
            mso-border-bottom-alt:0.5000pt solid windowtext;"><p class="MsoNormal"><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">震动、声控</font>-震动型双技术玻璃破碎探测器</span><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td></tr><tr><td width="162" valign="top" style="width:81.0000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid windowtext;
            mso-border-left-alt:0.5000pt solid windowtext;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
            border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
            mso-border-bottom-alt:0.5000pt solid windowtext;"><p class="MsoNormal"><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">空间控制型</font></span><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td><td width="607" valign="top" style="width:303.7000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid windowtext;
            mso-border-left-alt:0.5000pt solid windowtext;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
            border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
            mso-border-bottom-alt:0.5000pt solid windowtext;"><p class="MsoNormal"><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">雷达式微波、微波墙式、被动式红外、超声波、声控、视频、微波</font>-被动红外双技术、超声波-被动红外双技术、声控型单技术玻璃破碎、泄露电缆、震动电缆、电场感应式、电容变化式探测器</span><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td></tr></tbody></table><p class="MsoNormal" style="margin-left:39.0000pt;text-indent:-18.0000pt;mso-list:l6 level2 lfo1;"><!--[if !supportLists]--><span style="font-family:宋体;color:rgb(0,0,255);font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">D、<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,255);
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">按工作方式来分</font></span><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,255);
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:39.0000pt;"><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">可分为主动式和被动式探测器。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:39.0000pt;"><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">主动式探测器在担任警戒期间要向所防范的现场不断发出某种形式的能量，如红外线、超声波、微波等能量。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:39.0000pt;"><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">被动式探测器在担任警戒期间本身则不需要向所防范的现场发出任何形式的能量，而是直接探测来自被探测目标自身发出的某种形式的能量，如红外线、震动等能量。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:39.0000pt;text-indent:-18.0000pt;mso-list:l6 level2 lfo1;"><!--[if !supportLists]--><span style="font-family:宋体;color:rgb(0,0,255);font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">E、<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,255);
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">按输出的开关信号不同来分</font></span><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,255);
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:39.0000pt;"><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">可分为常开型、常闭型、常开</font>/常闭型探测器</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:39.0000pt;"><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">常开型（</font>NO）探测器：在正常情况下，开关是断开的，EOL(线末电阻)电阻与之并联。当探测器被触发时，开关闭合，回路电阻为零，该防区报警。</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:39.0000pt;"><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">常闭型（</font>NC）探测器：在正常情况下，开关是闭合的，EOL(线末电阻)电阻与之串联。当探测器被触发时，开关断开，回路电阻为无穷大，该防区报警。</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:39.0000pt;"><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">常开</font>/常闭型探测器：具有常开和常闭两种输出方式。</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:39.0000pt;text-indent:-18.0000pt;mso-list:l6 level2 lfo1;"><!--[if !supportLists]--><span style="font-family:宋体;color:rgb(0,0,255);font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">F、<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,255);
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">按探测器与报警控制器各防区的连接方式不同来分</font></span><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,255);
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:39.0000pt;"><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">可分为四线制、两线制、无线制三种。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:39.0000pt;"><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">四线制：是指探测器上有四个接线端</font>(两个接报警开关信号输出，两个接供电输入线)。一般常规需要供电的探测器，如红外探测器、双鉴探测器、玻璃破碎探测器等均采用的是四线制。</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:39.0000pt;"><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(255,0,0);
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">两线制：是指探测器上有两个接线端，又可分为三种情况。</font></span><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(255,0,0);
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:57.0000pt;text-indent:-18.0000pt;mso-list:l4 level1 lfo4;"><!--[if !supportLists]--><span style="font-family:宋体;color:rgb(255,0,0);font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">①<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(255,0,0);
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">探测器本身不需要供电</font>(报警开关信号线)。如紧急按钮、磁控开关、震动开关。</span><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(255,0,0);
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:57.0000pt;text-indent:-18.0000pt;mso-list:l4 level1 lfo4;"><!--[if !supportLists]--><span style="font-family:宋体;color:rgb(255,0,0);font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">②<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(255,0,0);
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">探测器需要供电</font>(报警开关信号线和供电输入线是共用的)。如火灾探测器。</span><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(255,0,0);
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:57.0000pt;text-indent:-18.0000pt;mso-list:l4 level1 lfo4;"><!--[if !supportLists]--><span style="font-family:宋体;color:rgb(255,0,0);font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">③<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(255,0,0);
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">两总线制，需采用总线制探测器</font>(都具有编码功能)。所有防区都共用两芯线，每个防区的报警开关信号线和供电输入线是共用的(特别适用于防区数目多)。另外，增加总线扩充器就可以接入四线制探测器。</span><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(255,0,0);
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:39.0000pt;"><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">无线制：无线探测器是由探测器和发射机两部分组合在一起的，它需要由无线发射机将无线报警探测器输出的电信号调制</font>(调幅或调频)到规定范围的载波上，发射到空间，而后再由无线接收机接收、解调后，再送往报警主机。</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:17.9500pt;mso-para-margin-left:1.7100gd;text-indent:20.0000pt;
            mso-char-indent-count:2.0000;"><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p>&nbsp;</o:p></span></p><p class="MsoNormal" style="margin-left:17.9500pt;mso-para-margin-left:1.7100gd;text-indent:20.0000pt;
            mso-char-indent-count:2.0000;"><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">可以应用在一切需要进行安全防范、防盗、防入侵的场所，如政府机关、军事单位和设施、广播电视通讯系统、工矿企业、科研单位、财政金融系统、商业系统、文物保护单位</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;">……</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">以至居民区、住家户等处。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:18.0000pt;"><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p>&nbsp;</o:p></span></p><p class="MsoNormal" style="margin-left:18.0000pt;text-indent:-18.0000pt;mso-list:l6 level1 lfo1;"><!--[if !supportLists]--><span style="font-family:宋体;mso-ansi-font-weight:bold;font-size:12.0000pt;
            mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">7、<span>&nbsp;</span></span></span><!--[endif]--><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:宋体;mso-ansi-font-weight:bold;
            font-size:12.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">探测器的主要技术性能指标</font></span></b><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:宋体;mso-ansi-font-weight:bold;
            font-size:12.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></b></p><p class="MsoNormal" style="margin-left:39.0000pt;text-indent:-18.0000pt;mso-list:l6 level2 lfo1;"><!--[if !supportLists]--><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">A、<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">功能</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:21.0000pt;"><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;">&nbsp;&nbsp;&nbsp;<font face="宋体">按照产品说明书进行了正确的安装，并在正常环境下使用和执行正确的操作时，应能完成产品标准所规定的入侵探测和报警的所有功能。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:39.0000pt;text-indent:-18.0000pt;mso-list:l6 level2 lfo1;"><!--[if !supportLists]--><span style="font-family:宋体;color:rgb(0,0,255);font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">B、<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,255);
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">灵敏度</font></span><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,255);
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:20.0000pt;text-indent:-20.0000pt;mso-char-indent-count:-2.0000;"><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<font face="宋体">是指能使探测器发出报警信号的最低门限信号或最小输入探测信号。该指标反映了探测器对入侵目标产生报警的反应能力。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:39.0000pt;text-indent:-18.0000pt;mso-list:l6 level2 lfo1;"><!--[if !supportLists]--><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">C、<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">报警传送方式，最大传输距离</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:20.0000pt;text-indent:-20.0000pt;mso-char-indent-count:-2.0000;"><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<font face="宋体">传送方式是指有线或无线方式。最大传输距离是指在探测器发挥正常警戒功能的条件下，从探测器到报警主机之间的最大有线或无线的传输距离。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:39.0000pt;text-indent:-18.0000pt;mso-list:l6 level2 lfo1;"><!--[if !supportLists]--><span style="font-family:宋体;color:rgb(0,0,255);font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">D、<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,255);
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">探测范围</font></span><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,255);
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:39.0000pt;"><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">又称警戒范围或监控范围，是指探测器在正常环境条件下所能警戒、防范的区域或空间的大小。通常有下列几种表示方法：</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:60.0000pt;text-indent:-18.0000pt;mso-list:l6 level3 lfo1;"><!--[if !supportLists]--><span style="font-family:'Times New Roman';font-size:10.0000pt;mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">A、<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">探测距离：指在给定方向从探测器到探测范围边界的距离。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:60.0000pt;text-indent:-18.0000pt;mso-list:l6 level3 lfo1;"><!--[if !supportLists]--><span style="font-family:'Times New Roman';font-size:10.0000pt;mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">B、<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">探测视角</font>(水平或垂直)：指探测器对所能探测到的立体防范空间的最大张角。</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:60.0000pt;text-indent:-18.0000pt;mso-list:l6 level3 lfo1;"><!--[if !supportLists]--><span style="font-family:'Times New Roman';font-size:10.0000pt;mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">C、<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">探测面积</font>(或体积)：指探测器所能探测到的最大立体防范空间的面积(或体积)</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:39.0000pt;text-indent:-18.0000pt;mso-list:l6 level2 lfo1;"><!--[if !supportLists]--><span style="font-family:宋体;color:rgb(0,0,255);font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">E、<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,255);
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">误报率</font></span><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,255);
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:21.0000pt;"><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;">&nbsp;&nbsp;&nbsp;<font face="宋体">所谓误报警是指在没有入侵者的情况下，由于探测器本身的原因或操作不当或环境影响而触发的报警。在某一单位时间内出现误报警的次数就称为误报率。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:39.0000pt;text-indent:-18.0000pt;mso-list:l6 level2 lfo1;"><!--[if !supportLists]--><span style="font-family:宋体;color:rgb(0,0,255);font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">F、<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,255);
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">漏报率</font></span><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,255);
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:21.0000pt;"><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;">&nbsp;&nbsp;&nbsp;<font face="宋体">所谓漏报警是指入侵已经发生，而探测器却没有给出报警信号。漏报的次数占应当报警的次数的百分比就称为漏报率。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:39.0000pt;text-indent:-18.0000pt;mso-list:l6 level2 lfo1;"><!--[if !supportLists]--><span style="font-family:宋体;color:rgb(0,0,255);font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">G、<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,255);
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">探测率</font></span><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,255);
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:39.0000pt;"><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">探测器在探测到入侵目标时实际报警的次数占应当报警的次数的百分比就称为探测率。探测率和漏报率之和应等于</font>100%。</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:39.0000pt;text-indent:-18.0000pt;mso-list:l6 level2 lfo1;"><!--[if !supportLists]--><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">H、<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">防破坏保护</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:39.0000pt;"><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">防拆保护：探测器应装有防拆开关，打开外壳时应输出报警信号或故障报警信号。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:39.0000pt;"><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">线路短路或开路保护：</font></span><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(255,0,0);
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">当线路短路或开路或并接其他负载时，应输出报警信号或故障报警信号。</font></span><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(255,0,0);
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:39.0000pt;text-indent:-18.0000pt;mso-list:l6 level2 lfo1;"><!--[if !supportLists]--><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">I、<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">电源适用范围</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:39.0000pt;"><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">当电源电压在额定值的</font>±10%范围内变化时，电源应不需要调整仍能正常工作，且性能指标应符合要求。</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:39.0000pt;text-indent:-18.0000pt;mso-list:l6 level2 lfo1;"><!--[if !supportLists]--><span style="font-family:宋体;color:rgb(0,0,255);font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">J、<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,255);
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">工作电压</font></span><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,255);
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:39.0000pt;"><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">探测器工作时的电源电压</font>(交流或直流)。</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:39.0000pt;text-indent:-18.0000pt;mso-list:l6 level2 lfo1;"><!--[if !supportLists]--><span style="font-family:宋体;color:rgb(0,0,255);font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">K、<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,255);
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">工作电流</font></span><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,255);
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:21.0000pt;"><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;">&nbsp;&nbsp;&nbsp;<font face="宋体">探测器工作时的耗电电流。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:39.0000pt;text-indent:-18.0000pt;mso-list:l6 level2 lfo1;"><!--[if !supportLists]--><span style="font-family:宋体;color:rgb(0,0,255);font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">L、<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,255);
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">电源功耗</font></span><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,255);
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:39.0000pt;"><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">探测器在工作时间的功率消耗。分静态</font>(警戒状态)和动态(报警状态)功耗。</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:39.0000pt;text-indent:-18.0000pt;mso-list:l6 level2 lfo1;"><!--[if !supportLists]--><span style="font-family:宋体;color:rgb(0,0,255);font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">M、<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,255);
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">工作时间</font></span><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,255);
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:39.0000pt;"><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">指探测器连续开机正常使用的最长工作时间</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:39.0000pt;text-indent:-18.0000pt;mso-list:l6 level2 lfo1;"><!--[if !supportLists]--><span style="font-family:宋体;color:rgb(0,0,255);font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">N、<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,255);
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">环境温度</font></span><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,255);
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:39.0000pt;"><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">指探测器维持正常工作所使用的环境温度。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:39.0000pt;"><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">室内应用：</font>-10℃—55℃，相对湿度≤95%。</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:39.0000pt;"><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">室外应用：</font>-20℃—75℃，相对湿度≤95%。</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:39.0000pt;text-indent:-18.0000pt;mso-list:l6 level2 lfo1;"><!--[if !supportLists]--><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">O、<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">耐久性要求：在额定电压和额定负载电流下进行警戒、报警和复位，循环</font>6000次，应无电的或机械的故障，也不应有器件损坏或触点粘连。</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:39.0000pt;text-indent:-18.0000pt;mso-list:l6 level2 lfo1;"><!--[if !supportLists]--><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">P、<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">稳定性要求</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:21.0000pt;"><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;">&nbsp;&nbsp;&nbsp;<font face="宋体">在正常气候环境下，连续工作</font>7天不应出现误报警和漏报警，其灵敏度和探测范围的变化不应超过±10%</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:39.0000pt;text-indent:-18.0000pt;mso-list:l6 level2 lfo1;"><!--[if !supportLists]--><span style="font-family:宋体;color:rgb(0,0,255);font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">Q、<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,255);
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">抗干扰要求</font></span><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,255);
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:39.0000pt;"><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">应符合</font>GB 6833.1中规定的静电放电敏感度实验、电源瞬态敏感实验、辐射敏感实验中的要求，不应出现误报警和漏报警。</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:39.0000pt;"><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">在警戒状态下受热气流干扰、电火花干扰、灯光干扰和电令等的干扰时应能正常工作，也不应出现误报警和漏报警。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:39.0000pt;text-indent:-18.0000pt;mso-list:l6 level2 lfo1;"><!--[if !supportLists]--><span style="font-family:宋体;color:rgb(0,0,255);font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">R、<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,255);
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">可靠性要求</font></span><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,255);
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:39.0000pt;"><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">在正常工作条件下平均无故障工作时间分为</font>A(1000小时)、B(5000小时)、C(20000小时)、D(60000小时)四级，各类产品的指标不应低于A级的要求。</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:39.0000pt;"><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p>&nbsp;</o:p></span></p><p class="MsoNormal" style="margin-left:18.0000pt;text-indent:-18.0000pt;mso-list:l6 level1 lfo1;"><!--[if !supportLists]--><span style="font-family:宋体;mso-ansi-font-weight:bold;font-size:12.0000pt;
            mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">8、<span>&nbsp;</span></span></span><!--[endif]--><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:宋体;mso-ansi-font-weight:bold;
            font-size:12.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">红外探测器</font></span></b><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:宋体;mso-ansi-font-weight:bold;
            font-size:12.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></b></p><p class="MsoNormal" style="margin-left:18.0000pt;"><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">可分为主动式红外探测器和被动式红外探测器。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:18.0000pt;"><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">主动式红外探测器是由发射和接收装置两部分所组成。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:18.0000pt;"><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,255);
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">主动红外探测器安装注意事项</font>:</span><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,255);
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:45.0000pt;text-indent:-21.0000pt;mso-list:l6 level5 lfo1;"><!--[if !supportLists]--><span style="font-family:Wingdings;mso-fareast-font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">l<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">对射探测器的探测距离一般是在天气晴朗的情况下侧出的，要根据当地情况留有余量，以免误报。一般以</font>80%-90%为宜。</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:45.0000pt;text-indent:-21.0000pt;mso-list:l6 level5 lfo1;"><!--[if !supportLists]--><span style="font-family:Wingdings;mso-fareast-font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">l<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">红外光束并非只是一条线，而是比较粗的一束，调试时一定要保证对准。否则即便当时测试正常了，也可能由于环境的稍稍变化引起误报。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:45.0000pt;text-indent:-21.0000pt;mso-list:l6 level5 lfo1;"><!--[if !supportLists]--><span style="font-family:Wingdings;mso-fareast-font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">l<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">对射探测器的保护范围较窄，注意光束覆盖不到的地方的防护。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:45.0000pt;text-indent:-21.0000pt;mso-list:l6 level5 lfo1;"><!--[if !supportLists]--><span style="font-family:Wingdings;mso-fareast-font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">l<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">在安装时要尽量把两个</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">对射探测器</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">保持水平，在两个探测器之间不可以有障碍物，因为</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">对射</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">探测器探测角度上下不能超过</font>20度，左右不能超过180度，为了调试方便，保持水平很重要</span><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            color:rgb(33,77,115);font-size:9.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:45.0000pt;text-indent:-21.0000pt;mso-list:l6 level5 lfo1;"><!--[if !supportLists]--><span style="font-family:Wingdings;mso-fareast-font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">l<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">如安装在支架上，支架长度应为</font>1米左右，支架直径为40mm，在支架顶端以下10cm处开一直径为10mm的小孔以作穿线用。</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:45.0000pt;text-indent:-21.0000pt;mso-list:l6 level5 lfo1;"><!--[if !supportLists]--><span style="font-family:Wingdings;mso-fareast-font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">l<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">一对</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">对射</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">探测器分为发射和接收，发射端只需要连电源便可，而线径的大小要视长度而定，线路越长要求的线径就越粗，一般使用</font>1.0mm2的电源线。</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:45.0000pt;text-indent:-21.0000pt;mso-list:l6 level5 lfo1;"><!--[if !supportLists]--><span style="font-family:Wingdings;mso-fareast-font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">l<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">接收端要接电源线和信号线，用一条四芯线连接，把其中二芯做电源线，另外二芯做信号线，信号线与报警主机或扩展模块连接。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:45.0000pt;text-indent:-21.0000pt;mso-list:l6 level5 lfo1;"><!--[if !supportLists]--><span style="font-family:Wingdings;mso-fareast-font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">l<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">对射</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">探测器一般都采用集中供电的办法，但要注意线路不可以太长。如果线路太长，电压就会衰减，如使用</font>1.0mm2电源线则最长不可以超过500米。</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:45.0000pt;text-indent:-21.0000pt;mso-list:l6 level5 lfo1;"><!--[if !supportLists]--><span style="font-family:Wingdings;mso-fareast-font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">l<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">接线时电源线接</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">对射</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">探测器的</font>POWER（+</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">/</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;">-）端子，而信号线则连接COM端子和N</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">C</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">端子，这种连接方法平时状态为常</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">闭</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">，而当</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">对射</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">探测器发生报警时，会触发一个</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">断开</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">信号给报警主机，主机收到信号就会发生报警。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:45.0000pt;text-indent:-21.0000pt;mso-list:l6 level5 lfo1;"><!--[if !supportLists]--><span style="font-family:Wingdings;mso-fareast-font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">l<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">对射</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">探测器调试。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">在调试时要用配件中的挡光板挡住上方或下方的发射和接收端，先调试好一面再调试另一面。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">在红外线探头的里面有一个类似潜望镜的观察孔，首先要在观察孔中观察调试探测器的左右位置，以便用观察孔看得</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">到</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">另一个红外线探测器。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">然后用万用表连接到接收端的一个专用测试孔上，把万用表调到</font>DC10-20的档位，观看万用表的读数来调节发射或接收端的上下左右，当接收端接收到发射端发射的红外线光速后，接收端</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">就</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">会有电压输出，使用万用表测量此电压的数值，电压要为</font>3.5V以上才算正常，达到5V以上便为最好。</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">当调节好上方的发射端和接收端后，便可把挡光板挡住已调节好的发射和接收端，再用同样的方法调试下方的发射和接收端，使上下方的电压都为</font>5V或以上。</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">上下方都调试好后便可以把挡光板拿走，然后再观察一下读数是否为</font>5V或以上。</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">调试好后可以安排调试人员在两个红外线探测器之间走</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">动</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">，观察万用表的电压是否有变化。正常的情况下，万用表的读数应为</font>5V或以上，而当有人从中间走过时，光束给截断，则电压会变成0V，红外线探测器便会触发一个闭合信号给报警主机或扩展模块。</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:18.0000pt;"><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">被动式红外探测器主要是由光学系统、热传感器</font>(或称红外传感器)组成。通过光学系统的配合作用，可以探测到某一个立体防范空间内的热辐射的变化（人体表面温度与周围环境温度的差别）。</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:18.0000pt;"><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,255);
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">防止被动红外探测器</font>(PIR)产生误报的措施</span><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,255);
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:60.0000pt;text-indent:-18.0000pt;mso-list:l2 level1 lfo5;"><!--[if !supportLists]--><span style="font-family:'Times New Roman';color:rgb(0,0,255);font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">A、<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,255);
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">温度补偿</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">电路。增强对各种冷、热环境的适应能力。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:60.0000pt;text-indent:-18.0000pt;mso-list:l2 level1 lfo5;"><!--[if !supportLists]--><span style="font-family:'Times New Roman';font-size:10.0000pt;mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">B、<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">采用多元</font>(双元或四元)红外光敏元件，并采用“脉冲计数”方式工作。</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:60.0000pt;text-indent:-18.0000pt;mso-list:l2 level1 lfo5;"><!--[if !supportLists]--><span style="font-family:'Times New Roman';font-size:10.0000pt;mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">C、<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">防射频干扰的措施。采用表面贴片技术。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:60.0000pt;text-indent:-18.0000pt;mso-list:l2 level1 lfo5;"><!--[if !supportLists]--><span style="font-family:'Times New Roman';font-size:10.0000pt;mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">D、<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">防白光干扰的措施。在菲涅尔透镜上采取滤白光的措施。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:60.0000pt;text-indent:-18.0000pt;mso-list:l2 level1 lfo5;"><!--[if !supportLists]--><span style="font-family:'Times New Roman';font-size:10.0000pt;mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">E、<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">防小动物误报所采取的措施。采用四元红外光敏元件；在被动红外探测器中内置微处理器；采用防宠物的菲涅尔透镜。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:60.0000pt;text-indent:-18.0000pt;mso-list:l2 level1 lfo5;"><!--[if !supportLists]--><span style="font-family:'Times New Roman';color:rgb(0,0,255);font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">F、<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,255);
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">反射镜聚焦</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">。采用反射镜聚焦红外能量比菲涅尔镜片更有效，探测器信号放大电路的增益可以较低，避免增益太大时干扰信号同时放大而引起误报。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:60.0000pt;text-indent:-18.0000pt;mso-list:l2 level1 lfo5;"><!--[if !supportLists]--><span style="font-family:'Times New Roman';font-size:10.0000pt;mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">G、<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">在监控区域内不应有障碍物。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:60.0000pt;text-indent:-18.0000pt;mso-list:l2 level1 lfo5;"><!--[if !supportLists]--><span style="font-family:'Times New Roman';font-size:10.0000pt;mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">H、<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">不应将被动红外探测器探头对准任何温度会快速改变的物体，特别是发热体。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:60.0000pt;text-indent:-18.0000pt;mso-list:l2 level1 lfo5;"><!--[if !supportLists]--><span style="font-family:'Times New Roman';font-size:10.0000pt;mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">I、<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">不要将被动红外探测器安装在某些发热源的上方或其附近。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:60.0000pt;text-indent:-18.0000pt;mso-list:l2 level1 lfo5;"><!--[if !supportLists]--><span style="font-family:'Times New Roman';font-size:10.0000pt;mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">J、<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">尽量选择那些静止不动的、温度不易变化的物体作为探测区的背景物体。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:60.0000pt;text-indent:-18.0000pt;mso-list:l2 level1 lfo5;"><!--[if !supportLists]--><span style="font-family:'Times New Roman';font-size:10.0000pt;mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">K、<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">应使探测器具有最大的警戒范围，使可能的入侵者都处于红外警戒的光束范围之内，并使入侵者的活动有利于横向穿越光束带区。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:60.0000pt;text-indent:-18.0000pt;mso-list:l2 level1 lfo5;"><!--[if !supportLists]--><span style="font-family:'Times New Roman';font-size:10.0000pt;mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">L、<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">注意保护菲涅尔透镜。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:18.0000pt;"><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,255);
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">被动红外探测器安装注意事项</font>:</span><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,255);
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:45.0000pt;text-indent:-21.0000pt;mso-list:l6 level5 lfo1;"><!--[if !supportLists]--><span style="font-family:Wingdings;mso-fareast-font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">l<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">安装部位应避开误报源：避免直接将探测器安装在可能造成误报的物体附近，比如冷</font>/热源（空调器、电冰箱、加热设备等）、荧光灯和通风口等必须尽量远离。</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:45.0000pt;text-indent:-21.0000pt;mso-list:l6 level5 lfo1;"><!--[if !supportLists]--><span style="font-family:Wingdings;mso-fareast-font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">l<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">探测视区内不得有障碍物：在探测范围内，应保证探测器有良好的</font>“视线”，即应当给探测器的视区保留一定的空间，不应有任何障碍物（比如：货架、家具、隔断等）遮挡探测器的“视线”，以免影响探测性能。</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:45.0000pt;text-indent:-21.0000pt;mso-list:l6 level5 lfo1;"><!--[if !supportLists]--><span style="font-family:Wingdings;mso-fareast-font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">l<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">安装时应避免将带有俯视区的探测器安装在货架、家具、镜框等物体的正上方。因为老鼠有可能从这些物体上面爬过，由于距探测器过于接近，从而造成误报。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:45.0000pt;text-indent:-21.0000pt;mso-list:l6 level5 lfo1;"><!--[if !supportLists]--><span style="font-family:Wingdings;mso-fareast-font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">l<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">当目标沿着横向切割红外视区的方向行走时，探测器最为灵敏；而面对或背向探测器行走时，探测器的反应最为迟钝。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:45.0000pt;text-indent:-21.0000pt;mso-list:l6 level5 lfo1;"><!--[if !supportLists]--><span style="font-family:Wingdings;mso-fareast-font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">l<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">安装探测器的物体表面应牢固。避免将探测器安装在晃动（抖动）的天花板、墙面、柱子或支架上，否则有可能引起误报。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:45.0000pt;text-indent:-21.0000pt;mso-list:l6 level5 lfo1;"><!--[if !supportLists]--><span style="font-family:Wingdings;mso-fareast-font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">l<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">完毕后，应将导线全部抽出探测器机壳外，以免影响视区分布。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:45.0000pt;text-indent:-21.0000pt;mso-list:l6 level5 lfo1;"><!--[if !supportLists]--><span style="font-family:Wingdings;mso-fareast-font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">l<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">安装完毕后，应用硅胶密封机壳上所有的孔洞，以防昆虫进入探测器内部，造成误报或影响探测性能。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal"><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p>&nbsp;</o:p></span></p><p class="MsoNormal" style="margin-left:0.0000pt;text-indent:0.0000pt;mso-list:l6 level1 lfo1;"><!--[if !supportLists]--><span style="font-family:宋体;mso-ansi-font-weight:bold;font-size:12.0000pt;
            mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">9、<span>&nbsp;</span></span></span><!--[endif]--><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:宋体;mso-ansi-font-weight:bold;
            font-size:12.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">开关式探测器</font></span></b><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:宋体;mso-ansi-font-weight:bold;
            font-size:12.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></b></p><p class="MsoNormal"><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:宋体;mso-ansi-font-weight:bold;
            font-size:10.0000pt;mso-font-kerning:1.0000pt;">&nbsp;&nbsp;&nbsp;</span></b><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">通过各种类型开关的闭合或断开来控制电路产生通、断，从而触发报警的。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal"><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;">&nbsp;&nbsp;&nbsp;<font face="宋体">常用的开关式传感器有磁控开关、微动开关、紧急报警开关、压力垫或用金属丝、金属条、金属箔等来代用的多种类型的开关。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:-18.0000pt;mso-list:l11 level1 lfo6;"><!--[if !supportLists]--><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">①<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">磁控开关：</font>(又称磁控管开关或磁簧开关)，是由永久磁铁块及干簧管(又称磁簧管或磁控管)两部分组成（间距、表面或隐藏式安装）。其体积小、耗电少、使用方便、价格便宜，而且动作灵敏、抗腐蚀性能好。</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:35.9000pt;mso-para-margin-left:3.4200gd;"><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,255);
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">安装注意要点：</font></span><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,255);
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:0.0000pt;mso-list:l6 level3 lfo1;"><!--[if !supportLists]--><span style="font-family:'Times New Roman';font-size:10.0000pt;mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">A、<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">干簧管和磁铁之间的距离应按产品要求正确安装。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:0.0000pt;mso-list:l6 level3 lfo1;"><!--[if !supportLists]--><span style="font-family:'Times New Roman';font-size:10.0000pt;mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">B、<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">经常注意检查永久磁铁的磁性是否减弱。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:0.0000pt;mso-list:l6 level3 lfo1;"><!--[if !supportLists]--><span style="font-family:'Times New Roman';font-size:10.0000pt;mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">C、<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">不宜在钢、铁物体上直接安装。必须安装时，应采用钢门专用型磁控开关。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:0.0000pt;mso-list:l6 level3 lfo1;"><!--[if !supportLists]--><span style="font-family:'Times New Roman';font-size:10.0000pt;mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">D、<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">安装位置：离门轴太近可能漏报，太远又可能误报，最好安装在门轴</font>2/3处。</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:-18.0000pt;mso-list:l11 level1 lfo6;"><!--[if !supportLists]--><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">②<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">微动开关：是一个整体部件，需要靠外部的作用力通过传动部件带动，将内部簧片的接点接通或断开。其结构简单、安装方便、价格便宜、防震性能好、触点可承受较大的电流，而且可以安装在金属物体上。缺点是抗腐蚀性及动作灵敏程度不如磁控开关。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:-18.0000pt;mso-list:l11 level1 lfo6;"><!--[if !supportLists]--><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">③<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">紧急报警开关：</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:35.9000pt;mso-para-margin-left:3.4200gd;"><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,255);
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">安装注意要点：</font></span><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,255);
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:60.0000pt;text-indent:-18.0000pt;mso-list:l5 level1 lfo7;"><!--[if !supportLists]--><span style="font-family:'Times New Roman';font-size:10.0000pt;mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">A、<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">紧急按钮药安装在容易接触到但又不容易引起误按的地方。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:60.0000pt;text-indent:-18.0000pt;mso-list:l5 level1 lfo7;"><!--[if !supportLists]--><span style="font-family:'Times New Roman';font-size:10.0000pt;mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">B、<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">紧急按钮轻易不用，容易忽略其存在，注意安装地方不要太过潮湿，以免腐蚀其触点。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:-18.0000pt;mso-list:l11 level1 lfo6;"><!--[if !supportLists]--><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">④<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">带有开关的防抢钱夹</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:-18.0000pt;mso-list:l11 level1 lfo6;"><!--[if !supportLists]--><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">⑤<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">用金属丝、金属条等导电体的断裂来代替开关</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:-18.0000pt;mso-list:l11 level1 lfo6;"><!--[if !supportLists]--><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">⑥<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">压力垫：由两条平行放置的具有弹性的金属带构成，中间有几处用很薄的绝缘材料将两块金属条支撑着绝缘分开（窗户、楼梯和保险柜周围的地毯下面）。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal"><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p>&nbsp;</o:p></span></p><p class="MsoNormal" style="margin-left:0.0000pt;text-indent:0.0000pt;mso-list:l6 level1 lfo1;"><!--[if !supportLists]--><span style="font-family:宋体;mso-ansi-font-weight:bold;font-size:12.0000pt;
            mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">10、<span>&nbsp;</span></span></span><!--[endif]--><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:宋体;mso-ansi-font-weight:bold;
            font-size:12.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">振动探测器</font></span></b><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:宋体;mso-ansi-font-weight:bold;
            font-size:12.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></b></p><p class="MsoNormal" style="margin-left:18.0000pt;"><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">是以探测入侵者的走动或进行各种破坏活动时所产生的振动信号来作为报警的依据（振动频率、振动周期、振动幅度）。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:18.0000pt;"><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">常用的几种振动探测器：机械式振动探测器、惯性棒电子式振动探测器、电动式振动探测器、压电晶体振动探测器、电子式全面型振动探测器。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:18.0000pt;"><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">机械式振动探测器：是一种振动型的机械开关。安装在墙壁、天花板或其他能产生振动的地方；适用于室内或室外周界。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:18.0000pt;"><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">惯性棒电子式振动探测器：是一根金属棒架在两组交叉的镀金金属架上，金属棒与金属架之间构成闭合回路。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:18.0000pt;"><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">电子式全面型振动探测器：是指可以探测到由各种入侵方式（如爆炸、焊枪、锤击、电钻、电锯、水压工具等）所引发的振动信号，但对在防区内人员的正常走动则不会引起误报。适用于金库、银行保险柜等处使用。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:18.0000pt;"><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">电动式振动探测器：由一根条形永久磁铁和一个绕有线圈的圆形筒组成，在线圈中存在由永久磁铁产生的磁通，磁通变化产生报警。适用于地面周界保护或周界的钢丝网上。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:18.0000pt;"><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">压电晶体振动探测器：</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:18.0000pt;"><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,255);
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">振动探测器的安装注意要点：</font></span><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,255);
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:17.8500pt;mso-para-margin-left:1.7000gd;text-indent:0.0000pt;
            mso-list:l9 level1 lfo8;"><!--[if !supportLists]--><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">①<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">安装在墙壁或天花板等处时，与这些物体必须固定牢固。用于探测地面振动时，应将传感器周围的泥土压实。不适合用于地质板结的冻土地带或土质松软的泥沙沼泽地带</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:17.8500pt;mso-para-margin-left:1.7000gd;text-indent:0.0000pt;
            mso-list:l9 level1 lfo8;"><!--[if !supportLists]--><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">②<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">安装位置应远离振动源（如旋转的电机）。在室外应用时，埋入地下的震动探测器应与其他的物体保持一定距离（</font>1-3cm以上）。</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:17.8500pt;mso-para-margin-left:1.7000gd;text-indent:0.0000pt;
            mso-list:l9 level1 lfo8;"><!--[if !supportLists]--><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">③<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">需要定期检修，以确保灵敏度</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal"><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:宋体;mso-ansi-font-weight:bold;
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p>&nbsp;</o:p></span></b></p><p class="MsoNormal" style="margin-left:0.0000pt;text-indent:0.0000pt;mso-list:l6 level1 lfo1;"><!--[if !supportLists]--><span style="font-family:宋体;mso-ansi-font-weight:bold;font-size:12.0000pt;
            mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">11、<span>&nbsp;</span></span></span><!--[endif]--><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:宋体;mso-ansi-font-weight:bold;
            font-size:12.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">玻璃破碎探测器</font></span></b><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:宋体;mso-ansi-font-weight:bold;
            font-size:12.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></b></p><p class="MsoNormal"><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:宋体;mso-ansi-font-weight:bold;
            font-size:10.0000pt;mso-font-kerning:1.0000pt;">&nbsp;&nbsp;&nbsp;</span></b><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">专门用来探测玻璃破碎功能的一种探测器。按照工作原理的不同，分为声控型的单技术和双技术（声控型</font>-振动、次声波-高频声响）玻璃破碎探测器。适用于一切需要警戒玻璃防碎的场所。</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal"><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;">&nbsp;&nbsp;&nbsp;&nbsp;<font face="宋体">次声波探测技术：次声波是低于</font>20Hz的声波，属于不可闻声波。与探测玻璃破碎高频声响相似的原理，采用具有选频作用的声控探测技术，即可探测到次声波的存在。</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="text-indent:20.0000pt;mso-char-indent-count:2.0000;"><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,255);
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">次声波</font>-高频声响玻璃破碎探测器</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">采用了次声波探测技术</font>(用于检测敲击玻璃时所产生的超低频次声波振动</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">）</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">与玻璃破碎高频声响探测技术</font>(用于探测玻璃破碎时发出的高频声响)。</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:18.0000pt;"><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,255);
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">玻璃破碎探测器的安装注意要点：</font></span><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,255);
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:18.0000pt;text-indent:0.0000pt;mso-list:l3 level1 lfo9;"><!--[if !supportLists]--><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">①<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">安装时应将声电传感器正对着警戒的主要方向，但前面不应有遮挡物。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:18.0000pt;text-indent:0.0000pt;mso-list:l3 level1 lfo9;"><!--[if !supportLists]--><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">②<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">探测器的灵敏度应调整到一个合适的值，一般以能探测到距探测器最远的被保护玻璃即可。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:18.0000pt;text-indent:0.0000pt;mso-list:l3 level1 lfo9;"><!--[if !supportLists]--><span style="font-family:宋体;color:rgb(255,0,0);font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">③<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(255,0,0);
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">一般玻璃破碎除非特殊说明，都不能和被保护的玻璃位于同一面墙上。</font></span><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(255,0,0);
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:18.0000pt;text-indent:0.0000pt;mso-list:l3 level1 lfo9;"><!--[if !supportLists]--><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">④<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">不同种类的玻璃破碎探测器，根据其工作原理的不同，有的需要安装在窗框旁边（一般距离框</font>5cm左右），有的可以安装在靠近玻璃附近的墙壁或天花板上，但要求玻璃与墙壁或天花板之间的夹角不得大于90°。</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:18.0000pt;text-indent:0.0000pt;mso-list:l3 level1 lfo9;"><!--[if !supportLists]--><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">⑤<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">当用一个玻璃破碎探测器保护多面玻璃窗时，可将玻璃破碎探测器安装在房间的天花板上，并与几个被保护玻璃窗之间保持大致相同的探测距离。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:18.0000pt;text-indent:0.0000pt;mso-list:l3 level1 lfo9;"><!--[if !supportLists]--><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">⑥<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">安装时应避免窗帘、百叶窗或其他遮盖物的影响。可以安装在窗帘背面的门窗框架上或门窗的上方。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:18.0000pt;text-indent:0.0000pt;mso-list:l3 level1 lfo9;"><!--[if !supportLists]--><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">⑦<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">不要安装在通风口或换气扇的前面，也不要靠近门铃。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:18.0000pt;text-indent:0.0000pt;mso-list:l3 level1 lfo9;"><!--[if !supportLists]--><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">⑧<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">大多玻璃破碎探测器依靠探测声音来判断报警，注意安装环境不能太嘈杂。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal"><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p>&nbsp;</o:p></span></p><p class="MsoNormal" style="margin-left:0.0000pt;text-indent:0.0000pt;mso-list:l6 level1 lfo1;"><!--[if !supportLists]--><span style="font-family:宋体;mso-ansi-font-weight:bold;font-size:12.0000pt;
            mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">12、<span>&nbsp;</span></span></span><!--[endif]--><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:宋体;mso-ansi-font-weight:bold;
            font-size:12.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">烟感探测器</font></span></b><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:宋体;mso-ansi-font-weight:bold;
            font-size:12.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></b></p><p class="MsoNormal"><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;">&nbsp;&nbsp;&nbsp;<font face="宋体">安装注意事项：</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:-18.0000pt;mso-list:l6 level5 lfo1;"><!--[if !supportLists]--><span style="font-family:Wingdings;mso-fareast-font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">l<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">烟感探测器探测的是烟尘离子，安装环境应该洁净，否则容易引起误报。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:-18.0000pt;mso-list:l6 level5 lfo1;"><!--[if !supportLists]--><span style="font-family:Wingdings;mso-fareast-font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">l<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">即使选择的探测器可以探测的范围很大，也不可能探测到其他封闭房间的烟雾，安装时要注意。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:-18.0000pt;mso-list:l6 level5 lfo1;"><!--[if !supportLists]--><span style="font-family:Wingdings;mso-fareast-font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">l<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">空气流通太好的房间也有可能会及时把烟雾吹走，难以在烟感探测器内积聚，可能会误报。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:-18.0000pt;mso-list:l6 level5 lfo1;"><!--[if !supportLists]--><span style="font-family:Wingdings;mso-fareast-font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">l<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">光电烟感一般探测的是火焰；离子式探测的是烟尘离子，所以保护的火种有区别。光电式一般用于保护燃烧迅速、火焰明显的地方；离子式一般用于保护燃烧不明显、火焰少、烟雾多的地方。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal"><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p>&nbsp;</o:p></span></p><p class="MsoNormal" style="margin-left:0.0000pt;text-indent:0.0000pt;mso-list:l6 level1 lfo1;"><!--[if !supportLists]--><span style="font-family:宋体;mso-ansi-font-weight:bold;font-size:12.0000pt;
            mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">13、<span>&nbsp;</span></span></span><!--[endif]--><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:宋体;mso-ansi-font-weight:bold;
            font-size:12.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">微波（雷达式或微波墙式）探测器</font></span></b><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:宋体;mso-ansi-font-weight:bold;
            font-size:12.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></b></p><p class="MsoNormal"><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:宋体;mso-ansi-font-weight:bold;
            font-size:10.0000pt;mso-font-kerning:1.0000pt;">&nbsp;&nbsp;</span></b><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;">&nbsp;<font face="宋体">雷达式：适用于保护狭长的地点，如走廊和通道等处；</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:67.6500pt;mso-para-margin-left:1.7000gd;text-indent:-49.8000pt;
            mso-char-indent-count:-4.9800;"><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">微波墙式：适用于露天仓库、施工现场、飞机场、监狱、劳改农场或博物馆等大楼墙外的室外周界场所。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:18.0000pt;text-indent:0.0000pt;mso-list:l6 level3 lfo1;"><!--[if !supportLists]--><span style="font-family:'Times New Roman';font-size:10.0000pt;mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">A、<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">多普勒效应：指当发射源</font>(声源或电磁波源)与接收者之间有相对径向运动时，接收到的信号频率将发生变化。</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:18.0000pt;text-indent:0.0000pt;mso-list:l6 level3 lfo1;"><!--[if !supportLists]--><span style="font-family:'Times New Roman';font-size:10.0000pt;mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">B、<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">雷达式微波探测器对警戒区域内活动目标的探测是有一定范围的</font>(立体防范空间)。</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:18.0000pt;text-indent:0.0000pt;mso-list:l6 level3 lfo1;"><!--[if !supportLists]--><span style="font-family:'Times New Roman';font-size:10.0000pt;mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">C、<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">微波探测器的探头不应对准可能会活动的物体，如门帘、窗帘、电风扇、排气扇或门、窗等可能会振动的部位。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:18.0000pt;text-indent:0.0000pt;mso-list:l6 level3 lfo1;"><!--[if !supportLists]--><span style="font-family:'Times New Roman';font-size:10.0000pt;mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">D、<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">在监控区域内不应有过大、过厚的物体，特别是金属物体。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:18.0000pt;text-indent:0.0000pt;mso-list:l6 level3 lfo1;"><!--[if !supportLists]--><span style="font-family:'Times New Roman';font-size:10.0000pt;mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">E、<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">微波探测器不应对准日光灯、水银灯等气体放电灯光源。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:18.0000pt;text-indent:0.0000pt;mso-list:l6 level3 lfo1;"><!--[if !supportLists]--><span style="font-family:'Times New Roman';font-size:10.0000pt;mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">F、<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">在室外环境使用时，无法保证其探测的可靠性。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:18.0000pt;text-indent:0.0000pt;mso-list:l6 level3 lfo1;"><!--[if !supportLists]--><span style="font-family:'Times New Roman';font-size:10.0000pt;mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">G、<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">当在同一室内需要安装两个以上的微波探测器时，它们之间的微波发射频率应当有所差异</font>(一般相差25MHz左右)。而且不要相对放置，以防止交叉干扰。</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:18.0000pt;"><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,255);
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">安装注意事项</font>:</span><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,255);
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:45.0000pt;text-indent:-21.0000pt;mso-list:l6 level5 lfo1;"><!--[if !supportLists]--><span style="font-family:Wingdings;mso-fareast-font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">l<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal"><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p>&nbsp;</o:p></span></p><p class="MsoNormal" style="margin-left:0.0000pt;text-indent:0.0000pt;mso-list:l6 level1 lfo1;"><!--[if !supportLists]--><span style="font-family:宋体;mso-ansi-font-weight:bold;font-size:12.0000pt;
            mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">14、<span>&nbsp;</span></span></span><!--[endif]--><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:宋体;mso-ansi-font-weight:bold;
            font-size:12.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">声控探测器</font></span></b><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:宋体;mso-ansi-font-weight:bold;
            font-size:12.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></b></p><p class="MsoNormal" style="margin-left:18.0000pt;"><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">利用由声电传感器做成的监听头对监控现场进行立体式空间警戒的探测系统。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:18.0000pt;"><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">属于空间控制型探测器，其结构简单、价格低廉、体积小巧、安装方便。适合于在环境噪声较小的仓库、博物馆。银行金库、机要室等处或夜深人静时使用。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal"><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,255);
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p>&nbsp;</o:p></span></p><p class="MsoNormal" style="margin-left:0.0000pt;text-indent:0.0000pt;mso-list:l6 level1 lfo1;"><!--[if !supportLists]--><span style="font-family:宋体;mso-ansi-font-weight:bold;font-size:12.0000pt;
            mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">15、<span>&nbsp;</span></span></span><!--[endif]--><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:宋体;mso-ansi-font-weight:bold;
            font-size:12.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">视频探测器</font></span></b><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:宋体;mso-ansi-font-weight:bold;
            font-size:12.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></b></p><p class="MsoNormal"><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:宋体;mso-ansi-font-weight:bold;
            font-size:12.0000pt;mso-font-kerning:1.0000pt;">&nbsp;&nbsp;&nbsp;</span></b><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">是将电视监视技术与报警技术相结合的一种新型的安全防范报警设备。它是用电视摄像机来作为遥测传感器，通过检测被监视区域的图像变化，从而报警的一种装置。适用于博物馆、商场、宾馆、仓库、金库等。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:18.0000pt;"><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,255);
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">视频探测器的安装注意要点：</font></span><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,255);
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:18.0000pt;"><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">安装时应避免环境光对镜头的直接照射，并尽量避免在摄像视场内出现经常开、关的照明光源。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal"><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p>&nbsp;</o:p></span></p><p class="MsoNormal" style="margin-left:0.0000pt;text-indent:0.0000pt;mso-list:l6 level1 lfo1;"><!--[if !supportLists]--><span style="font-family:宋体;mso-ansi-font-weight:bold;font-size:12.0000pt;
            mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">16、<span>&nbsp;</span></span></span><!--[endif]--><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:宋体;mso-ansi-font-weight:bold;
            font-size:12.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">其他周界防御探测器</font></span></b><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:宋体;mso-ansi-font-weight:bold;
            font-size:12.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></b></p><p class="MsoNormal"><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:宋体;mso-ansi-font-weight:bold;
            font-size:12.0000pt;mso-font-kerning:1.0000pt;">&nbsp;&nbsp;&nbsp;</span></b><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">在一些重要的区域，如机场、军事基地、武器弹药库、监狱、银行金库、博物馆、发电厂、油库、高级中外人员的住地等处，必须要增设一些可用来进行周界防范的探测器，并与电子控制电路相配合组成周界防御报警系统。用于周界防御报警的探测器有很多种，常用的有振动电缆探测器、电场式探测器、泄漏电缆探测器、光纤探测器，以及其他一些机电式探测器、压电式探测器、振动式探测器等。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:21.0000pt;text-indent:-21.0000pt;mso-list:l12 level1 lfo10;"><!--[if !supportLists]--><span style="font-family:Wingdings;mso-fareast-font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">l<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">泄漏电缆探测器：由平行的两根泄漏电缆组成，一根泄漏同轴电缆与发射机相连，向外发射能量；另一根泄漏同轴电缆与接收机相连，用来接收能量。发射机发射的高频电磁能（频率约为</font>30-300MHz左右）经发射电缆向外辐射，一部分能量耦合到接收电缆，收发电缆之间的空间形成一个椭圆形的电磁场的探测区域。适合用在隧道、地道、过道、走廊、楼梯、井、烟囱等处。</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="text-indent:10.0000pt;mso-char-indent-count:1.0000;"><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;">&nbsp;&nbsp;</span><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,255);
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">安装注意要点：</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:18.0000pt;text-indent:0.0000pt;mso-list:l6 level4 lfo1;"><!--[if !supportLists]--><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">①<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">为了消除对鸟、猫等小动物或其他小动物通过所引起的误报警，必须准确调节其灵敏度。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:18.0000pt;text-indent:0.0000pt;mso-list:l6 level4 lfo1;"><!--[if !supportLists]--><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">②<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">当警戒周界较长时，可将几对收、发电缆和收、发机适当串联在一起，即可构成一道长长的警戒线。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:18.0000pt;text-indent:0.0000pt;mso-list:l6 level4 lfo1;"><!--[if !supportLists]--><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">③<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">在掩埋泄漏电缆的地表面上不能放置成堆的金属物体。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:0.0000pt;text-indent:0.0000pt;mso-list:l6 level5 lfo1;"><!--[if !supportLists]--><span style="font-family:Wingdings;mso-fareast-font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">l<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">光纤探测器：</font>①利用光纤断裂使光路中断的探测器。②利用光纤中光传输模式发生变化的探测器。③利用光纤中光路发生变化的探测器。</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:0.0000pt;text-indent:0.0000pt;mso-list:l6 level5 lfo1;"><!--[if !supportLists]--><span style="font-family:Wingdings;mso-fareast-font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">l<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">振动电缆探测器：</font>①驻极体振动电缆探测器。②电磁感应式振动电缆探测器。</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:0.0000pt;text-indent:0.0000pt;mso-list:l6 level5 lfo1;"><!--[if !supportLists]--><span style="font-family:Wingdings;mso-fareast-font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">l<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">电场感应式探测器：通过检测场线和感应线之间（一对一、一对二）输出的感应电压的幅度、速率或干扰的持续时间等方面的变化来探测入侵者。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:0.0000pt;text-indent:0.0000pt;mso-list:l6 level5 lfo1;"><!--[if !supportLists]--><span style="font-family:Wingdings;mso-fareast-font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">l<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">电容变化式探测器：可通过测量敏感线和接地线之间的电容变化来探测入侵者。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal"><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p>&nbsp;</o:p></span></p><p class="MsoNormal" style="margin-left:0.0000pt;text-indent:0.0000pt;mso-list:l6 level1 lfo1;"><!--[if !supportLists]--><span style="font-family:宋体;mso-ansi-font-weight:bold;font-size:12.0000pt;
            mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">17、<span>&nbsp;</span></span></span><!--[endif]--><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:宋体;mso-ansi-font-weight:bold;
            font-size:12.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">单技术探测器的影响因素</font></span></b><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:宋体;mso-ansi-font-weight:bold;
            font-size:12.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></b></p><p class="MsoNormal"><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;">&nbsp;&nbsp;&nbsp;&nbsp;<font face="宋体">单技术探测器结构简单，价格低廉。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:18.0000pt;text-indent:0.0000pt;mso-list:l6 level4 lfo1;"><!--[if !supportLists]--><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">①<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">共同缺点：受到各种不同因素的影响，在不同的恶劣工作环境下，可因受到各种不同的误报源的干扰而产生误报警（参见下表）。</font></span><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:宋体;mso-ansi-font-weight:bold;
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></b></p><table class="MsoTableGrid" border="1" cellspacing="0" style="border-collapse:collapse;width:442.5000pt;margin-left:23.4000pt;
            border:none;mso-border-left-alt:0.5000pt solid windowtext;mso-border-top-alt:0.5000pt solid windowtext;
            mso-border-right-alt:0.5000pt solid windowtext;mso-border-bottom-alt:0.5000pt solid windowtext;mso-border-insideh:0.5000pt solid windowtext;
            mso-border-insidev:0.5000pt solid windowtext;mso-padding-alt:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;"><tbody><tr><td width="270" valign="top" style="width:135.0000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid windowtext;
            mso-border-left-alt:0.5000pt solid windowtext;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
            border-top:1.0000pt solid windowtext;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
            mso-border-bottom-alt:0.5000pt solid windowtext;"><p class="MsoNormal" align="center" style="text-align:center;"><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">因素</font></span><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td><td width="283" valign="top" style="width:141.9000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid windowtext;
            mso-border-left-alt:0.5000pt solid windowtext;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
            border-top:1.0000pt solid windowtext;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
            mso-border-bottom-alt:0.5000pt solid windowtext;"><p class="MsoNormal" align="center" style="text-align:center;"><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">红外</font></span><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td><td width="165" valign="top" style="width:82.8000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid windowtext;
            mso-border-left-alt:0.5000pt solid windowtext;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
            border-top:1.0000pt solid windowtext;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
            mso-border-bottom-alt:0.5000pt solid windowtext;"><p class="MsoNormal" align="center" style="text-align:center;"><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">微波</font></span><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td><td width="165" valign="top" style="width:82.8000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid windowtext;
            mso-border-left-alt:0.5000pt solid windowtext;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
            border-top:1.0000pt solid windowtext;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
            mso-border-bottom-alt:0.5000pt solid windowtext;"><p class="MsoNormal" align="center" style="text-align:center;"><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">超声波</font></span><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td></tr><tr><td width="270" valign="top" style="width:135.0000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid windowtext;
            mso-border-left-alt:0.5000pt solid windowtext;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
            border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
            mso-border-bottom-alt:0.5000pt solid windowtext;"><p class="MsoNormal"><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">振动</font></span><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td><td width="283" valign="top" style="width:141.9000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid windowtext;
            mso-border-left-alt:0.5000pt solid windowtext;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
            border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
            mso-border-bottom-alt:0.5000pt solid windowtext;"><p class="MsoNormal"><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">问题不大</font></span><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td><td width="165" valign="top" style="width:82.8000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid windowtext;
            mso-border-left-alt:0.5000pt solid windowtext;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
            border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
            mso-border-bottom-alt:0.5000pt solid windowtext;"><p class="MsoNormal"><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">有问题</font></span><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td><td width="165" valign="top" style="width:82.8000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid windowtext;
            mso-border-left-alt:0.5000pt solid windowtext;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
            border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
            mso-border-bottom-alt:0.5000pt solid windowtext;"><p class="MsoNormal"><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">问题不大</font></span><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td></tr><tr><td width="270" valign="top" style="width:135.0000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid windowtext;
            mso-border-left-alt:0.5000pt solid windowtext;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
            border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
            mso-border-bottom-alt:0.5000pt solid windowtext;"><p class="MsoNormal"><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">被大型金属物体反射</font></span><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td><td width="283" valign="top" style="width:141.9000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid windowtext;
            mso-border-left-alt:0.5000pt solid windowtext;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
            border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
            mso-border-bottom-alt:0.5000pt solid windowtext;"><p class="MsoNormal"><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">一般没问题，除非抛光金属面</font></span><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td><td width="165" valign="top" style="width:82.8000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid windowtext;
            mso-border-left-alt:0.5000pt solid windowtext;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
            border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
            mso-border-bottom-alt:0.5000pt solid windowtext;"><p class="MsoNormal"><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">有问题</font></span><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td><td width="165" valign="top" style="width:82.8000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid windowtext;
            mso-border-left-alt:0.5000pt solid windowtext;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
            border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
            mso-border-bottom-alt:0.5000pt solid windowtext;"><p class="MsoNormal"><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">极少有问题</font></span><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td></tr><tr><td width="270" valign="top" style="width:135.0000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid windowtext;
            mso-border-left-alt:0.5000pt solid windowtext;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
            border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
            mso-border-bottom-alt:0.5000pt solid windowtext;"><p class="MsoNormal"><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">对门、窗的晃动</font></span><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td><td width="283" valign="top" style="width:141.9000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid windowtext;
            mso-border-left-alt:0.5000pt solid windowtext;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
            border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
            mso-border-bottom-alt:0.5000pt solid windowtext;"><p class="MsoNormal"><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">问题不大</font></span><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td><td width="165" valign="top" style="width:82.8000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid windowtext;
            mso-border-left-alt:0.5000pt solid windowtext;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
            border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
            mso-border-bottom-alt:0.5000pt solid windowtext;"><p class="MsoNormal"><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">有问题</font></span><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td><td width="165" valign="top" style="width:82.8000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid windowtext;
            mso-border-left-alt:0.5000pt solid windowtext;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
            border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
            mso-border-bottom-alt:0.5000pt solid windowtext;"><p class="MsoNormal"><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">注意安装位置</font></span><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td></tr><tr><td width="270" valign="top" style="width:135.0000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid windowtext;
            mso-border-left-alt:0.5000pt solid windowtext;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
            border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
            mso-border-bottom-alt:0.5000pt solid windowtext;"><p class="MsoNormal"><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">对小动物的活动</font></span><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td><td width="283" valign="top" style="width:141.9000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid windowtext;
            mso-border-left-alt:0.5000pt solid windowtext;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
            border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
            mso-border-bottom-alt:0.5000pt solid windowtext;"><p class="MsoNormal"><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">靠近有问题，但可改变指向或用挡光片</font></span><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td><td width="165" valign="top" style="width:82.8000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid windowtext;
            mso-border-left-alt:0.5000pt solid windowtext;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
            border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
            mso-border-bottom-alt:0.5000pt solid windowtext;"><p class="MsoNormal"><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">靠近有问题</font></span><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td><td width="165" valign="top" style="width:82.8000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid windowtext;
            mso-border-left-alt:0.5000pt solid windowtext;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
            border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
            mso-border-bottom-alt:0.5000pt solid windowtext;"><p class="MsoNormal"><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">靠近有问题</font></span><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td></tr><tr><td width="270" valign="top" style="width:135.0000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid windowtext;
            mso-border-left-alt:0.5000pt solid windowtext;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
            border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
            mso-border-bottom-alt:0.5000pt solid windowtext;"><p class="MsoNormal"><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">水在塑料管中流动</font></span><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td><td width="283" valign="top" style="width:141.9000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid windowtext;
            mso-border-left-alt:0.5000pt solid windowtext;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
            border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
            mso-border-bottom-alt:0.5000pt solid windowtext;"><p class="MsoNormal"><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">没问题</font></span><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td><td width="165" valign="top" style="width:82.8000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid windowtext;
            mso-border-left-alt:0.5000pt solid windowtext;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
            border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
            mso-border-bottom-alt:0.5000pt solid windowtext;"><p class="MsoNormal"><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">靠近有问题</font></span><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td><td width="165" valign="top" style="width:82.8000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid windowtext;
            mso-border-left-alt:0.5000pt solid windowtext;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
            border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
            mso-border-bottom-alt:0.5000pt solid windowtext;"><p class="MsoNormal"><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">没问题</font></span><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td></tr><tr><td width="270" valign="top" style="width:135.0000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid windowtext;
            mso-border-left-alt:0.5000pt solid windowtext;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
            border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
            mso-border-bottom-alt:0.5000pt solid windowtext;"><p class="MsoNormal"><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">在薄墙或玻璃窗外侧活动</font></span><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td><td width="283" valign="top" style="width:141.9000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid windowtext;
            mso-border-left-alt:0.5000pt solid windowtext;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
            border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
            mso-border-bottom-alt:0.5000pt solid windowtext;"><p class="MsoNormal"><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">没问题</font></span><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td><td width="165" valign="top" style="width:82.8000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid windowtext;
            mso-border-left-alt:0.5000pt solid windowtext;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
            border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
            mso-border-bottom-alt:0.5000pt solid windowtext;"><p class="MsoNormal"><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">注意安装位置</font></span><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td><td width="165" valign="top" style="width:82.8000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid windowtext;
            mso-border-left-alt:0.5000pt solid windowtext;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
            border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
            mso-border-bottom-alt:0.5000pt solid windowtext;"><p class="MsoNormal"><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">没问题</font></span><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td></tr><tr><td width="270" valign="top" style="width:135.0000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid windowtext;
            mso-border-left-alt:0.5000pt solid windowtext;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
            border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
            mso-border-bottom-alt:0.5000pt solid windowtext;"><p class="MsoNormal"><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">通风口或空气流</font></span><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td><td width="283" valign="top" style="width:141.9000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid windowtext;
            mso-border-left-alt:0.5000pt solid windowtext;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
            border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
            mso-border-bottom-alt:0.5000pt solid windowtext;"><p class="MsoNormal"><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">温度较高的热对流有问题</font></span><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td><td width="165" valign="top" style="width:82.8000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid windowtext;
            mso-border-left-alt:0.5000pt solid windowtext;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
            border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
            mso-border-bottom-alt:0.5000pt solid windowtext;"><p class="MsoNormal"><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">没问题</font></span><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td><td width="165" valign="top" style="width:82.8000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid windowtext;
            mso-border-left-alt:0.5000pt solid windowtext;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
            border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
            mso-border-bottom-alt:0.5000pt solid windowtext;"><p class="MsoNormal"><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">注意安装位置</font></span><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td></tr><tr><td width="270" valign="top" style="width:135.0000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid windowtext;
            mso-border-left-alt:0.5000pt solid windowtext;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
            border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
            mso-border-bottom-alt:0.5000pt solid windowtext;"><p class="MsoNormal"><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">阳光、车大灯</font></span><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td><td width="283" valign="top" style="width:141.9000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid windowtext;
            mso-border-left-alt:0.5000pt solid windowtext;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
            border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
            mso-border-bottom-alt:0.5000pt solid windowtext;"><p class="MsoNormal"><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">注意安装位置</font></span><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td><td width="165" valign="top" style="width:82.8000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid windowtext;
            mso-border-left-alt:0.5000pt solid windowtext;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
            border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
            mso-border-bottom-alt:0.5000pt solid windowtext;"><p class="MsoNormal"><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">没问题</font></span><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td><td width="165" valign="top" style="width:82.8000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid windowtext;
            mso-border-left-alt:0.5000pt solid windowtext;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
            border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
            mso-border-bottom-alt:0.5000pt solid windowtext;"><p class="MsoNormal"><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">没问题</font></span><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td></tr><tr><td width="270" valign="top" style="width:135.0000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid windowtext;
            mso-border-left-alt:0.5000pt solid windowtext;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
            border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
            mso-border-bottom-alt:0.5000pt solid windowtext;"><p class="MsoNormal"><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">加热器、火炉</font></span><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td><td width="283" valign="top" style="width:141.9000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid windowtext;
            mso-border-left-alt:0.5000pt solid windowtext;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
            border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
            mso-border-bottom-alt:0.5000pt solid windowtext;"><p class="MsoNormal"><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">注意安装位置</font></span><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td><td width="165" valign="top" style="width:82.8000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid windowtext;
            mso-border-left-alt:0.5000pt solid windowtext;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
            border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
            mso-border-bottom-alt:0.5000pt solid windowtext;"><p class="MsoNormal"><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">没问题</font></span><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td><td width="165" valign="top" style="width:82.8000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid windowtext;
            mso-border-left-alt:0.5000pt solid windowtext;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
            border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
            mso-border-bottom-alt:0.5000pt solid windowtext;"><p class="MsoNormal"><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">极少有问题</font></span><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td></tr><tr><td width="270" valign="top" style="width:135.0000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid windowtext;
            mso-border-left-alt:0.5000pt solid windowtext;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
            border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
            mso-border-bottom-alt:0.5000pt solid windowtext;"><p class="MsoNormal"><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">运转的机械</font></span><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td><td width="283" valign="top" style="width:141.9000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid windowtext;
            mso-border-left-alt:0.5000pt solid windowtext;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
            border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
            mso-border-bottom-alt:0.5000pt solid windowtext;"><p class="MsoNormal"><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">问题不大</font></span><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td><td width="165" valign="top" style="width:82.8000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid windowtext;
            mso-border-left-alt:0.5000pt solid windowtext;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
            border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
            mso-border-bottom-alt:0.5000pt solid windowtext;"><p class="MsoNormal"><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">注意安装位置</font></span><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td><td width="165" valign="top" style="width:82.8000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid windowtext;
            mso-border-left-alt:0.5000pt solid windowtext;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
            border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
            mso-border-bottom-alt:0.5000pt solid windowtext;"><p class="MsoNormal"><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">注意安装位置</font></span><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td></tr><tr><td width="270" valign="top" style="width:135.0000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid windowtext;
            mso-border-left-alt:0.5000pt solid windowtext;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
            border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
            mso-border-bottom-alt:0.5000pt solid windowtext;"><p class="MsoNormal"><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">雷达干扰</font></span><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td><td width="283" valign="top" style="width:141.9000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid windowtext;
            mso-border-left-alt:0.5000pt solid windowtext;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
            border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
            mso-border-bottom-alt:0.5000pt solid windowtext;"><p class="MsoNormal"><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">问题不大</font></span><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td><td width="165" valign="top" style="width:82.8000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid windowtext;
            mso-border-left-alt:0.5000pt solid windowtext;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
            border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
            mso-border-bottom-alt:0.5000pt solid windowtext;"><p class="MsoNormal"><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">靠近有问题</font></span><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td><td width="165" valign="top" style="width:82.8000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid windowtext;
            mso-border-left-alt:0.5000pt solid windowtext;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
            border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
            mso-border-bottom-alt:0.5000pt solid windowtext;"><p class="MsoNormal"><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">极少有问题</font></span><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td></tr><tr><td width="270" valign="top" style="width:135.0000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid windowtext;
            mso-border-left-alt:0.5000pt solid windowtext;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
            border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
            mso-border-bottom-alt:0.5000pt solid windowtext;"><p class="MsoNormal"><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">荧光灯</font></span><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td><td width="283" valign="top" style="width:141.9000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid windowtext;
            mso-border-left-alt:0.5000pt solid windowtext;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
            border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
            mso-border-bottom-alt:0.5000pt solid windowtext;"><p class="MsoNormal"><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">没问题</font></span><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td><td width="165" valign="top" style="width:82.8000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid windowtext;
            mso-border-left-alt:0.5000pt solid windowtext;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
            border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
            mso-border-bottom-alt:0.5000pt solid windowtext;"><p class="MsoNormal"><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">靠近有问题</font></span><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td><td width="165" valign="top" style="width:82.8000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid windowtext;
            mso-border-left-alt:0.5000pt solid windowtext;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
            border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
            mso-border-bottom-alt:0.5000pt solid windowtext;"><p class="MsoNormal"><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">没问题</font></span><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td></tr><tr><td width="270" valign="top" style="width:135.0000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid windowtext;
            mso-border-left-alt:0.5000pt solid windowtext;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
            border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
            mso-border-bottom-alt:0.5000pt solid windowtext;"><p class="MsoNormal"><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">温度变化</font></span><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td><td width="283" valign="top" style="width:141.9000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid windowtext;
            mso-border-left-alt:0.5000pt solid windowtext;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
            border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
            mso-border-bottom-alt:0.5000pt solid windowtext;"><p class="MsoNormal"><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">有问题</font></span><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td><td width="165" valign="top" style="width:82.8000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid windowtext;
            mso-border-left-alt:0.5000pt solid windowtext;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
            border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
            mso-border-bottom-alt:0.5000pt solid windowtext;"><p class="MsoNormal"><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">没问题</font></span><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td><td width="165" valign="top" style="width:82.8000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid windowtext;
            mso-border-left-alt:0.5000pt solid windowtext;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
            border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
            mso-border-bottom-alt:0.5000pt solid windowtext;"><p class="MsoNormal"><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">有些问题</font></span><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td></tr><tr><td width="270" valign="top" style="width:135.0000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid windowtext;
            mso-border-left-alt:0.5000pt solid windowtext;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
            border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
            mso-border-bottom-alt:0.5000pt solid windowtext;"><p class="MsoNormal"><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">湿度变化</font></span><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td><td width="283" valign="top" style="width:141.9000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid windowtext;
            mso-border-left-alt:0.5000pt solid windowtext;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
            border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
            mso-border-bottom-alt:0.5000pt solid windowtext;"><p class="MsoNormal"><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">没问题</font></span><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td><td width="165" valign="top" style="width:82.8000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid windowtext;
            mso-border-left-alt:0.5000pt solid windowtext;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
            border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
            mso-border-bottom-alt:0.5000pt solid windowtext;"><p class="MsoNormal"><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">没问题</font></span><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td><td width="165" valign="top" style="width:82.8000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid windowtext;
            mso-border-left-alt:0.5000pt solid windowtext;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
            border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
            mso-border-bottom-alt:0.5000pt solid windowtext;"><p class="MsoNormal"><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">有问题</font></span><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td></tr><tr><td width="270" valign="top" style="width:135.0000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid windowtext;
            mso-border-left-alt:0.5000pt solid windowtext;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
            border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
            mso-border-bottom-alt:0.5000pt solid windowtext;"><p class="MsoNormal"><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">无线电干扰</font></span><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td><td width="283" valign="top" style="width:141.9000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid windowtext;
            mso-border-left-alt:0.5000pt solid windowtext;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
            border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
            mso-border-bottom-alt:0.5000pt solid windowtext;"><p class="MsoNormal"><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">严重时有问题</font></span><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td><td width="165" valign="top" style="width:82.8000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid windowtext;
            mso-border-left-alt:0.5000pt solid windowtext;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
            border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
            mso-border-bottom-alt:0.5000pt solid windowtext;"><p class="MsoNormal"><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">严重时有问题</font></span><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td><td width="165" valign="top" style="width:82.8000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid windowtext;
            mso-border-left-alt:0.5000pt solid windowtext;border-right:1.0000pt solid windowtext;mso-border-right-alt:0.5000pt solid windowtext;
            border-top:none;mso-border-top-alt:0.5000pt solid windowtext;border-bottom:1.0000pt solid windowtext;
            mso-border-bottom-alt:0.5000pt solid windowtext;"><p class="MsoNormal"><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">严重时有问题</font></span><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td></tr></tbody></table><p class="MsoNormal" style="margin-left:18.0000pt;text-indent:0.0000pt;mso-list:l6 level4 lfo1;"><!--[if !supportLists]--><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">②<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">解决误报：合理选用、安装和使用各种类型的探测器，不断提高探测性能和可靠性。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal"><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p>&nbsp;</o:p></span></p><p class="MsoNormal" style="margin-left:0.0000pt;text-indent:0.0000pt;mso-list:l6 level1 lfo1;"><!--[if !supportLists]--><span style="font-family:宋体;mso-ansi-font-weight:bold;font-size:12.0000pt;
            mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">18、<span>&nbsp;</span></span></span><!--[endif]--><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:宋体;mso-ansi-font-weight:bold;
            font-size:12.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">双鉴探测器</font></span></b><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:宋体;mso-ansi-font-weight:bold;
            font-size:12.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></b></p><p class="MsoNormal" style="margin-left:18.0000pt;"><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">双技术探测器是将两种探测技术结合在一起，只有当两种探测技术的传感器同时或相继在短暂时间内都探测到目标时，才可发出报警信号。如超声波</font>-微波、双被动红外、超声波-被动红外、玻璃破碎-振动、微波-被动红外等。</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:18.0000pt;"><span style="mso-spacerun:'yes';font-family:宋体;font-size:7.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">●</font></span><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,255);
            font-size:10.0000pt;mso-font-kerning:1.0000pt;">&nbsp;<font face="宋体">微波</font>-被动红外探测器</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">采用了微波及红外线两种探测技术，既提供了微波、被动红外探测器的优点，又克服了各自的缺点。</font></span><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,255);
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:18.0000pt;text-indent:0.0000pt;mso-list:l6 level4 lfo1;"><!--[if !supportLists]--><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">①<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">采用</font>IFT技术（双边独立浮动阀值技术）。</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:35.8500pt;mso-para-margin-left:1.7000gd;text-indent:-18.0000pt;
            mso-char-indent-count:-1.8000;mso-list:l6 level4 lfo1;"><!--[if !supportLists]--><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">②<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">微波监控功能（监控探测器是否工作正常）。当部件出现故障而不能正常工作时，能自动输出故障指示，而此时红外探测部分仍可以单技术方式工作。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:35.9500pt;mso-para-margin-left:1.7100gd;text-indent:-18.0000pt;
            mso-char-indent-count:-1.8000;mso-list:l6 level4 lfo1;"><!--[if !supportLists]--><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">③<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">采用微处理器智能分析技术（电脑单晶片信号档案和自适应式探测门限处理技术）。通过应用微处理器的模式确认技术，可使微处理器信号分析技术达到高度的智能化。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:18.0000pt;text-indent:0.0000pt;mso-list:l6 level4 lfo1;"><!--[if !supportLists]--><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">④<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">几种微波工作频率供选择。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:18.0000pt;text-indent:0.0000pt;mso-list:l6 level4 lfo1;"><!--[if !supportLists]--><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">⑤<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">采用</font>K-波段微波技术（频率更高、波段更短的微波信号）。</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:18.0000pt;text-indent:0.0000pt;mso-list:l6 level4 lfo1;"><!--[if !supportLists]--><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">⑥<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">采用双电子温度补偿措施。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:18.0000pt;text-indent:0.0000pt;mso-list:l6 level4 lfo1;"><!--[if !supportLists]--><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">⑦<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">采用白片菲涅耳透镜片（阻止白光的干扰）。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:18.0000pt;text-indent:0.0000pt;mso-list:l6 level4 lfo1;"><!--[if !supportLists]--><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">⑧<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">使用电子滤波器（消除日光灯对微波反射造成的干扰）。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:18.0000pt;text-indent:0.0000pt;mso-list:l6 level4 lfo1;"><!--[if !supportLists]--><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">⑨<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">增加俯视区反射镜式光路系统。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:18.0000pt;text-indent:0.0000pt;mso-list:l6 level4 lfo1;"><!--[if !supportLists]--><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">⑩<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">提高抗射频干扰的能力。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:18.0000pt;"><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,255);
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">微波</font>-被动红外双技术探测器的安装注意要点：</span><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,255);
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:18.0000pt;text-indent:0.0000pt;mso-list:l10 level1 lfo11;"><!--[if !supportLists]--><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">①<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">安装时通常要指向室内，避免直射朝向室外的窗户，应仔细调整好探测器的指向和视场。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:18.0000pt;text-indent:0.0000pt;mso-list:l10 level1 lfo11;"><!--[if !supportLists]--><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">②<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">对同时能引起两种探测传感器物报警的环境因素要避开或将影响减至最小。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:18.0000pt;text-indent:0.0000pt;mso-list:l10 level1 lfo11;"><!--[if !supportLists]--><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">③<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">微波具有一定的穿透能力，注意调节探测范围，要求局限在其探测范围之内。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:18.0000pt;text-indent:0.0000pt;mso-list:l10 level1 lfo11;"><!--[if !supportLists]--><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">④<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">安装时要考虑多个探测器之间的微波干扰问题，在多个探测器近距离安装时要特别注意。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="text-indent:17.5000pt;mso-char-indent-count:2.5000;"><span style="mso-spacerun:'yes';font-family:宋体;font-size:7.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">● </font></span><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,255);
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">超声波</font>-被动红外探测器</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">采用了超声波及红外线两种探测技术，安装时应避开同时能引起两种探测技术误报警的环境因素，例如不适合安装在通风好、空气流动大的位置。不过，因超声波不会穿过墙壁或门窗探测，所以对室外的物体不会造成误报警。这点优于前一种双技术探测器。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal"><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:宋体;mso-ansi-font-weight:bold;
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p>&nbsp;</o:p></span></b></p><p class="MsoNormal" style="margin-left:0.0000pt;text-indent:0.0000pt;mso-list:l6 level1 lfo1;"><!--[if !supportLists]--><span style="font-family:宋体;mso-ansi-font-weight:bold;font-size:12.0000pt;
            mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">19、<span>&nbsp;</span></span></span><!--[endif]--><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:宋体;mso-ansi-font-weight:bold;
            font-size:12.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">报警主机的功能</font></span></b><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:宋体;mso-ansi-font-weight:bold;
            font-size:12.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></b></p><p class="MsoNormal" style="margin-left:18.0000pt;"><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">报警主机置于用户端的值班中心，是报警系统的主控部分，它可向探测器提供电源，接收探测器送出的报警信号，并对信号进行处理。同时可以发出声音、光亮等报警信号，并指示出发生报警的部位。也可以向上一级接警中心或其他部门报告警情。可以连接一个或多个键盘，用户可以在键盘上完成编程和对报警系统的各种控制操作。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:-18.0000pt;mso-list:l0 level1 lfo12;"><!--[if !supportLists]--><span style="font-family:宋体;color:rgb(0,0,255);font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">①<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,255);
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">防区功能</font></span><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,255);
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"></span><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,255);
            font-size:10.0000pt;mso-font-kerning:1.0000pt;">&nbsp;<font face="宋体">—— </font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">一个报警主机通常可以连接多个防区，并设定每个防区的防区类型。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:-18.0000pt;mso-list:l0 level1 lfo12;"><!--[if !supportLists]--><span style="font-family:宋体;color:rgb(0,0,255);font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">②<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,255);
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">操作功能</font></span><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,255);
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"></span><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,255);
            font-size:10.0000pt;mso-font-kerning:1.0000pt;">&nbsp;<font face="宋体">—— </font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">基本操作有布防、撤防、旁路、测试等。一般主机操作都是通过键盘按密码来进行的。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:-18.0000pt;mso-list:l0 level1 lfo12;"><!--[if !supportLists]--><span style="font-family:宋体;color:rgb(0,0,255);font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">③<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,255);
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">输出功能</font></span><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,255);
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"></span><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,255);
            font-size:10.0000pt;mso-font-kerning:1.0000pt;">&nbsp;<font face="宋体">—— </font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">现场声光报警输出，电话联网报警。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:-18.0000pt;mso-list:l0 level1 lfo12;"><!--[if !supportLists]--><span style="font-family:宋体;color:rgb(0,0,255);font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">④<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,255);
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">可驱动外围设备</font> —— </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">如开启摄像机、录像机、照明设备、记录打印机等。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:-18.0000pt;mso-list:l0 level1 lfo12;"><!--[if !supportLists]--><span style="font-family:宋体;color:rgb(0,0,255);font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">⑤<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,255);
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">系统自检功能</font> —— </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">可实现对整个入侵探测报警系统的自检，检查系统各个部分的工作状态是否处于正常工作状态，否则发出故障报警信号。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:-18.0000pt;mso-list:l0 level1 lfo12;"><!--[if !supportLists]--><span style="font-family:宋体;color:rgb(0,0,255);font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">⑥<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,255);
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">故障报警功能</font> —— </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">是对系统中线路的短路、开路、设备外壳被非法打开等进行检测，一旦发生，发出故障报警信号。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:-18.0000pt;mso-list:l0 level1 lfo12;"><!--[if !supportLists]--><span style="font-family:宋体;color:rgb(0,0,255);font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">⑦<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,255);
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">对系统的编程功能</font> —— </span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">体现了报警主机的智能化水平。它可以很好地满足不同用户的防范需求。编程内容很多，如操作人员的密码、各防区的布防类型、报警的延时时间、响铃时间、事件报告、测试报告、是否自动拨号向上一级报告警情（通讯控制、通讯格式、报警中心接收机的电话号码）、遥控编程的电话号码等等。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal"><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:宋体;mso-ansi-font-weight:bold;
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p>&nbsp;</o:p></span></b></p><p class="MsoNormal" style="margin-left:0.0000pt;text-indent:0.0000pt;mso-list:l6 level1 lfo1;"><!--[if !supportLists]--><span style="font-family:宋体;mso-ansi-font-weight:bold;font-size:12.0000pt;
            mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">20、<span>&nbsp;</span></span></span><!--[endif]--><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:宋体;mso-ansi-font-weight:bold;
            font-size:12.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">报警主机的分类</font></span></b><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:宋体;mso-ansi-font-weight:bold;
            font-size:12.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></b></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:-18.0000pt;mso-list:l6 level5 lfo1;"><!--[if !supportLists]--><span style="font-family:Wingdings;mso-fareast-font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">l<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">根据使用要求和系统大小不同：单路报警主机（只控制一路探测器，只有一路声响报警）、小型报警主机、中型报警主机、大型报警主机。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:-18.0000pt;mso-list:l6 level5 lfo1;"><!--[if !supportLists]--><span style="font-family:Wingdings;mso-fareast-font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">l<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">就防范控制功能而言：单一安全防范功能（如防盗、防入侵、防火）的报警主机、具有多重安全防范功能（防盗、防入侵、防火、电视监控、监听等）为一体的综合型的多功能报警主机。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:-18.0000pt;mso-list:l6 level5 lfo1;"><!--[if !supportLists]--><span style="font-family:Wingdings;mso-fareast-font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">l<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">根据组成电路的器件不同：由晶体管或简单集成电路元器件组成的报警主机、利用单片机控制的报警主机、利用微机控制的报警主机</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:-18.0000pt;mso-list:l6 level5 lfo1;"><!--[if !supportLists]--><span style="font-family:Wingdings;mso-fareast-font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">l<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">按照信号的传输方式不同：有线接口的报警主机、无线接口的报警主机、有线接口和无线接口兼容的报警主机。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:-18.0000pt;mso-list:l6 level5 lfo1;"><!--[if !supportLists]--><span style="font-family:Wingdings;mso-fareast-font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">l<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">依据安装方式不同：台式报警主机、柜式报警主机、壁挂式报警主机。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal"><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p>&nbsp;</o:p></span></p><p class="MsoNormal" style="margin-left:0.0000pt;text-indent:0.0000pt;mso-list:l6 level1 lfo1;"><!--[if !supportLists]--><span style="font-family:宋体;mso-ansi-font-weight:bold;font-size:12.0000pt;
            mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">21、<span>&nbsp;</span></span></span><!--[endif]--><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:宋体;mso-ansi-font-weight:bold;
            font-size:12.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">报警主机对探测器和系统工作状态的控制方式</font></span></b><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:宋体;mso-ansi-font-weight:bold;
            font-size:12.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></b></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:-18.0000pt;mso-list:l6 level5 lfo1;"><!--[if !supportLists]--><span style="font-family:Wingdings;mso-fareast-font-family:宋体;color:rgb(0,0,255);
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">l<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,255);
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">布防状态：</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">是指操作人员执行了布防指令后，使该系统的探测器已开始工作（俗称</font>“开机”），并进入正常警戒状态，系统对探测器探测到的入侵行为作出报警。</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:-18.0000pt;mso-list:l6 level5 lfo1;"><!--[if !supportLists]--><span style="font-family:Wingdings;mso-fareast-font-family:宋体;color:rgb(0,0,255);
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">l<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,255);
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">撤防状态</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">：是指操作人员执行了撤防指令后，使该系统的探测器不能进入正常警戒工作状态，或从警戒状态下退出，使探测器无效（俗称</font>“关机”）。此时，系统对探测器探测到的动作不作反应（24小时类型防区除外）。</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:-18.0000pt;mso-list:l6 level5 lfo1;"><!--[if !supportLists]--><span style="font-family:Wingdings;mso-fareast-font-family:宋体;color:rgb(0,0,255);
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">l<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,255);
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">旁路状态</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">：是指操作人员执行了旁路指令后，指定防区的探测器就会从整个探测器的群体中被旁路掉（失效），而不能进入工作状态。在一个报警系统中，可以将其中一个探测器单独旁路，也可以将多个探测器同时旁路掉（又称集体旁路）。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:-18.0000pt;mso-list:l6 level5 lfo1;"><!--[if !supportLists]--><span style="font-family:Wingdings;mso-fareast-font-family:宋体;color:rgb(0,0,255);
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">l<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,255);
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">24小时监控状态</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">：是指某些防区的探测器处于常布防的全天时工作状态，一天</font>24小时始终担任着正常警戒（如用于火警、匪警、医务救护用的紧急按钮、烟感火灾探测器、感温探测器等）。它不会受到布防、撤防操作的影响。</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:-18.0000pt;mso-list:l6 level5 lfo1;"><!--[if !supportLists]--><span style="font-family:Wingdings;mso-fareast-font-family:宋体;color:rgb(0,0,255);
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">l<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,255);
            font-size:10.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">系统自检、测试状态</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">：是在系统撤防时操作人员对报警系统进行自检或测试的工作状态。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal"><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p>&nbsp;</o:p></span></p><p class="MsoNormal" style="margin-left:0.0000pt;text-indent:0.0000pt;mso-list:l6 level1 lfo1;"><!--[if !supportLists]--><span style="font-family:宋体;mso-ansi-font-weight:bold;font-size:12.0000pt;
            mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">22、<span>&nbsp;</span></span></span><!--[endif]--><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:宋体;mso-ansi-font-weight:bold;
            font-size:12.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">NX-8和NX-8E </font></span></b><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">这两款报警主机都具有</font>8个独立的子系统。</span><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:宋体;mso-ansi-font-weight:bold;
            font-size:12.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></b></p><p class="MsoNormal" style="margin-left:39.0000pt;text-indent:-18.0000pt;mso-list:l7 level1 lfo13;"><!--[if !supportLists]--><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">1、<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">所有防区可以独立分配到各个子系统中，也可以将一个防区分配到几个子系统中，建立复合子系统；</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:39.0000pt;text-indent:-18.0000pt;mso-list:l7 level1 lfo13;"><!--[if !supportLists]--><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">2、<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">每个子系统可以接</font>8个键盘（分子系统键盘），也可以在整体系统上接键盘（隶属所有8个子系统--整体键盘），整体系统最多可以接24个键盘，可以对每个子系统进行独立布撤防，也可以对整体系统进行布撤防；</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:39.0000pt;text-indent:-18.0000pt;mso-list:l7 level1 lfo13;"><!--[if !supportLists]--><span style="font-family:宋体;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">3、<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">每个子系统的报警信息可以在每个子系统自己的键盘上显示，也可以在整体系统的键盘上显示；</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:39.0000pt;text-indent:-18.0000pt;mso-list:l7 level1 lfo13;"><!--[if !supportLists]--><span style="font-family:宋体;mso-bidi-font-family:FZZhongDengXian-Z07S;color:rgb(0,0,0);
            font-size:10.0000pt;mso-font-kerning:0.0000pt;"><span style="mso-list:Ignore;">4、<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:FZZhongDengXian-Z07S;
            color:rgb(0,0,0);font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="宋体">每个子系统分别具有独立的用户码，可以将报警信号独立上报到报警中心；</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal"><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:宋体;mso-ansi-font-weight:bold;
            font-size:12.0000pt;mso-font-kerning:1.0000pt;"><o:p>&nbsp;</o:p></span></b></p><p class="MsoNormal" style="margin-left:0.0000pt;text-indent:0.0000pt;mso-list:l6 level1 lfo1;"><!--[if !supportLists]--><span style="font-family:宋体;mso-ansi-font-weight:bold;font-size:12.0000pt;
            mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">23、<span>&nbsp;</span></span></span><!--[endif]--><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:宋体;mso-ansi-font-weight:bold;
            font-size:12.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></b></p><p class="MsoNormal"><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p>&nbsp;</o:p></span></p><p class="MsoNormal"><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p>&nbsp;</o:p></span></p><p class="MsoNormal"><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            color:rgb(255,0,0);mso-ansi-font-weight:bold;font-size:12.0000pt;
            mso-font-kerning:1.0000pt;">C&amp;K<font face="宋体">常用探测器调试中应注意的几个问题</font></span></b><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            color:rgb(255,0,0);mso-ansi-font-weight:bold;font-size:12.0000pt;
            mso-font-kerning:1.0000pt;"><br></span></b><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            color:rgb(33,77,115);mso-ansi-font-weight:bold;font-size:9.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">一</font></span></b><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:ˎ̥;
            mso-hansi-font-family:ˎ̥;color:rgb(33,77,115);mso-ansi-font-weight:bold;
            font-size:9.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">、</font></span></b><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            color:rgb(33,77,115);mso-ansi-font-weight:bold;font-size:9.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">认识</font><font face="ˎ̥">LED</font><font face="宋体">灯况</font></span></b><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            color:rgb(33,77,115);mso-ansi-font-weight:bold;font-size:9.0000pt;
            mso-font-kerning:1.0000pt;"><br></span></b><span style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:ˎ̥;
            mso-hansi-font-family:ˎ̥;color:rgb(33,77,115);font-size:9.0000pt;
            mso-font-kerning:1.0000pt;"><font face="ˎ̥">1</font><font face="宋体">、</font></span><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            color:rgb(33,77,115);font-size:9.0000pt;mso-font-kerning:1.0000pt;">LED<font face="宋体">指示灯：</font></span><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            color:rgb(33,77,115);font-size:9.0000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            color:rgb(33,77,115);font-size:9.0000pt;mso-font-kerning:1.0000pt;">&nbsp; &nbsp; </span><span style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:ˎ̥;
            mso-hansi-font-family:ˎ̥;color:rgb(33,77,115);font-size:9.0000pt;
            mso-font-kerning:1.0000pt;"><font face="ˎ̥">A</font><font face="宋体">、</font></span><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            color:rgb(33,77,115);font-size:9.0000pt;mso-font-kerning:1.0000pt;">&nbsp;<font face="宋体">装有红、黄、绿三只</font><font face="ˎ̥">LED</font><font face="宋体">的产品：</font><font face="ˎ̥">DT-900/DT906</font><font face="宋体">、</font><font face="ˎ̥">DT-6360STC</font><font face="宋体">等</font></span><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            color:rgb(33,77,115);font-size:9.0000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:ˎ̥;
            mso-hansi-font-family:ˎ̥;color:rgb(33,77,115);font-size:9.0000pt;
            mso-font-kerning:1.0000pt;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            color:rgb(33,77,115);font-size:9.0000pt;mso-font-kerning:1.0000pt;">&nbsp;&nbsp;<font face="宋体">红灯为报警指示灯，黄灯（微波）和绿灯（红外）为步测指示灯。</font></span><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            color:rgb(33,77,115);font-size:9.0000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            color:rgb(33,77,115);font-size:9.0000pt;mso-font-kerning:1.0000pt;">&nbsp; &nbsp; </span><span style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:ˎ̥;
            mso-hansi-font-family:ˎ̥;color:rgb(33,77,115);font-size:9.0000pt;
            mso-font-kerning:1.0000pt;"><font face="ˎ̥">B</font><font face="宋体">、</font></span><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            color:rgb(33,77,115);font-size:9.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">仅有一只红色</font><font face="ˎ̥">LED</font><font face="宋体">的产品：</font><font face="ˎ̥">DT-500</font><font face="宋体">、</font><font face="ˎ̥">DT-700/DT-706</font><font face="宋体">等</font></span><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            color:rgb(33,77,115);font-size:9.0000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:ˎ̥;
            mso-hansi-font-family:ˎ̥;color:rgb(33,77,115);font-size:9.0000pt;
            mso-font-kerning:1.0000pt;"><font face="ˎ̥">2</font><font face="宋体">、</font></span><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            color:rgb(33,77,115);font-size:9.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">一般规律：</font></span><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            color:rgb(33,77,115);font-size:9.0000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            color:rgb(33,77,115);font-size:9.0000pt;mso-font-kerning:1.0000pt;">&nbsp;&nbsp;&nbsp; &nbsp;<font face="宋体">探测器成功地探测到目标，红色</font><font face="ˎ̥">LED</font><font face="宋体">应点亮。</font></span><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            color:rgb(33,77,115);font-size:9.0000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            color:rgb(33,77,115);font-size:9.0000pt;mso-font-kerning:1.0000pt;">&nbsp; &nbsp; <font face="宋体">测器视区内无目标活动的情况下，所有</font><font face="ˎ̥">LED</font><font face="宋体">均应熄灭。</font></span><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            color:rgb(33,77,115);font-size:9.0000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:ˎ̥;
            mso-hansi-font-family:ˎ̥;color:rgb(33,77,115);font-size:9.0000pt;
            mso-font-kerning:1.0000pt;"><font face="ˎ̥">3</font><font face="宋体">、</font></span><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            color:rgb(33,77,115);font-size:9.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">快闪：</font></span><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            color:rgb(33,77,115);font-size:9.0000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            color:rgb(33,77,115);font-size:9.0000pt;mso-font-kerning:1.0000pt;">&nbsp; &nbsp; <font face="宋体">红色</font><font face="ˎ̥">LED</font><font face="宋体">快闪（对仅有一只</font><font face="ˎ̥">LED</font><font face="宋体">的产品）或者红、黄、绿三只</font><font face="ˎ̥">LED</font><font face="宋体">一齐快闪（对装有三只</font><font face="ˎ̥">LED</font><font face="宋体">的产品），表示探测器正在上电自检</font><font face="ˎ̥">——“</font><font face="宋体">预热</font><font face="ˎ̥">”</font><font face="宋体">，也有可能是探测器出现故障。</font></span><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            color:rgb(33,77,115);font-size:9.0000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            color:rgb(33,77,115);font-size:9.0000pt;mso-font-kerning:1.0000pt;">&nbsp; &nbsp; <font face="宋体">对</font><font face="ˎ̥">DT-900/DT-906</font><font face="宋体">，红色和绿色两只</font><font face="ˎ̥">LED</font><font face="宋体">一齐快闪，代表探测器被遮挡。</font></span><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            color:rgb(33,77,115);font-size:9.0000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:ˎ̥;
            mso-hansi-font-family:ˎ̥;color:rgb(33,77,115);font-size:9.0000pt;
            mso-font-kerning:1.0000pt;"><font face="ˎ̥">4</font><font face="宋体">、</font></span><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            color:rgb(33,77,115);font-size:9.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">慢闪：</font></span><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            color:rgb(33,77,115);font-size:9.0000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            color:rgb(33,77,115);font-size:9.0000pt;mso-font-kerning:1.0000pt;">&nbsp; &nbsp; <font face="宋体">探测器上的两只</font><font face="ˎ̥">LED</font><font face="宋体">（红和绿或者红和黄）一齐慢闪，代表</font><font face="ˎ̥">“</font><font face="宋体">比例监控电路</font><font face="ˎ̥">”</font><font face="宋体">发现问题。问题既有可能出在环境方面，也有可能是探测器本身出了故障。具体可参照说明书上给出的慢闪灯况处理表进行判断。</font></span><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            color:rgb(33,77,115);font-size:9.0000pt;mso-font-kerning:1.0000pt;"><br></span><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            color:rgb(33,77,115);mso-ansi-font-weight:bold;font-size:9.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">二</font></span></b><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:ˎ̥;
            mso-hansi-font-family:ˎ̥;color:rgb(33,77,115);mso-ansi-font-weight:bold;
            font-size:9.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">、</font></span></b><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            color:rgb(33,77,115);mso-ansi-font-weight:bold;font-size:9.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">上电</font><font face="ˎ̥">“</font><font face="宋体">预热</font><font face="ˎ̥">”</font></span></b><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            color:rgb(33,77,115);mso-ansi-font-weight:bold;font-size:9.0000pt;
            mso-font-kerning:1.0000pt;"><br></span></b><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            color:rgb(33,77,115);font-size:9.0000pt;mso-font-kerning:1.0000pt;">&nbsp; &nbsp; <font face="宋体">需要引起注意的是，每只探测器上电后，都必须经历一个</font><font face="ˎ̥">“</font><font face="宋体">预热</font><font face="ˎ̥">”</font><font face="宋体">过程（一般需时</font><font face="ˎ̥">3</font><font face="宋体">分钟左右），</font><font face="ˎ̥">“</font><font face="宋体">预热</font><font face="ˎ̥">”</font><font face="宋体">过程结束之后探测器才能达到最佳探测性能，因此步测必须在</font><font face="ˎ̥">“</font><font face="宋体">预热</font><font face="ˎ̥">”</font><font face="宋体">之后方可进行。</font></span><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            color:rgb(33,77,115);font-size:9.0000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            color:rgb(33,77,115);font-size:9.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">正常情况下</font><font face="ˎ̥">“</font><font face="宋体">预热</font><font face="ˎ̥">”</font><font face="宋体">结束后，如果视区内无目标活动，则探测器上的指示灯应全部熄灭。</font></span><span style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:ˎ̥;
            mso-hansi-font-family:ˎ̥;color:rgb(33,77,115);font-size:9.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal"><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            color:rgb(33,77,115);mso-ansi-font-weight:bold;font-size:9.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">三</font></span></b><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:ˎ̥;
            mso-hansi-font-family:ˎ̥;color:rgb(33,77,115);mso-ansi-font-weight:bold;
            font-size:9.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">、</font></span></b><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            color:rgb(33,77,115);mso-ansi-font-weight:bold;font-size:9.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">步测的基本方法</font></span></b><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            color:rgb(33,77,115);mso-ansi-font-weight:bold;font-size:9.0000pt;
            mso-font-kerning:1.0000pt;"><br></span></b><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            color:rgb(33,77,115);font-size:9.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">步测的基本方法是：防区内人员退出</font><font face="ˎ̥">5</font><font face="宋体">分钟后，在探测视区内，直立的人体目标（</font><font face="ˎ̥">40KG – 80KG</font><font face="宋体">）沿着一定的方向以正常步速（</font><font face="ˎ̥">0.75</font><font face="宋体">米</font><font face="ˎ̥">/</font><font face="宋体">秒）连续行走</font><font face="ˎ̥">2</font><font face="宋体">～</font><font face="ˎ̥">4</font><font face="宋体">步，应能点亮红灯（对仅有一只</font><font face="ˎ̥">LED</font><font face="宋体">的产品）或者点亮黄灯和绿灯并最终点亮红灯（对装有三只</font><font face="ˎ̥">LED</font><font face="宋体">的产品），触发一次报警（以点亮红色</font><font face="ˎ̥">LED</font><font face="宋体">为标志）。</font></span><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            color:rgb(33,77,115);font-size:9.0000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            color:rgb(33,77,115);font-size:9.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">注意：</font></span><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            color:rgb(33,77,115);font-size:9.0000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            color:rgb(33,77,115);font-size:9.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">每个安装人员都应熟知探测器的特性（脾气）。</font></span><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            color:rgb(33,77,115);font-size:9.0000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            color:rgb(33,77,115);font-size:9.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">对挂壁式探测器，步测时的行走方向为侧向行走，即切割红外视区的方向，而不是朝着或背对探测器的方向。对吸顶式探测器，步测时的行走方向为以探测器为圆心的圆周，而不是朝着或背对探测器的方向。</font></span><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            color:rgb(33,77,115);font-size:9.0000pt;mso-font-kerning:1.0000pt;"><br></span><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            color:rgb(33,77,115);mso-ansi-font-weight:bold;font-size:9.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">四</font></span></b><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:ˎ̥;
            mso-hansi-font-family:ˎ̥;color:rgb(33,77,115);mso-ansi-font-weight:bold;
            font-size:9.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">、</font></span></b><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            color:rgb(33,77,115);mso-ansi-font-weight:bold;font-size:9.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">灵敏度调整</font></span></b><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            color:rgb(33,77,115);mso-ansi-font-weight:bold;font-size:9.0000pt;
            mso-font-kerning:1.0000pt;"><br></span></b><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            color:rgb(33,77,115);font-size:9.0000pt;mso-font-kerning:1.0000pt;">&nbsp; &nbsp;<font face="宋体">灵敏度的调整要视周围的环境而定，不应一味追求高灵敏度而放弃了对误报的警惕。一般情况下，建议使用出厂设置</font><font face="ˎ̥">——“</font><font face="宋体">中挡</font><font face="ˎ̥">”</font><font face="宋体">。如环境恶劣，可考虑采用</font><font face="ˎ̥">“</font><font face="宋体">低挡</font><font face="ˎ̥">”</font><font face="宋体">设置，以减少误报。只有环境比较好的场所才考虑使用</font><font face="ˎ̥">“</font><font face="宋体">高挡</font><font face="ˎ̥">”</font><font face="宋体">设置。</font></span><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            color:rgb(33,77,115);font-size:9.0000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            color:rgb(33,77,115);font-size:9.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">灵敏度的调整一般通过</font><font face="ˎ̥">PCB</font><font face="宋体">板上的跳线或开关来实现。具体应参照说明书进行操作。</font></span><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            color:rgb(33,77,115);font-size:9.0000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            color:rgb(33,77,115);font-size:9.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">注：</font><font face="ˎ̥">DT-500</font><font face="宋体">；</font><font face="ˎ̥">DT-700/DT-706</font><font face="宋体">没有灵敏度调节机构。</font></span><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            color:rgb(33,77,115);font-size:9.0000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            color:rgb(33,77,115);font-size:9.0000pt;mso-font-kerning:1.0000pt;">&nbsp; &nbsp; DT-6360STC<font face="宋体">调整</font><font face="ˎ̥">W1</font><font face="宋体">；</font><font face="ˎ̥">DT-900/DT-906</font><font face="宋体">调整</font><font face="ˎ̥">S3</font><font face="宋体">。</font></span><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            color:rgb(33,77,115);font-size:9.0000pt;mso-font-kerning:1.0000pt;"><br></span><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            color:rgb(33,77,115);mso-ansi-font-weight:bold;font-size:9.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">五</font></span></b><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:ˎ̥;
            mso-hansi-font-family:ˎ̥;color:rgb(33,77,115);mso-ansi-font-weight:bold;
            font-size:9.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">、</font></span></b><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            color:rgb(33,77,115);mso-ansi-font-weight:bold;font-size:9.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">微波探测范围调整</font></span></b><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            color:rgb(33,77,115);mso-ansi-font-weight:bold;font-size:9.0000pt;
            mso-font-kerning:1.0000pt;"><br></span></b><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            color:rgb(33,77,115);font-size:9.0000pt;mso-font-kerning:1.0000pt;">&nbsp; &nbsp;<font face="宋体">请注意：双鉴器出厂时其微波探测范围一般都开在最大位置！！！</font></span><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            color:rgb(33,77,115);font-size:9.0000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            color:rgb(33,77,115);font-size:9.0000pt;mso-font-kerning:1.0000pt;">&nbsp; &nbsp; <font face="宋体">因为微波具有穿透能力，如果不调整很容易通过门窗跑到室外，造成误报。因此除了不设调整机构的</font><font face="ˎ̥">DT-500</font><font face="宋体">外，其他探测器安装完毕后都必须进行此项调整。</font></span><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            color:rgb(33,77,115);font-size:9.0000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            color:rgb(33,77,115);font-size:9.0000pt;mso-font-kerning:1.0000pt;">C&amp;K<font face="宋体">双鉴器的微波调节机构一般为一个电位器小拨轮。步测时应先将其调至最小位置，然后根据需要逐渐加大直至探测距离满足要求为止。</font></span><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            color:rgb(33,77,115);font-size:9.0000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            color:rgb(33,77,115);font-size:9.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">注意：</font></span><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            color:rgb(33,77,115);font-size:9.0000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            color:rgb(33,77,115);font-size:9.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">微波调试时一个常见的误区是：不论房间大小和周围环境如何，微波均开最大位置（出厂设置），导致微波穿透到室外或误报因素进入微波视区留下误报隐患。</font></span><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            color:rgb(33,77,115);font-size:9.0000pt;mso-font-kerning:1.0000pt;"><br></span><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            color:rgb(33,77,115);mso-ansi-font-weight:bold;font-size:9.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">六</font></span></b><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:ˎ̥;
            mso-hansi-font-family:ˎ̥;color:rgb(33,77,115);mso-ansi-font-weight:bold;
            font-size:9.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">、</font></span></b><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            color:rgb(33,77,115);mso-ansi-font-weight:bold;font-size:9.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">红外探测范围调整</font></span></b><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            color:rgb(33,77,115);mso-ansi-font-weight:bold;font-size:9.0000pt;
            mso-font-kerning:1.0000pt;"><br></span></b><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            color:rgb(33,77,115);font-size:9.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">大多数</font><font face="ˎ̥">C&amp;K</font><font face="宋体">双鉴器在</font><font face="ˎ̥">PCB</font><font face="宋体">板上不设专门的红外调整机构，一般可以使用如下方法进行调整：</font></span><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            color:rgb(33,77,115);font-size:9.0000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            color:rgb(33,77,115);font-size:9.0000pt;mso-font-kerning:1.0000pt;">&nbsp; &nbsp; <font face="宋体">有些情况下，为了避开某些误报因素，安装人员可以通过改变探测器的安装高度、朝向和俯仰角度（只对挂壁式）或者遮蔽一部分红外视区（用专用贴纸）等方法进行调整，以获取合适的探测范围。</font></span><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            color:rgb(33,77,115);font-size:9.0000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            color:rgb(33,77,115);font-size:9.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">注意：</font></span><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            color:rgb(33,77,115);font-size:9.0000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            color:rgb(33,77,115);font-size:9.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">红外调试时一个常见的误区是：不论房间大小和周围环境如何，探测器均平贴墙面安装，既不调整俯仰角也不调整朝向，导致红外视区跑到室外或误报因素进入红外视区造成误报。</font></span><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            color:rgb(33,77,115);font-size:9.0000pt;mso-font-kerning:1.0000pt;"><br></span><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            color:rgb(33,77,115);mso-ansi-font-weight:bold;font-size:9.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">七</font></span></b><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:ˎ̥;
            mso-hansi-font-family:ˎ̥;color:rgb(33,77,115);mso-ansi-font-weight:bold;
            font-size:9.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">、</font></span></b><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            color:rgb(33,77,115);mso-ansi-font-weight:bold;font-size:9.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">比例监控</font></span></b><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            color:rgb(33,77,115);mso-ansi-font-weight:bold;font-size:9.0000pt;
            mso-font-kerning:1.0000pt;"><br></span></b><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            color:rgb(33,77,115);font-size:9.0000pt;mso-font-kerning:1.0000pt;">&nbsp; &nbsp;“<font face="宋体">比例监控电路</font><font face="ˎ̥">”</font><font face="宋体">是</font><font face="ˎ̥">C&amp;K</font><font face="宋体">的专利技术，其基本原理是对微波和红外这两种技术所探测到的事件分别予以计数，然后用两者的比例数</font><font face="ˎ̥">——</font><font face="宋体">探测比例来判定探测器的工作状态（自身及周围环境）是否正常。</font></span><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            color:rgb(33,77,115);font-size:9.0000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            color:rgb(33,77,115);font-size:9.0000pt;mso-font-kerning:1.0000pt;">&nbsp; &nbsp;“<font face="宋体">比例监控电路</font><font face="ˎ̥">”</font><font face="宋体">发现问题时，一方面会启动慢闪灯况；另一方面会开启故障输出端子</font><font face="ˎ̥">——“TRBL”</font><font face="宋体">，向外界通报。具体如何动作由设置决定。</font></span><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            color:rgb(33,77,115);font-size:9.0000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            color:rgb(33,77,115);font-size:9.0000pt;mso-font-kerning:1.0000pt;">&nbsp; &nbsp; <font face="宋体">常用的比例有：</font><font face="ˎ̥">128</font><font face="宋体">：</font><font face="ˎ̥">1</font><font face="宋体">； </font><font face="ˎ̥">&nbsp; 32</font><font face="宋体">：</font><font face="ˎ̥">1</font><font face="宋体">； </font><font face="ˎ̥">&nbsp; 16</font><font face="宋体">：</font><font face="ˎ̥">1</font><font face="宋体">。</font></span><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            color:rgb(33,77,115);font-size:9.0000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            color:rgb(33,77,115);font-size:9.0000pt;mso-font-kerning:1.0000pt;">&nbsp; &nbsp; <font face="宋体">出厂时</font><font face="ˎ̥">“</font><font face="宋体">比例监控</font><font face="ˎ̥">”</font><font face="宋体">一般处于关闭状态。有经验的用户可根据需要决定起用与否。</font></span><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            color:rgb(33,77,115);font-size:9.0000pt;mso-font-kerning:1.0000pt;"><br></span><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            color:rgb(33,77,115);mso-ansi-font-weight:bold;font-size:9.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">八</font></span></b><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:ˎ̥;
            mso-hansi-font-family:ˎ̥;color:rgb(33,77,115);mso-ansi-font-weight:bold;
            font-size:9.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">、</font></span></b><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            color:rgb(33,77,115);mso-ansi-font-weight:bold;font-size:9.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">更换透镜</font></span></b><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            color:rgb(33,77,115);mso-ansi-font-weight:bold;font-size:9.0000pt;
            mso-font-kerning:1.0000pt;"><br></span></b><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            color:rgb(33,77,115);font-size:9.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">建议：</font></span><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            color:rgb(33,77,115);font-size:9.0000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            color:rgb(33,77,115);font-size:9.0000pt;mso-font-kerning:1.0000pt;">&nbsp; &nbsp; <font face="宋体">安装调试人员应仔细阅读产品说明书，熟悉各种透镜的视区分布图。</font></span><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            color:rgb(33,77,115);font-size:9.0000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            color:rgb(33,77,115);font-size:9.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">挂壁式：</font></span><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            color:rgb(33,77,115);font-size:9.0000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            color:rgb(33,77,115);font-size:9.0000pt;mso-font-kerning:1.0000pt;">&nbsp; &nbsp; <font face="宋体">挂壁式探测器出厂时，一般配备广角透镜（</font><font face="ˎ̥">DT-906</font><font face="宋体">除外）。广角透镜适合探测宽广的范围，是最常用的一种透镜，一般情况下不必更换。</font></span><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            color:rgb(33,77,115);font-size:9.0000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            color:rgb(33,77,115);font-size:9.0000pt;mso-font-kerning:1.0000pt;">&nbsp; &nbsp; <font face="宋体">除广角透镜外，</font><font face="ˎ̥">DT-700/DT-706</font><font face="宋体">还备有帘幕式透镜以及宠物透镜供选择。帘幕式透镜用于控制狭长的区域；宠物透镜用于养有宠物的场合。</font></span><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            color:rgb(33,77,115);font-size:9.0000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            color:rgb(33,77,115);font-size:9.0000pt;mso-font-kerning:1.0000pt;">&nbsp; &nbsp; &nbsp;<font face="宋体">请注意：</font><font face="ˎ̥">1. </font><font face="宋体">对狭长的区域（两侧均有墙体或物体），应换用帘幕透镜。如用广角透镜将会影响探测性能（距离变近）。反之，如果错误选用帘幕透镜控制宽广的区域，也将达不到预期效果（会使视区变得很窄）。</font></span><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            color:rgb(33,77,115);font-size:9.0000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            color:rgb(33,77,115);font-size:9.0000pt;mso-font-kerning:1.0000pt;">&nbsp; &nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp; 2. <font face="宋体">如换装宠物透镜，安装高度应改为</font><font face="ˎ̥">1.2</font><font face="宋体">米。</font></span><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            color:rgb(33,77,115);font-size:9.0000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            color:rgb(33,77,115);font-size:9.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">吸顶式：</font></span><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            color:rgb(33,77,115);font-size:9.0000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            color:rgb(33,77,115);font-size:9.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">吸顶式探测器</font><font face="ˎ̥">DT-6360STC</font><font face="宋体">的安装高度为</font><font face="ˎ̥">2.4</font><font face="宋体">米～</font><font face="ˎ̥">4.8</font><font face="宋体">米。当安装高度超过</font><font face="ˎ̥">3.3</font><font face="宋体">米，需要更换另一片环形透镜（随机提供）。</font></span><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            color:rgb(33,77,115);font-size:9.0000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            color:rgb(33,77,115);font-size:9.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">注意事项：</font></span><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            color:rgb(33,77,115);font-size:9.0000pt;mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            color:rgb(33,77,115);font-size:9.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">除非确有必要，一般不要随意更换透镜。任何对透镜的不当选择都可能对探测器性能产生影响。</font></span><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            color:rgb(33,77,115);font-size:9.0000pt;mso-font-kerning:1.0000pt;"><br></span><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            color:rgb(33,77,115);mso-ansi-font-weight:bold;font-size:9.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">九</font></span></b><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:ˎ̥;
            mso-hansi-font-family:ˎ̥;color:rgb(33,77,115);mso-ansi-font-weight:bold;
            font-size:9.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">、</font></span></b><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            color:rgb(33,77,115);mso-ansi-font-weight:bold;font-size:9.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">定期检查</font></span></b><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            color:rgb(33,77,115);mso-ansi-font-weight:bold;font-size:9.0000pt;
            mso-font-kerning:1.0000pt;"><br></span></b><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            color:rgb(33,77,115);font-size:9.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">应建立制度，定期对探测器进行步行测试。至少每半年要作一次检查。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></div>`
        }, 
        {
            id:5,
            title:'楼宇对讲基础知识培训',
            html:`<div class="Section0" style="layout-grid:15.6000pt;"><p class="p" style="margin-top:0.0000pt;margin-bottom:0.0000pt;line-height:13.5000pt;"><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:Tahoma;
            mso-ansi-font-weight:bold;font-size:42.0000pt;mso-font-kerning:0.0000pt;">&nbsp;&nbsp;&nbsp;&nbsp;</span></b><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:Tahoma;
            mso-ansi-font-weight:bold;font-size:12.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></b></p><p class="p" style="margin-top:0.0000pt;margin-bottom:0.0000pt;line-height:13.5000pt;"><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:Tahoma;
            mso-ansi-font-weight:bold;font-size:12.0000pt;mso-font-kerning:0.0000pt;"><font face="宋体">一、发展历程：</font></span></b><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:Tahoma;
            mso-ansi-font-weight:bold;font-size:12.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></b></p><p class="p" style="margin-top:0.0000pt;margin-bottom:0.0000pt;text-indent:24.0000pt;
            mso-char-indent-count:2.0000;line-height:13.5000pt;"><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:Tahoma;
            font-size:12.0000pt;mso-font-kerning:0.0000pt;"><font face="宋体">小区智能化建设在我国虽然起步较晚，但发展日新月异。随着</font>Internet互联网的普及，很多小区都已实现了宽带接入，信息高速公路已铺设到小区并进入家庭。智能小区系统采用TCP/IP技术的条件已经具备。智能小区系统的运行基础正由小区现场总线向Internet转变，由分散式管理，到集中管理转变。从以下楼宇对讲系统发展阶段阐述可以看出：数字对讲是楼宇对讲的必然趋势！ </span><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:Tahoma;
            font-size:12.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-bottom:0.0000pt;text-indent:24.0000pt;
            mso-char-indent-count:2.0000;line-height:13.5000pt;"><span style="mso-spacerun:'yes';font-family:Georgia;mso-bidi-font-family:宋体;
            font-size:12.0000pt;mso-font-kerning:0.0000pt;"><font face="宋体">二十世纪八十年代末到二十世纪九十年代初，楼宇对讲系统做为舶来品进入中国大陆。最先接受楼宇对讲系统理念的是深圳、广州等沿海开放城市。进入的产品包括日本的</font><font face="Georgia">‘</font><font face="宋体">爱峰</font><font face="Georgia">’</font><font face="宋体">、台湾的</font><font face="Georgia">&nbsp;</font><font face="宋体">等少数几家产品。</font></span><span style="mso-spacerun:'yes';font-family:Georgia;mso-bidi-font-family:宋体;
            font-size:12.0000pt;mso-font-kerning:0.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:Georgia;mso-bidi-font-family:宋体;
            font-size:12.0000pt;mso-font-kerning:0.0000pt;"><font face="宋体">随着安全技术防范概念不断深入人心，楼宇对讲系统在二十世纪九十年代初期开始在国内出现专门的生产单位。早期的产品一般以仿制为主（主要是功能、性能等方面的仿制）。最早的产品如深圳的宝石（</font><font face="Georgia">91</font><font face="宋体">年）等。</font><font face="Georgia">90-95</font><font face="宋体">年期间，国内楼宇对讲系统基本全部为对讲系统，只有到</font><font face="Georgia">95</font><font face="宋体">年左右，国内才开始出现使用可视对讲系统安装的住宅小区，但是数量极少，使用产品也均为进口产品，而且产品在功能上以最简单的直按式系统为主，辅助的出现极少的数字式系统。</font></span><span style="mso-spacerun:'yes';font-family:Georgia;mso-bidi-font-family:宋体;
            font-size:12.0000pt;mso-font-kerning:0.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:Georgia;mso-bidi-font-family:宋体;
            font-size:12.0000pt;mso-font-kerning:0.0000pt;"><font face="宋体">在这期间，又有很多国际性产品进入国内，比较典型的为西班牙</font><font face="Georgia">FERMAX</font><font face="宋体">（弗曼科斯）、意大利欧明、韩国的</font><font face="Georgia">KOCOM</font><font face="宋体">、韩国的</font><font face="Georgia">COMMAX</font><font face="宋体">，这些国际性的品牌带入了新的楼宇对讲概念：可视功能、联网功能、小区管理功能。在这些品牌的大力带动下，国内的楼宇对讲市场不断的扩大，而国产产品也出现了新的动向。</font><font face="Georgia">1996</font><font face="宋体">年左右，深圳市视得安科技公司开发出了国内第一台可以实用的可视对讲分机。随之而来的是更多的国内公司投入到楼宇对讲行业中，通过消化吸收国外知名品牌的精髓，并以国内实际的情况（如国内小区密度更大，小区容量更大），进行楼宇对讲产品的本土化开发。</font></span><span style="mso-spacerun:'yes';font-family:Georgia;mso-bidi-font-family:宋体;
            font-size:12.0000pt;mso-font-kerning:0.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:Georgia;mso-bidi-font-family:宋体;
            font-size:12.0000pt;mso-font-kerning:0.0000pt;">1996-2000<font face="宋体">年之间是国内楼宇对讲行业发展的第一次高潮，主要体现在楼宇对讲系统本身的功能开发方面：更强的联网能力、更好的产品稳定性。国内比较老牌的楼宇对讲企业迅速的崛起（如深圳的视得安、慧锐通，等），并随着一批新兴的楼宇对讲企业也进入到产品大量销售阶段（如广州的安居宝、中山奥敏、深圳克耐克等），整体的楼宇对讲市场进入到激烈的竞争阶段。此段期间全国各地楼宇对讲厂商达到数百家。</font></span><span style="mso-spacerun:'yes';font-family:Georgia;mso-bidi-font-family:宋体;
            font-size:12.0000pt;mso-font-kerning:0.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:Georgia;mso-bidi-font-family:宋体;
            font-size:12.0000pt;mso-font-kerning:0.0000pt;"><font face="宋体">进入到二十一世纪，由于竞争的激烈，部分进口产品开始在国内设立自己的加工中心以降低成本，成立了很多独资或合资的楼宇对讲企业。这一阶段，新技术在楼宇对讲产品中得到了大量的应用，几乎每年均有新技术在楼宇对讲产品中进行运用。市场的竞争趋于残酷，一些厂家在激烈的竞争中退出楼宇对讲市场，只有产品质量稳定、产品技术全面、价格更加有竞争力、服务更加完善的厂家才能够在激烈的竞争中立于不败之地。</font></span><span style="mso-spacerun:'yes';font-family:Georgia;mso-bidi-font-family:宋体;
            font-size:12.0000pt;mso-font-kerning:0.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:Georgia;mso-bidi-font-family:宋体;
            font-size:12.0000pt;mso-font-kerning:0.0000pt;"><font face="宋体">随着楼宇对讲市场的不断扩张，国家及各地也相应的出台了部分政策以规范楼宇对讲市场，在各地纷纷有一些强制性要求安装楼宇对讲系统的政策出台，同时也带动了楼宇对讲产业的蓬勃发展。</font></span><span style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Georgia;
            mso-hansi-font-family:Georgia;font-size:12.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-bottom:0.0000pt;text-indent:24.0000pt;
            mso-char-indent-count:2.0000;line-height:13.5000pt;"><span style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Georgia;
            mso-hansi-font-family:Georgia;font-size:12.0000pt;mso-font-kerning:0.0000pt;"><o:p>&nbsp;</o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-bottom:0.0000pt;text-indent:24.0000pt;
            mso-char-indent-count:2.0000;line-height:13.5000pt;"><span style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Georgia;
            mso-hansi-font-family:Georgia;font-size:12.0000pt;mso-font-kerning:0.0000pt;"><o:p>&nbsp;</o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-bottom:0.0000pt;text-indent:24.0000pt;
            mso-char-indent-count:2.0000;line-height:13.5000pt;"><span style="mso-spacerun:'yes';font-family:Georgia;mso-bidi-font-family:宋体;
            font-size:12.0000pt;mso-font-kerning:0.0000pt;"><br></span><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:宋体;mso-bidi-font-family:Tahoma;
            mso-ansi-font-weight:bold;font-size:12.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></b></p><p class="MsoNormal" style="mso-pagination:widow-orphan;"><span style="mso-spacerun:'yes';font-family:宋体;font-size:10.5000pt;
            mso-font-kerning:0.0000pt;"><o:p>&nbsp;</o:p></span></p></div>`
        }, 
        {
            id:6,
            title:'楼宇对讲知识',
            html:`<div class="Section0" style="layout-grid:15.6000pt;"><p class="MsoNormal" style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
            mso-margin-bottom-alt:auto;mso-pagination:widow-orphan;text-align:left;"><b><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,0);
            font-weight:bold;font-size:9.0000pt;mso-font-kerning:0.0000pt;">1．什么是楼宇对讲系统 </span></b><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,0);
            font-size:9.0000pt;mso-font-kerning:0.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,0);
            font-size:9.0000pt;mso-font-kerning:0.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,0);
            font-size:9.0000pt;mso-font-kerning:0.0000pt;">&nbsp;&nbsp;&nbsp; 楼宇对讲系统是采用单片机技术、双工对讲技术、CCD摄像及视频显像技术而设计的一种访客识别电控信息管理的智能系统。楼门平时总处于闭锁状态，避免非本楼人员未经允许进入楼内。本楼内的住户可以用钥匙或密码开门自由出入。当有客人来访时，需在楼门外的对讲主机键盘上按出被访住户的房间号，呼叫被访住户的对讲分机，接通后与被访住户的主人进行双向通话或可视通话。通过对话或图像确认来访者的身份后，住户主人允许来访者进入，就用对讲分机上的开锁键打开大楼门口上的电控锁，来访客人便可以进入楼内。来访客人进入后，楼门自动闭锁。</span><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,0);
            font-size:9.0000pt;mso-font-kerning:0.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,0);
            font-size:9.0000pt;mso-font-kerning:0.0000pt;"><font face="宋体">住宅小区的物业管理部门通过小区对讲管理主机，可以对小区内各住宅楼宇对讲系统的工作情况进行监视。如有住宅楼入口门被非法打开或对讲系统出现故障，小区对讲管理主机会发出报警信号和显示出报警的内容及地点。</font> </span><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,0);
            font-size:9.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
            mso-margin-bottom-alt:auto;mso-pagination:widow-orphan;text-align:left;"><b><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,0);
            font-weight:bold;font-size:9.0000pt;mso-font-kerning:0.0000pt;">2．楼宇对讲的类型 </span></b><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,0);
            font-size:9.0000pt;mso-font-kerning:0.0000pt;"><br></span><b><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,0);
            font-weight:bold;font-size:9.0000pt;mso-font-kerning:0.0000pt;"><br></span></b><b><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,0);
            font-weight:bold;font-size:9.0000pt;mso-font-kerning:0.0000pt;">a) 单户型</span></b><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,0);
            font-size:9.0000pt;mso-font-kerning:0.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,0);
            font-size:9.0000pt;mso-font-kerning:0.0000pt;">&nbsp;&nbsp;&nbsp; 具备可视对讲或非可视对讲、遥控开锁、主动监控，使家中的电话（与市话连接）、电视可与单元型可视对讲主机组成单元系统等功能，室内机分台式和扁平挂壁式两种。 </span><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,0);
            font-size:9.0000pt;mso-font-kerning:0.0000pt;"><br></span><b><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,0);
            font-weight:bold;font-size:9.0000pt;mso-font-kerning:0.0000pt;">b) 单元型</span></b><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,0);
            font-size:9.0000pt;mso-font-kerning:0.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,0);
            font-size:9.0000pt;mso-font-kerning:0.0000pt;">&nbsp;&nbsp;&nbsp; 单元型可视系统或非可视对讲系统主机分直按式和拨号式两种。直按式容量较小，有14、15、18、21、27户型等，适用于多层住宅楼，特点是一按就应，操作简便。拨号式容量较大，多为256户到891户不等，适用于高层住宅楼，特点是界面豪华，操作方式同拨电话一样。这两种系统均采用总线式布线，解码方式有楼层机解码或室内机解码两种方式，室内机一般与单户型的室内机兼容，均可实现可视对讲或非可视对讲、遥控开锁等功能，并可挂接管理中心。</span><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,0);
            font-size:9.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
            mso-margin-bottom-alt:auto;mso-pagination:widow-orphan;text-align:left;"><b><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,0);
            font-weight:bold;font-size:9.0000pt;mso-font-kerning:0.0000pt;">c) 小区联网型</span></b><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,0);
            font-size:9.0000pt;mso-font-kerning:0.0000pt;">&nbsp;</span><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,0);
            font-size:9.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:5.0000pt;margin-bottom:5.0000pt;mso-margin-top-alt:auto;
            mso-margin-bottom-alt:auto;mso-pagination:widow-orphan;text-align:left;"><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,0);
            font-size:9.0000pt;mso-font-kerning:0.0000pt;">&nbsp;&nbsp;&nbsp; 采用区域集中化管理，功能复杂，各厂家的产品均有自己的特色。一般除具备可视对讲或非可视对讲、遥控开锁等基本功能外，还能接收和传送住户的各种技防探测器报警信息和进行紧急求助，能主动呼叫辖区任一住户或群呼所有住户实行广播功能，有的还与三表（水、煤、电）抄送、IC卡门禁系统和其他系统构成小区物业管理系统。 </span><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,0);
            font-size:9.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="MsoNormal"><span style="mso-spacerun:'yes';font-family:'Times New Roman';mso-fareast-font-family:宋体;
            font-size:10.5000pt;mso-font-kerning:1.0000pt;"><o:p>&nbsp;</o:p></span></p></div>`
        }, 
        {
            id:7,
            title:'门禁基本知识及系统原理',
            html:`<div class="Section0" style="layout-grid:15.6000pt;"><h1><b><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,255);
            font-weight:bold;font-size:12.0000pt;mso-font-kerning:18.0000pt;"><font face="宋体">一、</font></span></b><b><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,255);
            font-weight:bold;font-size:12.0000pt;mso-font-kerning:18.0000pt;"><font face="宋体">什么是门禁</font> ? 当前常见门禁系统的种类有哪些？各有什么优缺点？</span></b><b><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(0,0,255);
            font-weight:bold;font-size:12.0000pt;mso-font-kerning:18.0000pt;"><o:p></o:p></span></b></h1><p class="29"><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            mso-bidi-font-family:宋体;color:rgb(0,0,0);font-size:10.5000pt;
            mso-font-kerning:0.0000pt;"><font face="宋体">门禁</font> <font face="ˎ̥">, </font><font face="宋体">又称出入管理控制系统 通道管理系统</font><font face="ˎ̥">. </font><font face="宋体">是一种管理人员进出的数字化智能管理系统 </font><font face="ˎ̥">.</font></span><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            mso-bidi-font-family:宋体;color:rgb(0,0,0);font-size:10.5000pt;
            mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="29"><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            mso-bidi-font-family:宋体;color:rgb(0,0,0);font-size:10.5000pt;
            mso-font-kerning:0.0000pt;"><font face="宋体">原始的门禁系统概念其实早就在我们生活中。例如：我们家家户户都装了门和锁，自己家里的人配了钥匙，家里人回来时，无需喊门，直接用钥匙开门进入。外面的人来访，屋里的人听到敲门的声音，知道有人来访了，问：</font><font face="ˎ̥">“</font><font face="宋体">谁呀？</font><font face="ˎ̥">”</font><font face="宋体">外面的人回答：</font><font face="ˎ̥">“</font><font face="宋体">我是</font><font face="ˎ̥">***</font><font face="宋体">！</font><font face="ˎ̥">”</font><font face="宋体">里面的人通过声音和以往经验判断该不该开门。这就是一个原始的门禁概念。万里长城就是中国古代最大的原始门禁系统。</font></span><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            mso-bidi-font-family:宋体;color:rgb(0,0,0);font-size:10.5000pt;
            mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="29"><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            mso-bidi-font-family:宋体;color:rgb(0,0,0);font-size:10.5000pt;
            mso-font-kerning:0.0000pt;"><font face="宋体">随着社会电子化智能程度的深入，现在我们看到的门禁系统依据输入设备</font> 介质 和方法的不同可以分为：</span><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            mso-bidi-font-family:宋体;color:rgb(0,0,0);font-size:10.5000pt;
            mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="29"><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            mso-bidi-font-family:宋体;color:rgb(0,0,0);font-size:10.5000pt;
            mso-font-kerning:0.0000pt;"><font face="宋体">密码门禁系统</font> <font face="ˎ̥">, </font><font face="宋体">刷卡门禁系统 </font><font face="ˎ̥">,</font><font face="宋体">生物识别门禁系统</font></span><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            mso-bidi-font-family:宋体;color:rgb(0,0,0);font-size:10.5000pt;
            mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="29"><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            mso-bidi-font-family:宋体;color:rgb(0,0,0);font-size:10.5000pt;
            mso-font-kerning:0.0000pt;"><font face="宋体">密码门禁系统：通过输入密码，系统判断密码正确就驱动电锁，打开门放行。优点：只需记住密码，无需携带其他介质。成本最低。缺点：速度慢，</font> 输入密码一般需要好几秒中，如果进出的人员过多，需要排队。如果输入错误，还需重新输入，耗时更长。安全性差，旁边的人容易通过手势记住别人的密码，密码容易忘记或者泄露。趋势：密码门禁使用的场合越来越少了，只在对安全性要求低，成本低，使用不频繁的场合还在使用。例如：保险柜。</span><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            mso-bidi-font-family:宋体;color:rgb(0,0,0);font-size:10.5000pt;
            mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="29"><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            mso-bidi-font-family:宋体;color:rgb(0,0,0);font-size:10.5000pt;
            mso-font-kerning:0.0000pt;"><font face="宋体">刷卡门禁系统：根据卡的种类又分为</font> 接触卡门禁系统（磁条卡 条码卡）和非接触卡（又叫感应卡 射频卡）门禁系统。接触卡门禁系统由于 接触而是的卡片容易磨损，使用次数不多，卡片容易损坏等，使用的范围已经越来越少了，只在和银行卡（磁条卡）有关的场合被使用，例如：银行<font face="ˎ̥">VIP</font><font face="宋体">通道门禁系统，无人值守取款机门禁系统等局部行业性领域还在使用。非接触</font><font face="ˎ̥">IC</font><font face="宋体">卡，由于其耐用性 性价比好 读取速度快 安全性高等优势是当前门禁系统的主流。所以，当前很多人就把非接触</font><font face="ˎ̥">IC</font><font face="宋体">卡门禁系统简称为门禁系统了。</font></span><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            mso-bidi-font-family:宋体;color:rgb(0,0,0);font-size:10.5000pt;
            mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="29"><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            mso-bidi-font-family:宋体;color:rgb(0,0,0);font-size:10.5000pt;
            mso-font-kerning:0.0000pt;"><font face="宋体">生物识别门禁系统：根据人体生物特征的不同而识别身份的门禁系统。常见的有：指纹门禁系统（每个人的指纹纹路特征存在差异性），掌型仪门禁系统（每个人的手掌的骨骼形状存在差异性）虹膜门禁系统（每个人的视网膜通过光学扫描存在差异性）人像识别门禁系统（每个人的五官特征和位置不同）等。生物识别门禁系统的优点是：无需携带卡片等介质，重复的几率少，不容易被复制，安全性高。缺点是：成本高。由于生物识别需要比对很多参数特征，比对速度慢，不利于人员人数过多的场合。人体的生物特征会随着环境和时间的变化而变化，因此容易产生拒识率（明明是这个人，但是他的生物特征变了，而认为不是本人），例如：指纹由于季节和干湿度不同而不同，掌型和人像由于年龄的增长而改变，虹膜由于眼部患病而改变等。所以，生物识别门禁系统虽然先进和安全，但是应用的范围有限，只在人数不多，安全性要求高，不担心成本高等少数领域进行应用，不是当前门禁系统的主流。</font></span><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            mso-bidi-font-family:宋体;color:rgb(0,0,0);font-size:10.5000pt;
            mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="29"><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            mso-bidi-font-family:宋体;color:rgb(0,0,0);font-size:10.5000pt;
            mso-font-kerning:0.0000pt;"><font face="宋体">门禁系统根据其应用场合和应用对象不同还有一些衍生形式。例如：用于车辆进出管理的停车场管理系统，用于地铁公交收费的地铁门禁系统等。</font></span><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            mso-bidi-font-family:宋体;color:rgb(0,0,0);font-size:10.5000pt;
            mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="29"><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            mso-bidi-font-family:宋体;color:rgb(0,0,0);font-size:10.5000pt;
            mso-font-kerning:0.0000pt;">&nbsp;</span><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            mso-bidi-font-family:宋体;color:rgb(0,0,0);font-size:10.5000pt;
            mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="29"><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            mso-bidi-font-family:宋体;color:rgb(0,0,0);font-size:10.5000pt;
            mso-font-kerning:0.0000pt;">&nbsp;</span><span style="mso-spacerun:'yes';font-family:ˎ̥;mso-fareast-font-family:宋体;
            mso-bidi-font-family:宋体;color:rgb(0,0,0);font-size:10.5000pt;
            mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><h1><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;
            font-size:10.5000pt;mso-font-kerning:18.0000pt;"><o:p>&nbsp;</o:p></span></b></h1></div>`
        }, 
        {
            id:8,
            title:'如何选购无线对讲机',
            html:`<div class="Section0" style="layout-grid:15.6000pt;"><h1 align="center" style="text-align:center;line-height:180%;background:rgb(255,255,255);"><b><span style="mso-spacerun:'yes';font-family:Arial;mso-fareast-font-family:宋体;
            color:rgb(0,0,0);font-weight:bold;font-size:24.0000pt;
            mso-font-kerning:18.0000pt;"><font face="宋体">如何选购无线对讲机</font><font face="Arial">?</font></span></b><b><span style="mso-spacerun:'yes';font-family:Arial;mso-fareast-font-family:宋体;
            color:rgb(0,0,0);font-weight:bold;font-size:24.0000pt;
            mso-font-kerning:18.0000pt;"><o:p></o:p></span></b></h1><p class="MsoNormal" style="line-height:150%;background:rgb(255,255,255);"><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:Arial;mso-fareast-font-family:宋体;
            line-height:150%;color:rgb(0,0,0);mso-ansi-font-weight:bold;
            font-size:9.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">通讯距离</font></span></b><span style="mso-spacerun:'yes';font-family:Arial;mso-fareast-font-family:宋体;
            line-height:150%;color:rgb(0,0,0);font-size:9.0000pt;
            mso-font-kerning:1.0000pt;">&nbsp;</span><span style="mso-spacerun:'yes';font-family:Arial;mso-fareast-font-family:宋体;
            line-height:150%;color:rgb(0,0,0);font-size:9.0000pt;
            mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:Arial;mso-fareast-font-family:宋体;
            line-height:150%;color:rgb(0,0,0);font-size:9.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">一般通讯距离在</font> <font face="Arial">2-3</font><font face="宋体">公里以内的可以视为短距离通讯，对于个人用户，可以选购民用对讲机。对于单位用户，通过申请可以选购小功率对讲机，如果为了保证通讯效果，可以选择专业常规对讲机。需要在城市范围内通讯，通讯距离在</font><font face="Arial">3-10</font><font face="宋体">公里内，可以考虑购买专业对讲机。如果再远一些，最好采用基地台与手持对讲机通讯，或者车载台与车载台通讯基地台我们可以使用输出功率比较大的车载对讲机。如果用户需要更远的距离或者更好的通讯质量，在自己没有组网的情况下，可以考虑使用集群对讲机。集群对讲机因为有无线电网络支持，所以通讯距离比较远，有效距离达到</font><font face="Arial">15--30KM</font><font face="宋体">这种对讲机由于需要网络支持，所以一旦脱网，就像移动电话脱网一样，就算通话双方面对面也无法叫通。 </font></span><span style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Arial;
            mso-hansi-font-family:Arial;mso-bidi-font-family:Arial;line-height:150%;
            color:rgb(0,0,0);font-size:9.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="line-height:150%;background:rgb(255,255,255);"><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:Arial;mso-fareast-font-family:宋体;
            line-height:150%;color:rgb(0,0,0);mso-ansi-font-weight:bold;
            font-size:9.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">发送功率</font> </span></b><span style="mso-spacerun:'yes';font-family:Arial;mso-fareast-font-family:宋体;
            line-height:150%;color:rgb(0,0,0);font-size:9.0000pt;
            mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:Arial;mso-fareast-font-family:宋体;
            line-height:150%;color:rgb(0,0,0);font-size:9.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">对于无线电设备来说，发送功率的大小至关重要。同样的无线设备，发送功率大的产品和发送功率小的产品价格差别也是不小的。不仅如此，发送功率的不同，还直接影响着无线网络的数据传输稳定性。因此，关注所选设备的发送功率大小，也是选择无线设备过程中的一个重要环节。目前无线网络设备的发送功率，有的是直接将功率数值标注在设备说明书中，有的是通过传输距离的远近来表示发送功率大小的。正常情况下，无线设备的发送功率都应该在</font><font face="Arial">25mW</font><font face="宋体">到</font><font face="Arial">50mW</font><font face="宋体">之间，或者传输距离在</font><font face="Arial">50</font><font face="宋体">米到</font><font face="Arial">200</font><font face="宋体">米之间。倘若你无法在设备铭牌或说明书中找到发送功率数值时，千万不要随意购买</font><font face="Arial">- </font></span><span style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Arial;
            mso-hansi-font-family:Arial;mso-bidi-font-family:Arial;line-height:150%;
            color:rgb(0,0,0);font-size:9.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="line-height:150%;background:rgb(255,255,255);"><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:Arial;mso-fareast-font-family:宋体;
            line-height:150%;color:rgb(0,0,0);mso-ansi-font-weight:bold;
            font-size:9.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">专业对讲机</font> </span></b><span style="mso-spacerun:'yes';font-family:Arial;mso-fareast-font-family:宋体;
            line-height:150%;color:rgb(0,0,0);font-size:9.0000pt;
            mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:Arial;mso-fareast-font-family:宋体;
            line-height:150%;color:rgb(0,0,0);font-size:9.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">专业对讲机是指发射功率为</font><font face="Arial">4-5W</font><font face="宋体">，频率范围在</font><font face="Arial">136-174MHz</font><font face="宋体">或</font><font face="Arial">400-480MHz</font><font face="宋体">的对讲机，这类对讲机通话距离较远一般在</font><font face="Arial">3</font><font face="宋体">至</font><font face="Arial">8</font><font face="宋体">公里之间（视具体环境的不同而有所改变）。操作起来相对简单因为大多数专业对讲机都没有数字键盘只有两个旋钮来控制信道数及电源与音量开关。专业对讲机的不足之处就是体积较大也相对较重，但是它在防进水、抗振、抗摔、防老化方面较突出而且对讲机的性能也稳定得多。专业对讲机的价格较高（</font><font face="Arial">500-2500</font><font face="宋体">元）一般在建筑工程、物业管理、工厂车间应用得比较多。 </font></span><span style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Arial;
            mso-hansi-font-family:Arial;mso-bidi-font-family:Arial;line-height:150%;
            color:rgb(0,0,0);font-size:9.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="line-height:150%;background:rgb(255,255,255);"><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:Arial;mso-fareast-font-family:宋体;
            line-height:150%;color:rgb(0,0,0);mso-ansi-font-weight:bold;
            font-size:9.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">商用对讲机</font> </span></b><span style="mso-spacerun:'yes';font-family:Arial;mso-fareast-font-family:宋体;
            line-height:150%;color:rgb(0,0,0);font-size:9.0000pt;
            mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:Arial;mso-fareast-font-family:宋体;
            line-height:150%;color:rgb(0,0,0);font-size:9.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">商用对讲机是指发射功率为</font><font face="Arial">0.5-4W</font><font face="宋体">，频率范围在</font><font face="Arial">400-480MHz</font><font face="宋体">的对讲机，这类对讲机通话距离在</font><font face="Arial">800</font><font face="宋体">米至</font><font face="Arial">8</font><font face="宋体">公里之间（视对讲机的发射功率大小和具体环境的不同而有所改变）。商用对讲机外型轻巧、美观，通过对讲机的皮带夹将对讲机系在腰间，戴上耳机就可以解放双手提高工作效率。商用对讲机的价格同专业对讲机一样相对较高，一般用于商场、超级市场、零售业、工厂、制造业。 </font></span><span style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Arial;
            mso-hansi-font-family:Arial;mso-bidi-font-family:Arial;line-height:150%;
            color:rgb(0,0,0);font-size:9.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="line-height:150%;background:rgb(255,255,255);"><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:Arial;mso-fareast-font-family:宋体;
            line-height:150%;color:rgb(0,0,0);mso-ansi-font-weight:bold;
            font-size:9.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">民用对讲机</font> </span></b><span style="mso-spacerun:'yes';font-family:Arial;mso-fareast-font-family:宋体;
            line-height:150%;color:rgb(0,0,0);font-size:9.0000pt;
            mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:Arial;mso-fareast-font-family:宋体;
            line-height:150%;color:rgb(0,0,0);font-size:9.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">民用对讲机是指发射功率为</font><font face="Arial">0.5W</font><font face="宋体">不大于</font><font face="Arial">1W</font><font face="宋体">，频率范围在</font><font face="Arial">409MHz</font><font face="宋体">之间的对讲机，民用对讲机一般的通话距离较短，（空旷地</font><font face="Arial">3</font><font face="宋体">公里）是国家批准无须执照就可以使用的无线通讯设备。民用对讲机体积小巧、色彩多样，价格较低一般只要</font><font face="Arial">300--600</font><font face="宋体">元即可，比较适合个人出差、旅游，酒楼使用。 </font></span><span style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Arial;
            mso-hansi-font-family:Arial;mso-bidi-font-family:Arial;line-height:150%;
            color:rgb(0,0,0);font-size:9.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="line-height:150%;background:rgb(255,255,255);"><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:Arial;mso-fareast-font-family:宋体;
            line-height:150%;color:rgb(0,0,0);mso-ansi-font-weight:bold;
            font-size:9.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">业余对讲机</font> </span></b><span style="mso-spacerun:'yes';font-family:Arial;mso-fareast-font-family:宋体;
            line-height:150%;color:rgb(0,0,0);font-size:9.0000pt;
            mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:Arial;mso-fareast-font-family:宋体;
            line-height:150%;color:rgb(0,0,0);font-size:9.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">业余对讲机专为满足业余无线电爱好者使用而设计、生产的无线电对讲机。这类对讲机又可称为</font><font face="Arial">“</font><font face="宋体">玩机</font><font face="Arial">”</font><font face="宋体">。针对这种业余的个人无线电业务，各个国家都开辟了专用频段分配给业余无线电运动爱好者使用。我国开辟的频段为</font><font face="Arial">144~146MHZ</font><font face="宋体">和</font><font face="Arial">430~440MHZ</font><font face="宋体">，世界各国一般也都是这一频段。业余爱好者只需购得自己喜欢的机型，申请地方电台入网许证，就可以和其他人无话不聊了。世界各国一般也都是这一频段。 </font></span><span style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Arial;
            mso-hansi-font-family:Arial;mso-bidi-font-family:Arial;line-height:150%;
            color:rgb(0,0,0);font-size:9.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="line-height:150%;background:rgb(255,255,255);"><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:Arial;mso-fareast-font-family:宋体;
            line-height:150%;color:rgb(0,0,0);mso-ansi-font-weight:bold;
            font-size:9.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">防爆对讲机</font> </span></b><span style="mso-spacerun:'yes';font-family:Arial;mso-fareast-font-family:宋体;
            line-height:150%;color:rgb(0,0,0);font-size:9.0000pt;
            mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:Arial;mso-fareast-font-family:宋体;
            line-height:150%;color:rgb(0,0,0);font-size:9.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">在众多无线对讲机品牌中带有防爆型对讲机的品牌并不多，现中国市面上只有</font><font face="Arial">(MOTOROLA)</font><font face="宋体">摩托罗拉、</font><font face="Arial">(VertexStandard)</font><font face="宋体">威泰克斯、</font><font face="Arial">(HYT)-</font><font face="宋体">好易通这三个对讲机品牌。摩托罗拉防爆对讲机以其优越的性能抢战了中国八层以上的防爆对讲机市场。防爆对讲机是指可以在易燃易爆的环境下工作的对讲机如有：煤矿、液化气灌装公司、油库、油田、石油、加油站化工、石化、煤矿、矿山、天燃气、化工厂、面粉加工厂、亚麻加工厂、煤炭加工厂、消防。 </font></span><span style="mso-spacerun:'yes';font-family:Arial;mso-fareast-font-family:宋体;
            line-height:150%;color:rgb(0,0,0);font-size:9.0000pt;
            mso-font-kerning:1.0000pt;"><br></span><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:Arial;mso-fareast-font-family:宋体;
            line-height:150%;color:rgb(0,0,0);mso-ansi-font-weight:bold;
            font-size:9.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">铁路对讲机</font> </span></b><span style="mso-spacerun:'yes';font-family:Arial;mso-fareast-font-family:宋体;
            line-height:150%;color:rgb(0,0,0);font-size:9.0000pt;
            mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:Arial;mso-fareast-font-family:宋体;
            line-height:150%;color:rgb(0,0,0);font-size:9.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">铁路对讲机好无疑问就是对讲机制造商根据铁路、铁道部门的实际工作需求而为其量身定做的专用对讲设备。发射功率为</font><font face="Arial">4-5W</font><font face="宋体">，频率范围在</font><font face="Arial">440--470MHz</font><font face="宋体">内可选。铁路对讲机的面壳正面一般有</font><font face="Arial">2-4</font><font face="宋体">个功能按钮，用于平原、遂道不同环境通讯的选择，车站与机车的切换。由于列车行驶噪声很大，对对讲机的音频输出要求很高，对讲机都通过语音压扩，使得通话音质更加清晰清脆。 </font></span><span style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Arial;
            mso-hansi-font-family:Arial;mso-bidi-font-family:Arial;line-height:150%;
            color:rgb(0,0,0);font-size:9.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="line-height:150%;background:rgb(255,255,255);"><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:Arial;mso-fareast-font-family:宋体;
            line-height:150%;color:rgb(0,0,0);mso-ansi-font-weight:bold;
            font-size:9.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">警用对讲机</font> </span></b><span style="mso-spacerun:'yes';font-family:Arial;mso-fareast-font-family:宋体;
            line-height:150%;color:rgb(0,0,0);font-size:9.0000pt;
            mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:Arial;mso-fareast-font-family:宋体;
            line-height:150%;color:rgb(0,0,0);font-size:9.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">警用对讲机是公安系统专用设备，警用对讲机的频率范围为</font><font face="Arial">350-370MHz</font><font face="宋体">、</font><font face="Arial">370-390MHz</font><font face="宋体">。任何人不得以任何方式窃取、盗用公安系统专用对讲机的频率资源。警用对讲机的功能较多，一般外型于键盘型居多。我们在选购无线对讲机时候应注意对讲机的发射频率范围大多为（</font><font face="Arial">400-420MHz</font><font face="宋体">、</font><font face="Arial">450-470MHz</font><font face="宋体">）以免引来不必要麻烦。 </font></span><span style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Arial;
            mso-hansi-font-family:Arial;mso-bidi-font-family:Arial;line-height:150%;
            color:rgb(0,0,0);font-size:9.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="line-height:150%;background:rgb(255,255,255);"><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:Arial;mso-fareast-font-family:宋体;
            line-height:150%;color:rgb(0,0,0);mso-ansi-font-weight:bold;
            font-size:9.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">车载对讲机</font> </span></b><span style="mso-spacerun:'yes';font-family:Arial;mso-fareast-font-family:宋体;
            line-height:150%;color:rgb(0,0,0);font-size:9.0000pt;
            mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:Arial;mso-fareast-font-family:宋体;
            line-height:150%;color:rgb(0,0,0);font-size:9.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">车载对讲机的工作电压为</font><font face="Arial">12.5V</font><font face="宋体">，现在的汽车电压分为</font><font face="Arial">12V</font><font face="宋体">与</font><font face="Arial">24V</font><font face="宋体">，当车载对讲机要在电压为</font><font face="Arial">24V</font><font face="宋体">的汽车上工作时，需要在汽车与对讲机之间安装一台变压器。车载对讲机的发射功率为</font><font face="Arial">25-50W</font><font face="宋体">，功率较大，信号发射较远。车载对讲机只能固定在一个地方，使用起来不像手持对讲机那样方便。车载对讲机一般在车队中使用。 </font></span><span style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Arial;
            mso-hansi-font-family:Arial;mso-bidi-font-family:Arial;line-height:150%;
            color:rgb(0,0,0);font-size:9.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="line-height:150%;background:rgb(255,255,255);"><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:Arial;mso-fareast-font-family:宋体;
            line-height:150%;color:rgb(0,0,0);mso-ansi-font-weight:bold;
            font-size:9.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">中转台基地台</font> </span></b><span style="mso-spacerun:'yes';font-family:Arial;mso-fareast-font-family:宋体;
            line-height:150%;color:rgb(0,0,0);font-size:9.0000pt;
            mso-font-kerning:1.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:Arial;mso-fareast-font-family:宋体;
            line-height:150%;color:rgb(0,0,0);font-size:9.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">中转台、基地台的具体功能就是将接收到的无线信号通过内部处理以更大的功率将其发射出去，这样就达到了无线通讯距离的拉扩。说简单一些中转台、基地台就是一台无线通讯信号发大器。随着您公司业务的发展和增长，双向通信的需求也在扩大。使用中转台、基地台，可以增强您的通信能力。而通过我们功能强大的调度台和转发台，可以促进系统资源的协调，并拓宽对讲机的通信范围。</font></span><span style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Arial;
            mso-hansi-font-family:Arial;mso-bidi-font-family:Arial;line-height:150%;
            color:rgb(0,0,0);font-size:9.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></div>`
        }, 
        {
            id:9,
            title:'视频监控工程布线施工应注意哪些问题',
            html:`<div class="Section0" style="layout-grid:15.6000pt;"><p class="MsoNormal" style="margin-left:0.0000pt;text-indent:0.0000pt;mso-pagination:widow-orphan;
            text-align:left;line-height:15.7500pt;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:sans-serif;color:rgb(0,0,0);
            letter-spacing:0.0000pt;mso-ansi-font-weight:normal;text-transform:none;
            mso-ansi-font-style:normal;font-size:12.0000pt;mso-font-kerning:0.0000pt;
            background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><font face="sans-serif">　　一般来说，当我们拿到施工图纸时，就意味着要为工程做工作准备了。这时，我们要按图纸所标注的走线方向，察看实际的情况。如电缆沟、井的实际走向、下一个井口的位置以及它们之间的距离；地埋管道的长度、牵引绳、管道的畅通与否；以及室内的电缆槽、架的位置、长度等等工程中所涉及的问题都要一一察看。并记录在案。</font></span><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(68,68,68);
            letter-spacing:0.0000pt;mso-ansi-font-weight:normal;text-transform:none;
            mso-ansi-font-style:normal;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:0.0000pt;text-indent:0.0000pt;mso-pagination:widow-orphan;
            text-align:left;line-height:15.7500pt;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:sans-serif;color:rgb(0,0,0);
            letter-spacing:0.0000pt;mso-ansi-font-weight:normal;text-transform:none;
            mso-ansi-font-style:normal;font-size:12.0000pt;mso-font-kerning:0.0000pt;
            background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><font face="sans-serif">　　视频监控工程布线施工时，针对不同地段和环境提出以下几点要求。</font></span><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(68,68,68);
            letter-spacing:0.0000pt;mso-ansi-font-weight:normal;text-transform:none;
            mso-ansi-font-style:normal;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:0.0000pt;text-indent:0.0000pt;mso-pagination:widow-orphan;
            text-align:left;line-height:15.7500pt;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:sans-serif;color:rgb(0,0,0);
            letter-spacing:0.0000pt;mso-ansi-font-weight:normal;text-transform:none;
            mso-ansi-font-style:normal;font-size:12.0000pt;mso-font-kerning:0.0000pt;
            background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><font face="sans-serif">　　</font></span><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:sans-serif;color:rgb(0,0,0);
            letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
            mso-ansi-font-style:normal;font-size:12.0000pt;mso-font-kerning:0.0000pt;
            background:rgb(255,255,255);mso-shading:rgb(255,255,255);">1、布明线槽的要求：</span></b><span style="mso-spacerun:'yes';font-family:sans-serif;color:rgb(0,0,0);
            letter-spacing:0.0000pt;mso-ansi-font-weight:normal;text-transform:none;
            mso-ansi-font-style:normal;font-size:12.0000pt;mso-font-kerning:0.0000pt;
            background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><font face="sans-serif">明线槽要求在不受外力作用的情况下，能够保证</font>10~15年的使用期，因此对不同规格的槽提出不同的安装要求：</span><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(68,68,68);
            letter-spacing:0.0000pt;mso-ansi-font-weight:normal;text-transform:none;
            mso-ansi-font-style:normal;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:0.0000pt;text-indent:0.0000pt;mso-pagination:widow-orphan;
            text-align:left;line-height:15.7500pt;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:sans-serif;color:rgb(0,0,0);
            letter-spacing:0.0000pt;mso-ansi-font-weight:normal;text-transform:none;
            mso-ansi-font-style:normal;font-size:12.0000pt;mso-font-kerning:0.0000pt;
            background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><font face="sans-serif">　　（</font>1）线槽规格小于25㎜×30㎜时，每间隔15㎝～25㎝距离,安装一个固定螺钉；</span><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(68,68,68);
            letter-spacing:0.0000pt;mso-ansi-font-weight:normal;text-transform:none;
            mso-ansi-font-style:normal;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:0.0000pt;text-indent:0.0000pt;mso-pagination:widow-orphan;
            text-align:left;line-height:15.7500pt;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:sans-serif;color:rgb(0,0,0);
            letter-spacing:0.0000pt;mso-ansi-font-weight:normal;text-transform:none;
            mso-ansi-font-style:normal;font-size:12.0000pt;mso-font-kerning:0.0000pt;
            background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><font face="sans-serif">　　（</font>2）线槽规格小于50㎜×50㎜时，每间隔15㎝～30㎝距离,安装二个固定螺钉,且要求错位安装；</span><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(68,68,68);
            letter-spacing:0.0000pt;mso-ansi-font-weight:normal;text-transform:none;
            mso-ansi-font-style:normal;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:0.0000pt;text-indent:0.0000pt;mso-pagination:widow-orphan;
            text-align:left;line-height:15.7500pt;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:sans-serif;color:rgb(0,0,0);
            letter-spacing:0.0000pt;mso-ansi-font-weight:normal;text-transform:none;
            mso-ansi-font-style:normal;font-size:12.0000pt;mso-font-kerning:0.0000pt;
            background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><font face="sans-serif">　　（</font>3）线槽规格小于30㎜×100㎜时，每间隔15㎝～30㎝距离,安装三个固定螺钉,且要求错位安装；</span><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(68,68,68);
            letter-spacing:0.0000pt;mso-ansi-font-weight:normal;text-transform:none;
            mso-ansi-font-style:normal;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:0.0000pt;text-indent:0.0000pt;mso-pagination:widow-orphan;
            text-align:left;line-height:15.7500pt;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:sans-serif;color:rgb(0,0,0);
            letter-spacing:0.0000pt;mso-ansi-font-weight:normal;text-transform:none;
            mso-ansi-font-style:normal;font-size:12.0000pt;mso-font-kerning:0.0000pt;
            background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><font face="sans-serif">　　（</font>4）金属明槽原则上使用托架安装（托架每间隔1米～1.5米距离）,不能安装托架的地段,每间隔15㎝～30㎝距离,安装三个固定螺钉,且要求错位安装；</span><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(68,68,68);
            letter-spacing:0.0000pt;mso-ansi-font-weight:normal;text-transform:none;
            mso-ansi-font-style:normal;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:0.0000pt;text-indent:0.0000pt;mso-pagination:widow-orphan;
            text-align:left;line-height:15.7500pt;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:sans-serif;color:rgb(0,0,0);
            letter-spacing:0.0000pt;mso-ansi-font-weight:normal;text-transform:none;
            mso-ansi-font-style:normal;font-size:12.0000pt;mso-font-kerning:0.0000pt;
            background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><font face="sans-serif">　　（</font>5）当布管时出现“S”型“Z”拐弯时均安装过线盒，不易拐弯时使用软管。</span><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(68,68,68);
            letter-spacing:0.0000pt;mso-ansi-font-weight:normal;text-transform:none;
            mso-ansi-font-style:normal;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:0.0000pt;text-indent:0.0000pt;mso-pagination:widow-orphan;
            text-align:left;line-height:15.7500pt;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:sans-serif;color:rgb(0,0,0);
            letter-spacing:0.0000pt;mso-ansi-font-weight:normal;text-transform:none;
            mso-ansi-font-style:normal;font-size:12.0000pt;mso-font-kerning:0.0000pt;
            background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><font face="sans-serif">　　</font></span><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:sans-serif;color:rgb(0,0,0);
            letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
            mso-ansi-font-style:normal;font-size:12.0000pt;mso-font-kerning:0.0000pt;
            background:rgb(255,255,255);mso-shading:rgb(255,255,255);">2、室内布暗管的要求</span></b><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(68,68,68);
            letter-spacing:0.0000pt;mso-ansi-font-weight:normal;text-transform:none;
            mso-ansi-font-style:normal;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:0.0000pt;text-indent:0.0000pt;mso-pagination:widow-orphan;
            text-align:left;line-height:15.7500pt;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:sans-serif;color:rgb(0,0,0);
            letter-spacing:0.0000pt;mso-ansi-font-weight:normal;text-transform:none;
            mso-ansi-font-style:normal;font-size:12.0000pt;mso-font-kerning:0.0000pt;
            background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><font face="sans-serif">　　</font>(1)摄像机的线槽均采用暗埋布线。</span><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(68,68,68);
            letter-spacing:0.0000pt;mso-ansi-font-weight:normal;text-transform:none;
            mso-ansi-font-style:normal;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:0.0000pt;text-indent:0.0000pt;mso-pagination:widow-orphan;
            text-align:left;line-height:15.7500pt;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:sans-serif;color:rgb(0,0,0);
            letter-spacing:0.0000pt;mso-ansi-font-weight:normal;text-transform:none;
            mso-ansi-font-style:normal;font-size:12.0000pt;mso-font-kerning:0.0000pt;
            background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><font face="sans-serif">　　</font>(2)如果原先已有预埋管，能够利用的尽可能利用起来。不能利用时，再破墙开槽设立预埋管。</span><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(68,68,68);
            letter-spacing:0.0000pt;mso-ansi-font-weight:normal;text-transform:none;
            mso-ansi-font-style:normal;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:0.0000pt;text-indent:0.0000pt;mso-pagination:widow-orphan;
            text-align:left;line-height:15.7500pt;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:sans-serif;color:rgb(0,0,0);
            letter-spacing:0.0000pt;mso-ansi-font-weight:normal;text-transform:none;
            mso-ansi-font-style:normal;font-size:12.0000pt;mso-font-kerning:0.0000pt;
            background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><font face="sans-serif">　　</font>(3)对承重墙体原则上一律不能开槽，打洞，但部分地方，因施工的特殊需要，甲、乙双方共同协商、考虑，开较浅的槽,不允许打洞。</span><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(68,68,68);
            letter-spacing:0.0000pt;mso-ansi-font-weight:normal;text-transform:none;
            mso-ansi-font-style:normal;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:0.0000pt;text-indent:0.0000pt;mso-pagination:widow-orphan;
            text-align:left;line-height:15.7500pt;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:sans-serif;color:rgb(0,0,0);
            letter-spacing:0.0000pt;mso-ansi-font-weight:normal;text-transform:none;
            mso-ansi-font-style:normal;font-size:12.0000pt;mso-font-kerning:0.0000pt;
            background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><font face="sans-serif">　　</font></span><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:sans-serif;color:rgb(0,0,0);
            letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
            mso-ansi-font-style:normal;font-size:12.0000pt;mso-font-kerning:0.0000pt;
            background:rgb(255,255,255);mso-shading:rgb(255,255,255);">3、布线的其他要求:</span></b><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(68,68,68);
            letter-spacing:0.0000pt;mso-ansi-font-weight:normal;text-transform:none;
            mso-ansi-font-style:normal;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:0.0000pt;text-indent:0.0000pt;mso-pagination:widow-orphan;
            text-align:left;line-height:15.7500pt;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:sans-serif;color:rgb(0,0,0);
            letter-spacing:0.0000pt;mso-ansi-font-weight:normal;text-transform:none;
            mso-ansi-font-style:normal;font-size:12.0000pt;mso-font-kerning:0.0000pt;
            background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><font face="sans-serif">　　（</font>1）强电与弱电分开布线，走各自的管路；</span><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(68,68,68);
            letter-spacing:0.0000pt;mso-ansi-font-weight:normal;text-transform:none;
            mso-ansi-font-style:normal;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:0.0000pt;text-indent:0.0000pt;mso-pagination:widow-orphan;
            text-align:left;line-height:15.7500pt;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:sans-serif;color:rgb(0,0,0);
            letter-spacing:0.0000pt;mso-ansi-font-weight:normal;text-transform:none;
            mso-ansi-font-style:normal;font-size:12.0000pt;mso-font-kerning:0.0000pt;
            background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><font face="sans-serif">　　（</font>2）强电与弱电管路在水平方向同向走线时保持10㎝～30㎝的距离；</span><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(68,68,68);
            letter-spacing:0.0000pt;mso-ansi-font-weight:normal;text-transform:none;
            mso-ansi-font-style:normal;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:0.0000pt;text-indent:0.0000pt;mso-pagination:widow-orphan;
            text-align:left;line-height:15.7500pt;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:sans-serif;color:rgb(0,0,0);
            letter-spacing:0.0000pt;mso-ansi-font-weight:normal;text-transform:none;
            mso-ansi-font-style:normal;font-size:12.0000pt;mso-font-kerning:0.0000pt;
            background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><font face="sans-serif">　　（</font>3）强电与弱电管路垂直交叉时，各自套管，以避免强电对弱电的干扰；</span><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(68,68,68);
            letter-spacing:0.0000pt;mso-ansi-font-weight:normal;text-transform:none;
            mso-ansi-font-style:normal;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:0.0000pt;text-indent:0.0000pt;mso-pagination:widow-orphan;
            text-align:left;line-height:15.7500pt;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:sans-serif;color:rgb(0,0,0);
            letter-spacing:0.0000pt;mso-ansi-font-weight:normal;text-transform:none;
            mso-ansi-font-style:normal;font-size:12.0000pt;mso-font-kerning:0.0000pt;
            background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><font face="sans-serif">　　（</font>4）各种线的两端均作标记</span><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(68,68,68);
            letter-spacing:0.0000pt;mso-ansi-font-weight:normal;text-transform:none;
            mso-ansi-font-style:normal;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:0.0000pt;text-indent:0.0000pt;mso-pagination:widow-orphan;
            text-align:left;line-height:15.7500pt;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:sans-serif;color:rgb(0,0,0);
            letter-spacing:0.0000pt;mso-ansi-font-weight:normal;text-transform:none;
            mso-ansi-font-style:normal;font-size:12.0000pt;mso-font-kerning:0.0000pt;
            background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><font face="sans-serif">　　（</font>5）信号线、视频线中间不允许有端接点；</span><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(68,68,68);
            letter-spacing:0.0000pt;mso-ansi-font-weight:normal;text-transform:none;
            mso-ansi-font-style:normal;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:0.0000pt;text-indent:0.0000pt;mso-pagination:widow-orphan;
            text-align:left;line-height:15.7500pt;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:sans-serif;color:rgb(0,0,0);
            letter-spacing:0.0000pt;mso-ansi-font-weight:normal;text-transform:none;
            mso-ansi-font-style:normal;font-size:12.0000pt;mso-font-kerning:0.0000pt;
            background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><font face="sans-serif">　　（</font>6）电源线原则上中间不允许有接续点，万一出现时必须加过线盒；</span><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(68,68,68);
            letter-spacing:0.0000pt;mso-ansi-font-weight:normal;text-transform:none;
            mso-ansi-font-style:normal;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:0.0000pt;text-indent:0.0000pt;mso-pagination:widow-orphan;
            text-align:left;line-height:15.7500pt;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:sans-serif;color:rgb(0,0,0);
            letter-spacing:0.0000pt;mso-ansi-font-weight:normal;text-transform:none;
            mso-ansi-font-style:normal;font-size:12.0000pt;mso-font-kerning:0.0000pt;
            background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><font face="sans-serif">　　（</font>7）信号线、视频线、电源线等在各自的槽管中要平直，没有扭曲、打结的现象；</span><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(68,68,68);
            letter-spacing:0.0000pt;mso-ansi-font-weight:normal;text-transform:none;
            mso-ansi-font-style:normal;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:0.0000pt;text-indent:0.0000pt;mso-pagination:widow-orphan;
            text-align:left;line-height:15.7500pt;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:sans-serif;color:rgb(0,0,0);
            letter-spacing:0.0000pt;mso-ansi-font-weight:normal;text-transform:none;
            mso-ansi-font-style:normal;font-size:12.0000pt;mso-font-kerning:0.0000pt;
            background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><font face="sans-serif">　　（</font>8）软管与硬管连接时，软管、硬管进槽时要牢固、可靠。</span><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(68,68,68);
            letter-spacing:0.0000pt;mso-ansi-font-weight:normal;text-transform:none;
            mso-ansi-font-style:normal;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-left:0.0000pt;text-indent:0.0000pt;mso-pagination:widow-orphan;
            text-align:left;line-height:15.7500pt;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:sans-serif;color:rgb(0,0,0);
            letter-spacing:0.0000pt;mso-ansi-font-weight:normal;text-transform:none;
            mso-ansi-font-style:normal;font-size:12.0000pt;mso-font-kerning:0.0000pt;
            background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><font face="sans-serif">　　各种线缆布敷后，均按布线标准的要求，对其性能进行测试并符合规定的技术指标。和联电子</font>16年专业打造，行业标杆!安防监控：好品质，好方案，好服务!一体化运作，高效安全!</span><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(68,68,68);
            letter-spacing:0.0000pt;mso-ansi-font-weight:normal;text-transform:none;
            mso-ansi-font-style:normal;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal"><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
            mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;"><o:p>&nbsp;</o:p></span></p></div>`
        }, 
        {
            id:10,
            title:'停车场系统施工规范',
            html:`<div class="Section0" style="layout-grid:15.6000pt;"><p class="MsoTitle" style="margin-top:7.8000pt;margin-bottom:7.8000pt;mso-para-margin-top:0.5000gd;
            mso-para-margin-bottom:0.5000gd;"><b><span style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Cambria;
            mso-hansi-font-family:Cambria;mso-bidi-font-family:'Times New Roman';font-weight:bold;
            font-size:16.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">停车场系统施工规范</font></span></b><b><span style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Cambria;
            mso-hansi-font-family:Cambria;mso-bidi-font-family:'Times New Roman';font-weight:bold;
            font-size:16.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></b></p><p class="MsoNormal" style="margin-top:7.8000pt;margin-bottom:7.8000pt;mso-para-margin-top:0.5000gd;
            mso-para-margin-bottom:0.5000gd;"><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:宋体;mso-ansi-font-weight:bold;
            font-size:15.0000pt;mso-font-kerning:1.0000pt;"><font face="宋体">一、施工准备</font></span></b><b style="mso-bidi-font-weight:normal"><span style="mso-spacerun:'yes';font-family:宋体;mso-ansi-font-weight:bold;
            font-size:15.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></b></p><p class="MsoNormal" style="margin-top:7.8000pt;margin-bottom:7.8000pt;margin-left:21.0000pt;
            mso-para-margin-top:0.5000gd;mso-para-margin-bottom:0.5000gd;text-indent:-21.0000pt;
            mso-list:l3 level1 lfo1;"><!--[if !supportLists]--><span style="font-family:宋体;font-size:14.0000pt;mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">1)<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:14.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">工具</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:14.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:7.8000pt;margin-bottom:7.8000pt;margin-left:39.0000pt;
            mso-para-margin-top:0.5000gd;mso-para-margin-bottom:0.5000gd;text-indent:-18.0000pt;
            mso-list:l1 level1 lfo2;"><!--[if !supportLists]--><span style="font-family:'Times New Roman';font-size:12.0000pt;mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">1、<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">冲击钻：主要用于设备固定、穿墙打孔以及线槽、管线固定，钻头￠</font><font face="Times New Roman">22/14/12/8</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:7.8000pt;margin-bottom:7.8000pt;margin-left:39.0000pt;
            mso-para-margin-top:0.5000gd;mso-para-margin-bottom:0.5000gd;text-indent:-18.0000pt;
            mso-list:l1 level1 lfo2;"><!--[if !supportLists]--><span style="font-family:'Times New Roman';font-size:12.0000pt;mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">2、<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">打磨机：主要用于金属材料的切割、修磨</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:7.8000pt;margin-bottom:7.8000pt;margin-left:39.0000pt;
            mso-para-margin-top:0.5000gd;mso-para-margin-bottom:0.5000gd;text-indent:-18.0000pt;
            mso-list:l1 level1 lfo2;"><!--[if !supportLists]--><span style="font-family:'Times New Roman';font-size:12.0000pt;mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">3、<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">手电钻：主要用于金属材料的打孔，钻头￠</font><font face="Times New Roman">12/8/4.2</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:7.8000pt;margin-bottom:7.8000pt;margin-left:39.0000pt;
            mso-para-margin-top:0.5000gd;mso-para-margin-bottom:0.5000gd;text-indent:-18.0000pt;
            mso-list:l1 level1 lfo2;"><!--[if !supportLists]--><span style="font-family:'Times New Roman';font-size:12.0000pt;mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">4、<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">拉钉枪：主要用于金属材料之间的铆合</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:7.8000pt;margin-bottom:7.8000pt;margin-left:39.0000pt;
            mso-para-margin-top:0.5000gd;mso-para-margin-bottom:0.5000gd;text-indent:-18.0000pt;
            mso-list:l1 level1 lfo2;"><!--[if !supportLists]--><span style="font-family:'Times New Roman';font-size:12.0000pt;mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">5、<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">手提切割机：主要用于水泥、地感的切割（切片￠</font><font face="Times New Roman">180</font><font face="宋体">）</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:7.8000pt;margin-bottom:7.8000pt;margin-left:39.0000pt;
            mso-para-margin-top:0.5000gd;mso-para-margin-bottom:0.5000gd;text-indent:-18.0000pt;
            mso-list:l1 level1 lfo2;"><!--[if !supportLists]--><span style="font-family:'Times New Roman';font-size:12.0000pt;mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">6、<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">模版：固定安全岛、水泥台</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:7.8000pt;margin-bottom:7.8000pt;margin-left:39.0000pt;
            mso-para-margin-top:0.5000gd;mso-para-margin-bottom:0.5000gd;text-indent:-18.0000pt;
            mso-list:l1 level1 lfo2;"><!--[if !supportLists]--><span style="font-family:'Times New Roman';font-size:12.0000pt;mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">7、<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">其他常用工具：万用表、内六方扳手套件、活动扳手、烙铁、剥线钳、尖嘴钳、大小螺丝刀套件、网线钳等。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:7.8000pt;margin-bottom:7.8000pt;margin-left:21.0000pt;
            mso-para-margin-top:0.5000gd;mso-para-margin-bottom:0.5000gd;text-indent:-21.0000pt;
            mso-list:l3 level1 lfo1;"><!--[if !supportLists]--><span style="font-family:宋体;font-size:14.0000pt;mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">2)<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:14.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">辅材</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:14.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:7.8000pt;margin-bottom:7.8000pt;margin-left:39.3000pt;
            mso-para-margin-top:0.5000gd;mso-para-margin-bottom:0.5000gd;text-indent:-18.0000pt;
            mso-list:l0 level1 lfo3;"><!--[if !supportLists]--><span style="font-family:'Times New Roman';font-size:12.0000pt;mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">1、<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">水泥、沙子、石子以及打混泥土的工具</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:7.8000pt;margin-bottom:7.8000pt;margin-left:39.3000pt;
            mso-para-margin-top:0.5000gd;mso-para-margin-bottom:0.5000gd;text-indent:-18.0000pt;
            mso-list:l0 level1 lfo3;"><!--[if !supportLists]--><span style="font-family:'Times New Roman';font-size:12.0000pt;mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">2、<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;
            mso-font-kerning:1.0000pt;"><font face="Times New Roman">PVC(KBG</font><font face="宋体">铁管</font><font face="Times New Roman">)</font><font face="宋体">及连接接头、弯头、及固定夹，膨胀管、自攻钉、备用膨胀螺栓；</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:7.8000pt;margin-bottom:7.8000pt;margin-left:39.3000pt;
            mso-para-margin-top:0.5000gd;mso-para-margin-bottom:0.5000gd;text-indent:-18.0000pt;
            mso-list:l0 level1 lfo3;"><!--[if !supportLists]--><span style="font-family:'Times New Roman';font-size:12.0000pt;mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">3、<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">扎带、防水胶带、热缩管、缠绕带（软管）</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:7.8000pt;margin-bottom:7.8000pt;mso-para-margin-top:0.5000gd;
            mso-para-margin-bottom:0.5000gd;text-indent:24.0000pt;mso-char-indent-count:2.0000;"><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">根据客户的具体配置和设计图纸确定布线方案，以上图为例，列举安全岛的布线方式。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:7.8000pt;margin-bottom:7.8000pt;margin-left:42.3000pt;
            mso-para-margin-top:0.5000gd;mso-para-margin-bottom:0.5000gd;text-indent:-21.0000pt;
            mso-list:l2 level1 lfo4;"><!--[if !supportLists]--><span style="font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><span style="mso-list:Ignore;">1)<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;
            mso-font-kerning:1.0000pt;"><font face="Times New Roman">4</font></span><span style="mso-spacerun:'yes';font-family:'Times New Roman';font-size:12.0000pt;
            mso-font-kerning:1.0000pt;">85</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">通讯线：从电脑到出入口读卡机，采用两芯屏蔽双绞线，通讯线最远距离不应超过</font><font face="Times New Roman">7</font></span><span style="mso-spacerun:'yes';font-family:'Times New Roman';font-size:12.0000pt;
            mso-font-kerning:1.0000pt;">00</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">米，否则需加</font><font face="Times New Roman">485</font><font face="宋体">中继器。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:7.8000pt;margin-bottom:7.8000pt;mso-para-margin-top:0.5000gd;
            mso-para-margin-bottom:0.5000gd;text-indent:24.0000pt;mso-char-indent-count:2.0000;"><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;
            mso-font-kerning:1.0000pt;"><font face="Times New Roman">2</font><font face="宋体">）开闸线：出入口读卡机到出入口道闸布两芯屏蔽双绞线。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:7.8000pt;margin-bottom:7.8000pt;mso-para-margin-top:0.5000gd;
            mso-para-margin-bottom:0.5000gd;text-indent:24.0000pt;mso-char-indent-count:2.0000;"><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;
            mso-font-kerning:1.0000pt;"><font face="Times New Roman">3</font><font face="宋体">）按钮线：道闸到岗亭内的三联按钮布六芯屏蔽线。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:7.8000pt;margin-bottom:7.8000pt;margin-left:1.5500pt;
            mso-para-margin-top:0.5000gd;mso-para-margin-bottom:0.5000gd;mso-para-margin-left:0.1500gd;
            text-indent:24.0000pt;mso-char-indent-count:2.0000;"><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;
            mso-font-kerning:1.0000pt;"><font face="Times New Roman">4</font><font face="宋体">）对讲线：岗亭内的对讲主机到出入口读卡控制机布两芯屏蔽线。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;
            mso-font-kerning:1.0000pt;">&nbsp;</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:7.8000pt;margin-bottom:7.8000pt;mso-para-margin-top:0.5000gd;
            mso-para-margin-bottom:0.5000gd;text-indent:24.0000pt;mso-char-indent-count:2.0000;"><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;
            mso-font-kerning:1.0000pt;"><font face="Times New Roman">5</font><font face="宋体">）网线：如果是远程管理的网络版配置方案，须布网线；从各管理电脑到服务器。 </font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:7.8000pt;margin-bottom:7.8000pt;mso-para-margin-top:0.5000gd;
            mso-para-margin-bottom:0.5000gd;text-indent:24.0000pt;mso-char-indent-count:2.0000;"><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;
            mso-font-kerning:1.0000pt;"><font face="Times New Roman">6</font><font face="宋体">）视频线：从电脑到出入口摄像机，注意摄像机的电源线不得与聚光灯的电源线共用一根管（在使用低压供电摄像机时），更不能强弱电源线共用一条四芯护线套。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:7.8000pt;margin-bottom:7.8000pt;margin-left:1.5500pt;
            mso-para-margin-top:0.5000gd;mso-para-margin-bottom:0.5000gd;mso-para-margin-left:0.1500gd;
            text-indent:24.0000pt;mso-char-indent-count:2.0000;"><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;
            mso-font-kerning:1.0000pt;"><font face="Times New Roman">7</font><font face="宋体">）地感线：在切好的地感槽内，用</font><font face="Times New Roman">BV1.5</font><font face="宋体">线绕</font><font face="Times New Roman">5</font><font face="宋体">圈</font><font face="Times New Roman">.</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:7.8000pt;margin-bottom:7.8000pt;margin-left:1.5500pt;
            mso-para-margin-top:0.5000gd;mso-para-margin-bottom:0.5000gd;mso-para-margin-left:0.1500gd;
            text-indent:24.0000pt;mso-char-indent-count:2.0000;"><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;
            mso-font-kerning:1.0000pt;"><font face="Times New Roman">8</font><font face="宋体">）电源线（从配电室到岗亭、出入口道闸、出入口读卡机、聚光灯、摄像机）在布线施工时要求距离地感线圈的最小安全距离不能小于</font></span><span style="mso-spacerun:'yes';font-family:'Times New Roman';font-size:12.0000pt;
            mso-font-kerning:1.0000pt;">1</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">米。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:7.8000pt;margin-bottom:7.8000pt;margin-left:1.5500pt;
            mso-para-margin-top:0.5000gd;mso-para-margin-bottom:0.5000gd;mso-para-margin-left:0.1500gd;
            text-indent:24.0000pt;mso-char-indent-count:2.0000;"><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;
            mso-font-kerning:1.0000pt;"><font face="Times New Roman">9</font><font face="宋体">）所有的管线必须按强弱电分开，导线中间不许有接头。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:7.8000pt;margin-bottom:7.8000pt;mso-para-margin-top:0.5000gd;
            mso-para-margin-bottom:0.5000gd;text-indent:24.0000pt;mso-char-indent-count:2.0000;"><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;
            mso-font-kerning:1.0000pt;"><font face="Times New Roman">10</font><font face="宋体">）聚光灯开关无特殊要求一般在岗亭控制。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:7.8000pt;margin-bottom:7.8000pt;mso-para-margin-top:0.5000gd;
            mso-para-margin-bottom:0.5000gd;text-indent:24.0000pt;mso-char-indent-count:2.0000;"><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;
            mso-font-kerning:1.0000pt;"><font face="宋体">线缆标准</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;
            mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><table class="MsoNormalTable" border="1" cellspacing="0" style="border-collapse:collapse;width:466.1000pt;mso-table-layout-alt:fixed;
            border:none;mso-border-left-alt:0.5000pt solid rgb(0,0,0);mso-border-top-alt:0.5000pt solid rgb(0,0,0);
            mso-border-right-alt:0.5000pt solid rgb(0,0,0);mso-border-bottom-alt:0.5000pt solid rgb(0,0,0);mso-border-insideh:0.5000pt solid rgb(0,0,0);
            mso-border-insidev:0.5000pt solid rgb(0,0,0);mso-padding-alt:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;"><tbody><tr><td width="66" valign="top" style="width:33.1500pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid rgb(0,0,0);
            mso-border-left-alt:0.5000pt solid rgb(0,0,0);border-right:1.0000pt solid rgb(0,0,0);mso-border-right-alt:0.5000pt solid rgb(0,0,0);
            border-top:1.0000pt solid rgb(0,0,0);mso-border-top-alt:0.5000pt solid rgb(0,0,0);border-bottom:1.0000pt solid rgb(0,0,0);
            mso-border-bottom-alt:0.5000pt solid rgb(0,0,0);"><p class="MsoNormal" style="margin-top:7.8000pt;margin-bottom:7.8000pt;mso-para-margin-top:0.5000gd;
            mso-para-margin-bottom:0.5000gd;"><span style="font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="宋体">用途</font></span><span style="font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td><td width="100" valign="top" style="width:50.2500pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid rgb(0,0,0);
            mso-border-left-alt:0.5000pt solid rgb(0,0,0);border-right:1.0000pt solid rgb(0,0,0);mso-border-right-alt:0.5000pt solid rgb(0,0,0);
            border-top:1.0000pt solid rgb(0,0,0);mso-border-top-alt:0.5000pt solid rgb(0,0,0);border-bottom:1.0000pt solid rgb(0,0,0);
            mso-border-bottom-alt:0.5000pt solid rgb(0,0,0);"><p class="MsoNormal" style="margin-top:7.8000pt;margin-bottom:7.8000pt;mso-para-margin-top:0.5000gd;
            mso-para-margin-bottom:0.5000gd;"><span style="font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="宋体">地感</font></span><span style="font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td><td width="113" valign="top" style="width:56.7000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid rgb(0,0,0);
            mso-border-left-alt:0.5000pt solid rgb(0,0,0);border-right:1.0000pt solid rgb(0,0,0);mso-border-right-alt:0.5000pt solid rgb(0,0,0);
            border-top:1.0000pt solid rgb(0,0,0);mso-border-top-alt:0.5000pt solid rgb(0,0,0);border-bottom:1.0000pt solid rgb(0,0,0);
            mso-border-bottom-alt:0.5000pt solid rgb(0,0,0);"><p class="MsoNormal" style="margin-top:7.8000pt;margin-bottom:7.8000pt;mso-para-margin-top:0.5000gd;
            mso-para-margin-bottom:0.5000gd;"><span style="font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="宋体">电源</font></span><span style="font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td><td width="85" valign="top" style="width:42.5000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid rgb(0,0,0);
            mso-border-left-alt:0.5000pt solid rgb(0,0,0);border-right:1.0000pt solid rgb(0,0,0);mso-border-right-alt:0.5000pt solid rgb(0,0,0);
            border-top:1.0000pt solid rgb(0,0,0);mso-border-top-alt:0.5000pt solid rgb(0,0,0);border-bottom:1.0000pt solid rgb(0,0,0);
            mso-border-bottom-alt:0.5000pt solid rgb(0,0,0);"><p class="MsoNormal" style="margin-top:7.8000pt;margin-bottom:7.8000pt;mso-para-margin-top:0.5000gd;
            mso-para-margin-bottom:0.5000gd;"><span style="font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="宋体">通讯</font></span><span style="font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td><td width="78" valign="top" style="width:39.3500pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid rgb(0,0,0);
            mso-border-left-alt:0.5000pt solid rgb(0,0,0);border-right:1.0000pt solid rgb(0,0,0);mso-border-right-alt:0.5000pt solid rgb(0,0,0);
            border-top:1.0000pt solid rgb(0,0,0);mso-border-top-alt:0.5000pt solid rgb(0,0,0);border-bottom:1.0000pt solid rgb(0,0,0);
            mso-border-bottom-alt:0.5000pt solid rgb(0,0,0);"><p class="MsoNormal" style="margin-top:7.8000pt;margin-bottom:7.8000pt;mso-para-margin-top:0.5000gd;
            mso-para-margin-bottom:0.5000gd;"><span style="font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="宋体">对讲</font></span><span style="font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td><td width="98" valign="top" style="width:49.0500pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid rgb(0,0,0);
            mso-border-left-alt:0.5000pt solid rgb(0,0,0);border-right:1.0000pt solid rgb(0,0,0);mso-border-right-alt:0.5000pt solid rgb(0,0,0);
            border-top:1.0000pt solid rgb(0,0,0);mso-border-top-alt:0.5000pt solid rgb(0,0,0);border-bottom:1.0000pt solid rgb(0,0,0);
            mso-border-bottom-alt:0.5000pt solid rgb(0,0,0);"><p class="MsoNormal" style="margin-top:7.8000pt;margin-bottom:7.8000pt;mso-para-margin-top:0.5000gd;
            mso-para-margin-bottom:0.5000gd;"><span style="font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="宋体">按钮</font></span><span style="font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td><td width="98" valign="top" style="width:49.0500pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid rgb(0,0,0);
            mso-border-left-alt:0.5000pt solid rgb(0,0,0);border-right:1.0000pt solid rgb(0,0,0);mso-border-right-alt:0.5000pt solid rgb(0,0,0);
            border-top:1.0000pt solid rgb(0,0,0);mso-border-top-alt:0.5000pt solid rgb(0,0,0);border-bottom:1.0000pt solid rgb(0,0,0);
            mso-border-bottom-alt:0.5000pt solid rgb(0,0,0);"><p class="MsoNormal" style="margin-top:7.8000pt;margin-bottom:7.8000pt;mso-para-margin-top:0.5000gd;
            mso-para-margin-bottom:0.5000gd;"><span style="font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="宋体">开闸</font></span><span style="font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td><td width="102" valign="top" style="width:51.1000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid rgb(0,0,0);
            mso-border-left-alt:0.5000pt solid rgb(0,0,0);border-right:1.0000pt solid rgb(0,0,0);mso-border-right-alt:0.5000pt solid rgb(0,0,0);
            border-top:1.0000pt solid rgb(0,0,0);mso-border-top-alt:0.5000pt solid rgb(0,0,0);border-bottom:1.0000pt solid rgb(0,0,0);
            mso-border-bottom-alt:0.5000pt solid rgb(0,0,0);"><p class="MsoNormal" style="margin-top:7.8000pt;margin-bottom:7.8000pt;mso-para-margin-top:0.5000gd;
            mso-para-margin-bottom:0.5000gd;"><span style="font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="宋体">视频</font></span><span style="font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td><td width="76" valign="top" style="width:38.2500pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid rgb(0,0,0);
            mso-border-left-alt:0.5000pt solid rgb(0,0,0);border-right:1.0000pt solid rgb(0,0,0);mso-border-right-alt:0.5000pt solid rgb(0,0,0);
            border-top:1.0000pt solid rgb(0,0,0);mso-border-top-alt:0.5000pt solid rgb(0,0,0);border-bottom:1.0000pt solid rgb(0,0,0);
            mso-border-bottom-alt:0.5000pt solid rgb(0,0,0);"><p class="MsoNormal" style="margin-top:7.8000pt;margin-bottom:7.8000pt;mso-para-margin-top:0.5000gd;
            mso-para-margin-bottom:0.5000gd;"><span style="font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="宋体">网络</font></span><span style="font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td><td width="113" valign="top" style="width:56.7000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid rgb(0,0,0);
            mso-border-left-alt:0.5000pt solid rgb(0,0,0);border-right:1.0000pt solid rgb(0,0,0);mso-border-right-alt:0.5000pt solid rgb(0,0,0);
            border-top:1.0000pt solid rgb(0,0,0);mso-border-top-alt:0.5000pt solid rgb(0,0,0);border-bottom:1.0000pt solid rgb(0,0,0);
            mso-border-bottom-alt:0.5000pt solid rgb(0,0,0);"><p class="MsoNormal" style="margin-top:7.8000pt;margin-bottom:7.8000pt;mso-para-margin-top:0.5000gd;
            mso-para-margin-bottom:0.5000gd;"><span style="font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="宋体">光纤</font></span><span style="font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td></tr><tr><td width="66" valign="top" style="width:33.1500pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid rgb(0,0,0);
            mso-border-left-alt:0.5000pt solid rgb(0,0,0);border-right:1.0000pt solid rgb(0,0,0);mso-border-right-alt:0.5000pt solid rgb(0,0,0);
            border-top:none;mso-border-top-alt:0.5000pt solid rgb(0,0,0);border-bottom:1.0000pt solid rgb(0,0,0);
            mso-border-bottom-alt:0.5000pt solid rgb(0,0,0);"><p class="MsoNormal" style="margin-top:7.8000pt;margin-bottom:7.8000pt;mso-para-margin-top:0.5000gd;
            mso-para-margin-bottom:0.5000gd;"><span style="font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="宋体">线芯</font></span><span style="font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td><td width="100" valign="top" style="width:50.2500pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid rgb(0,0,0);
            mso-border-left-alt:0.5000pt solid rgb(0,0,0);border-right:1.0000pt solid rgb(0,0,0);mso-border-right-alt:0.5000pt solid rgb(0,0,0);
            border-top:none;mso-border-top-alt:0.5000pt solid rgb(0,0,0);border-bottom:1.0000pt solid rgb(0,0,0);
            mso-border-bottom-alt:0.5000pt solid rgb(0,0,0);"><p class="MsoNormal" style="margin-top:7.8000pt;margin-bottom:7.8000pt;mso-para-margin-top:0.5000gd;
            mso-para-margin-bottom:0.5000gd;"><span style="font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="Times New Roman">1</font></span><span style="font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td><td width="113" valign="top" style="width:56.7000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid rgb(0,0,0);
            mso-border-left-alt:0.5000pt solid rgb(0,0,0);border-right:1.0000pt solid rgb(0,0,0);mso-border-right-alt:0.5000pt solid rgb(0,0,0);
            border-top:none;mso-border-top-alt:0.5000pt solid rgb(0,0,0);border-bottom:1.0000pt solid rgb(0,0,0);
            mso-border-bottom-alt:0.5000pt solid rgb(0,0,0);"><p class="MsoNormal" style="margin-top:7.8000pt;margin-bottom:7.8000pt;mso-para-margin-top:0.5000gd;
            mso-para-margin-bottom:0.5000gd;"><span style="font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="Times New Roman">3</font></span><span style="font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td><td width="85" valign="top" style="width:42.5000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid rgb(0,0,0);
            mso-border-left-alt:0.5000pt solid rgb(0,0,0);border-right:1.0000pt solid rgb(0,0,0);mso-border-right-alt:0.5000pt solid rgb(0,0,0);
            border-top:none;mso-border-top-alt:0.5000pt solid rgb(0,0,0);border-bottom:1.0000pt solid rgb(0,0,0);
            mso-border-bottom-alt:0.5000pt solid rgb(0,0,0);"><p class="MsoNormal" style="margin-top:7.8000pt;margin-bottom:7.8000pt;mso-para-margin-top:0.5000gd;
            mso-para-margin-bottom:0.5000gd;"><span style="font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="Times New Roman">2</font></span><span style="font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td><td width="78" valign="top" style="width:39.3500pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid rgb(0,0,0);
            mso-border-left-alt:0.5000pt solid rgb(0,0,0);border-right:1.0000pt solid rgb(0,0,0);mso-border-right-alt:0.5000pt solid rgb(0,0,0);
            border-top:none;mso-border-top-alt:0.5000pt solid rgb(0,0,0);border-bottom:1.0000pt solid rgb(0,0,0);
            mso-border-bottom-alt:0.5000pt solid rgb(0,0,0);"><p class="MsoNormal" style="margin-top:7.8000pt;margin-bottom:7.8000pt;mso-para-margin-top:0.5000gd;
            mso-para-margin-bottom:0.5000gd;"><span style="font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="Times New Roman">2</font></span><span style="font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td><td width="98" valign="top" style="width:49.0500pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid rgb(0,0,0);
            mso-border-left-alt:0.5000pt solid rgb(0,0,0);border-right:1.0000pt solid rgb(0,0,0);mso-border-right-alt:0.5000pt solid rgb(0,0,0);
            border-top:none;mso-border-top-alt:0.5000pt solid rgb(0,0,0);border-bottom:1.0000pt solid rgb(0,0,0);
            mso-border-bottom-alt:0.5000pt solid rgb(0,0,0);"><p class="MsoNormal" style="margin-top:7.8000pt;margin-bottom:7.8000pt;mso-para-margin-top:0.5000gd;
            mso-para-margin-bottom:0.5000gd;"><span style="font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="Times New Roman">6</font></span><span style="font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td><td width="98" valign="top" style="width:49.0500pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid rgb(0,0,0);
            mso-border-left-alt:0.5000pt solid rgb(0,0,0);border-right:1.0000pt solid rgb(0,0,0);mso-border-right-alt:0.5000pt solid rgb(0,0,0);
            border-top:none;mso-border-top-alt:0.5000pt solid rgb(0,0,0);border-bottom:1.0000pt solid rgb(0,0,0);
            mso-border-bottom-alt:0.5000pt solid rgb(0,0,0);"><p class="MsoNormal" style="margin-top:7.8000pt;margin-bottom:7.8000pt;mso-para-margin-top:0.5000gd;
            mso-para-margin-bottom:0.5000gd;"><span style="font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="Times New Roman">6</font></span><span style="font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td><td width="102" valign="top" style="width:51.1000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid rgb(0,0,0);
            mso-border-left-alt:0.5000pt solid rgb(0,0,0);border-right:1.0000pt solid rgb(0,0,0);mso-border-right-alt:0.5000pt solid rgb(0,0,0);
            border-top:none;mso-border-top-alt:0.5000pt solid rgb(0,0,0);border-bottom:1.0000pt solid rgb(0,0,0);
            mso-border-bottom-alt:0.5000pt solid rgb(0,0,0);"><p class="MsoNormal" style="margin-top:7.8000pt;margin-bottom:7.8000pt;mso-para-margin-top:0.5000gd;
            mso-para-margin-bottom:0.5000gd;"><span style="font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="宋体">同轴</font></span><span style="font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td><td width="76" valign="top" style="width:38.2500pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid rgb(0,0,0);
            mso-border-left-alt:0.5000pt solid rgb(0,0,0);border-right:1.0000pt solid rgb(0,0,0);mso-border-right-alt:0.5000pt solid rgb(0,0,0);
            border-top:none;mso-border-top-alt:0.5000pt solid rgb(0,0,0);border-bottom:1.0000pt solid rgb(0,0,0);
            mso-border-bottom-alt:0.5000pt solid rgb(0,0,0);"><p class="MsoNormal" style="margin-top:7.8000pt;margin-bottom:7.8000pt;mso-para-margin-top:0.5000gd;
            mso-para-margin-bottom:0.5000gd;"><span style="font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="Times New Roman">8</font></span><span style="font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td><td width="113" valign="top" style="width:56.7000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid rgb(0,0,0);
            mso-border-left-alt:0.5000pt solid rgb(0,0,0);border-right:1.0000pt solid rgb(0,0,0);mso-border-right-alt:0.5000pt solid rgb(0,0,0);
            border-top:none;mso-border-top-alt:0.5000pt solid rgb(0,0,0);border-bottom:1.0000pt solid rgb(0,0,0);
            mso-border-bottom-alt:0.5000pt solid rgb(0,0,0);"><p class="MsoNormal" style="margin-top:7.8000pt;margin-bottom:7.8000pt;mso-para-margin-top:0.5000gd;
            mso-para-margin-bottom:0.5000gd;"><span style="font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="Times New Roman">2</font></span><span style="font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td></tr><tr><td width="66" valign="top" style="width:33.1500pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid rgb(0,0,0);
            mso-border-left-alt:0.5000pt solid rgb(0,0,0);border-right:1.0000pt solid rgb(0,0,0);mso-border-right-alt:0.5000pt solid rgb(0,0,0);
            border-top:none;mso-border-top-alt:0.5000pt solid rgb(0,0,0);border-bottom:1.0000pt solid rgb(0,0,0);
            mso-border-bottom-alt:0.5000pt solid rgb(0,0,0);"><p class="MsoNormal" style="margin-top:7.8000pt;margin-bottom:7.8000pt;mso-para-margin-top:0.5000gd;
            mso-para-margin-bottom:0.5000gd;"><span style="font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="宋体">规格</font></span><span style="font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td><td width="100" valign="top" style="width:50.2500pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid rgb(0,0,0);
            mso-border-left-alt:0.5000pt solid rgb(0,0,0);border-right:1.0000pt solid rgb(0,0,0);mso-border-right-alt:0.5000pt solid rgb(0,0,0);
            border-top:none;mso-border-top-alt:0.5000pt solid rgb(0,0,0);border-bottom:1.0000pt solid rgb(0,0,0);
            mso-border-bottom-alt:0.5000pt solid rgb(0,0,0);"><p class="MsoNormal" style="margin-top:7.8000pt;margin-bottom:7.8000pt;mso-para-margin-top:0.5000gd;
            mso-para-margin-bottom:0.5000gd;"><span style="font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="Times New Roman">1mm</font></span><sup><span style="font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;
            vertical-align:super;"><font face="Times New Roman">2</font></span></sup><sup><span style="font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;
            vertical-align:super;"><o:p></o:p></span></sup></p></td><td width="113" valign="top" style="width:56.7000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid rgb(0,0,0);
            mso-border-left-alt:0.5000pt solid rgb(0,0,0);border-right:1.0000pt solid rgb(0,0,0);mso-border-right-alt:0.5000pt solid rgb(0,0,0);
            border-top:none;mso-border-top-alt:0.5000pt solid rgb(0,0,0);border-bottom:1.0000pt solid rgb(0,0,0);
            mso-border-bottom-alt:0.5000pt solid rgb(0,0,0);"><p class="MsoNormal" style="margin-top:7.8000pt;margin-bottom:7.8000pt;mso-para-margin-top:0.5000gd;
            mso-para-margin-bottom:0.5000gd;"><span style="font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="Times New Roman">1mm</font></span><sup><span style="font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;
            vertical-align:super;"><font face="Times New Roman">2 </font></span></sup><span style="font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="宋体">软线</font></span><span style="font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td><td width="85" valign="top" style="width:42.5000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid rgb(0,0,0);
            mso-border-left-alt:0.5000pt solid rgb(0,0,0);border-right:1.0000pt solid rgb(0,0,0);mso-border-right-alt:0.5000pt solid rgb(0,0,0);
            border-top:none;mso-border-top-alt:0.5000pt solid rgb(0,0,0);border-bottom:1.0000pt solid rgb(0,0,0);
            mso-border-bottom-alt:0.5000pt solid rgb(0,0,0);"><p class="MsoNormal" style="margin-top:7.8000pt;margin-bottom:7.8000pt;mso-para-margin-top:0.5000gd;
            mso-para-margin-bottom:0.5000gd;"><span style="font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="Times New Roman">1.0mm</font></span><sup><span style="font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;
            vertical-align:super;"><font face="Times New Roman">2</font></span></sup><sup><span style="font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;
            vertical-align:super;"><o:p></o:p></span></sup></p></td><td width="78" valign="top" style="width:39.3500pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid rgb(0,0,0);
            mso-border-left-alt:0.5000pt solid rgb(0,0,0);border-right:1.0000pt solid rgb(0,0,0);mso-border-right-alt:0.5000pt solid rgb(0,0,0);
            border-top:none;mso-border-top-alt:0.5000pt solid rgb(0,0,0);border-bottom:1.0000pt solid rgb(0,0,0);
            mso-border-bottom-alt:0.5000pt solid rgb(0,0,0);"><p class="MsoNormal" style="margin-top:7.8000pt;margin-bottom:7.8000pt;mso-para-margin-top:0.5000gd;
            mso-para-margin-bottom:0.5000gd;"><span style="font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="Times New Roman">1.0mm</font></span><sup><span style="font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;
            vertical-align:super;"><font face="Times New Roman">2</font></span></sup><sup><span style="font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;
            vertical-align:super;"><o:p></o:p></span></sup></p></td><td width="98" valign="top" style="width:49.0500pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid rgb(0,0,0);
            mso-border-left-alt:0.5000pt solid rgb(0,0,0);border-right:1.0000pt solid rgb(0,0,0);mso-border-right-alt:0.5000pt solid rgb(0,0,0);
            border-top:none;mso-border-top-alt:0.5000pt solid rgb(0,0,0);border-bottom:1.0000pt solid rgb(0,0,0);
            mso-border-bottom-alt:0.5000pt solid rgb(0,0,0);"><p class="MsoNormal" style="margin-top:7.8000pt;margin-bottom:7.8000pt;mso-para-margin-top:0.5000gd;
            mso-para-margin-bottom:0.5000gd;"><span style="font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="Times New Roman">0.75mm</font></span><sup><span style="font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;
            vertical-align:super;"><font face="Times New Roman">2</font></span></sup><sup><span style="font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;
            vertical-align:super;"><o:p></o:p></span></sup></p></td><td width="98" valign="top" style="width:49.0500pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid rgb(0,0,0);
            mso-border-left-alt:0.5000pt solid rgb(0,0,0);border-right:1.0000pt solid rgb(0,0,0);mso-border-right-alt:0.5000pt solid rgb(0,0,0);
            border-top:none;mso-border-top-alt:0.5000pt solid rgb(0,0,0);border-bottom:1.0000pt solid rgb(0,0,0);
            mso-border-bottom-alt:0.5000pt solid rgb(0,0,0);"><p class="MsoNormal" style="margin-top:7.8000pt;margin-bottom:7.8000pt;mso-para-margin-top:0.5000gd;
            mso-para-margin-bottom:0.5000gd;"><span style="font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="Times New Roman">0.75mm</font></span><sup><span style="font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;
            vertical-align:super;"><font face="Times New Roman">2</font></span></sup><sup><span style="font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;
            vertical-align:super;"><o:p></o:p></span></sup></p></td><td width="102" valign="top" style="width:51.1000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid rgb(0,0,0);
            mso-border-left-alt:0.5000pt solid rgb(0,0,0);border-right:1.0000pt solid rgb(0,0,0);mso-border-right-alt:0.5000pt solid rgb(0,0,0);
            border-top:none;mso-border-top-alt:0.5000pt solid rgb(0,0,0);border-bottom:1.0000pt solid rgb(0,0,0);
            mso-border-bottom-alt:0.5000pt solid rgb(0,0,0);"><p class="MsoNormal" style="margin-top:7.8000pt;margin-bottom:7.8000pt;mso-para-margin-top:0.5000gd;
            mso-para-margin-bottom:0.5000gd;"><span style="font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="Times New Roman">75</font></span><span style="font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="宋体">Ω</font></span><span style="font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td><td width="76" valign="top" style="width:38.2500pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid rgb(0,0,0);
            mso-border-left-alt:0.5000pt solid rgb(0,0,0);border-right:1.0000pt solid rgb(0,0,0);mso-border-right-alt:0.5000pt solid rgb(0,0,0);
            border-top:none;mso-border-top-alt:0.5000pt solid rgb(0,0,0);border-bottom:1.0000pt solid rgb(0,0,0);
            mso-border-bottom-alt:0.5000pt solid rgb(0,0,0);"><p class="MsoNormal" style="margin-top:7.8000pt;margin-bottom:7.8000pt;mso-para-margin-top:0.5000gd;
            mso-para-margin-bottom:0.5000gd;"><span style="font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="宋体">双绞</font></span><span style="font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td><td width="113" valign="top" style="width:56.7000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid rgb(0,0,0);
            mso-border-left-alt:0.5000pt solid rgb(0,0,0);border-right:1.0000pt solid rgb(0,0,0);mso-border-right-alt:0.5000pt solid rgb(0,0,0);
            border-top:none;mso-border-top-alt:0.5000pt solid rgb(0,0,0);border-bottom:1.0000pt solid rgb(0,0,0);
            mso-border-bottom-alt:0.5000pt solid rgb(0,0,0);"><p class="MsoNormal" style="margin-top:7.8000pt;margin-bottom:7.8000pt;mso-para-margin-top:0.5000gd;
            mso-para-margin-bottom:0.5000gd;"><span style="font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="Times New Roman">62.5/125u</font></span><span style="font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td></tr><tr><td width="66" valign="top" style="width:33.1500pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid rgb(0,0,0);
            mso-border-left-alt:0.5000pt solid rgb(0,0,0);border-right:1.0000pt solid rgb(0,0,0);mso-border-right-alt:0.5000pt solid rgb(0,0,0);
            border-top:none;mso-border-top-alt:0.5000pt solid rgb(0,0,0);border-bottom:1.0000pt solid rgb(0,0,0);
            mso-border-bottom-alt:0.5000pt solid rgb(0,0,0);"><p class="MsoNormal" style="margin-top:7.8000pt;margin-bottom:7.8000pt;mso-para-margin-top:0.5000gd;
            mso-para-margin-bottom:0.5000gd;"><span style="font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="宋体">材质</font></span><span style="font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td><td width="100" valign="top" style="width:50.2500pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid rgb(0,0,0);
            mso-border-left-alt:0.5000pt solid rgb(0,0,0);border-right:1.0000pt solid rgb(0,0,0);mso-border-right-alt:0.5000pt solid rgb(0,0,0);
            border-top:none;mso-border-top-alt:0.5000pt solid rgb(0,0,0);border-bottom:1.0000pt solid rgb(0,0,0);
            mso-border-bottom-alt:0.5000pt solid rgb(0,0,0);"><p class="MsoNormal" style="margin-top:7.8000pt;margin-bottom:7.8000pt;mso-para-margin-top:0.5000gd;
            mso-para-margin-bottom:0.5000gd;"><span style="font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="宋体">铁氟龙</font></span><span style="font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td><td width="113" valign="top" style="width:56.7000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid rgb(0,0,0);
            mso-border-left-alt:0.5000pt solid rgb(0,0,0);border-right:1.0000pt solid rgb(0,0,0);mso-border-right-alt:0.5000pt solid rgb(0,0,0);
            border-top:none;mso-border-top-alt:0.5000pt solid rgb(0,0,0);border-bottom:1.0000pt solid rgb(0,0,0);
            mso-border-bottom-alt:0.5000pt solid rgb(0,0,0);"><p class="MsoNormal" style="margin-top:7.8000pt;margin-bottom:7.8000pt;mso-para-margin-top:0.5000gd;
            mso-para-margin-bottom:0.5000gd;"><span style="font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">R</span><span style="font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="Times New Roman">VV</font></span><span style="font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td><td width="85" valign="top" style="width:42.5000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid rgb(0,0,0);
            mso-border-left-alt:0.5000pt solid rgb(0,0,0);border-right:1.0000pt solid rgb(0,0,0);mso-border-right-alt:0.5000pt solid rgb(0,0,0);
            border-top:none;mso-border-top-alt:0.5000pt solid rgb(0,0,0);border-bottom:1.0000pt solid rgb(0,0,0);
            mso-border-bottom-alt:0.5000pt solid rgb(0,0,0);"><p class="MsoNormal" style="margin-top:7.8000pt;margin-bottom:7.8000pt;mso-para-margin-top:0.5000gd;
            mso-para-margin-bottom:0.5000gd;"><span style="font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="Times New Roman">RVSP</font></span><span style="font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td><td width="78" valign="top" style="width:39.3500pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid rgb(0,0,0);
            mso-border-left-alt:0.5000pt solid rgb(0,0,0);border-right:1.0000pt solid rgb(0,0,0);mso-border-right-alt:0.5000pt solid rgb(0,0,0);
            border-top:none;mso-border-top-alt:0.5000pt solid rgb(0,0,0);border-bottom:1.0000pt solid rgb(0,0,0);
            mso-border-bottom-alt:0.5000pt solid rgb(0,0,0);"><p class="MsoNormal" style="margin-top:7.8000pt;margin-bottom:7.8000pt;mso-para-margin-top:0.5000gd;
            mso-para-margin-bottom:0.5000gd;"><span style="font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="Times New Roman">RVVP</font></span><span style="font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td><td width="98" valign="top" style="width:49.0500pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid rgb(0,0,0);
            mso-border-left-alt:0.5000pt solid rgb(0,0,0);border-right:1.0000pt solid rgb(0,0,0);mso-border-right-alt:0.5000pt solid rgb(0,0,0);
            border-top:none;mso-border-top-alt:0.5000pt solid rgb(0,0,0);border-bottom:1.0000pt solid rgb(0,0,0);
            mso-border-bottom-alt:0.5000pt solid rgb(0,0,0);"><p class="MsoNormal" style="margin-top:7.8000pt;margin-bottom:7.8000pt;mso-para-margin-top:0.5000gd;
            mso-para-margin-bottom:0.5000gd;"><span style="font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="Times New Roman">RVVP</font></span><span style="font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td><td width="98" valign="top" style="width:49.0500pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid rgb(0,0,0);
            mso-border-left-alt:0.5000pt solid rgb(0,0,0);border-right:1.0000pt solid rgb(0,0,0);mso-border-right-alt:0.5000pt solid rgb(0,0,0);
            border-top:none;mso-border-top-alt:0.5000pt solid rgb(0,0,0);border-bottom:1.0000pt solid rgb(0,0,0);
            mso-border-bottom-alt:0.5000pt solid rgb(0,0,0);"><p class="MsoNormal" style="margin-top:7.8000pt;margin-bottom:7.8000pt;mso-para-margin-top:0.5000gd;
            mso-para-margin-bottom:0.5000gd;"><span style="font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="Times New Roman">RVVP</font></span><span style="font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td><td width="102" valign="top" style="width:51.1000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid rgb(0,0,0);
            mso-border-left-alt:0.5000pt solid rgb(0,0,0);border-right:1.0000pt solid rgb(0,0,0);mso-border-right-alt:0.5000pt solid rgb(0,0,0);
            border-top:none;mso-border-top-alt:0.5000pt solid rgb(0,0,0);border-bottom:1.0000pt solid rgb(0,0,0);
            mso-border-bottom-alt:0.5000pt solid rgb(0,0,0);"><p class="MsoNormal" style="margin-top:7.8000pt;margin-bottom:7.8000pt;mso-para-margin-top:0.5000gd;
            mso-para-margin-bottom:0.5000gd;"><span style="font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="Times New Roman">SYV75-5</font></span><span style="font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td><td width="76" valign="top" style="width:38.2500pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid rgb(0,0,0);
            mso-border-left-alt:0.5000pt solid rgb(0,0,0);border-right:1.0000pt solid rgb(0,0,0);mso-border-right-alt:0.5000pt solid rgb(0,0,0);
            border-top:none;mso-border-top-alt:0.5000pt solid rgb(0,0,0);border-bottom:1.0000pt solid rgb(0,0,0);
            mso-border-bottom-alt:0.5000pt solid rgb(0,0,0);"><p class="MsoNormal" style="margin-top:7.8000pt;margin-bottom:7.8000pt;mso-para-margin-top:0.5000gd;
            mso-para-margin-bottom:0.5000gd;"><span style="font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="宋体">五类</font></span><span style="font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td><td width="113" valign="top" style="width:56.7000pt;padding:0.0000pt 5.4000pt 0.0000pt 5.4000pt ;border-left:1.0000pt solid rgb(0,0,0);
            mso-border-left-alt:0.5000pt solid rgb(0,0,0);border-right:1.0000pt solid rgb(0,0,0);mso-border-right-alt:0.5000pt solid rgb(0,0,0);
            border-top:none;mso-border-top-alt:0.5000pt solid rgb(0,0,0);border-bottom:1.0000pt solid rgb(0,0,0);
            mso-border-bottom-alt:0.5000pt solid rgb(0,0,0);"><p class="MsoNormal" style="margin-top:7.8000pt;margin-bottom:7.8000pt;mso-para-margin-top:0.5000gd;
            mso-para-margin-bottom:0.5000gd;"><span style="font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><font face="宋体">多模</font></span><span style="font-family:宋体;font-size:10.5000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p></td></tr></tbody></table><p class="MsoNormal" style="margin-top:7.8000pt;margin-bottom:7.8000pt;mso-para-margin-top:0.5000gd;
            mso-para-margin-bottom:0.5000gd;"><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;
            mso-font-kerning:1.0000pt;"><o:p>&nbsp;</o:p></span></p><p class="MsoNormal" style="margin-top:7.8000pt;margin-bottom:7.8000pt;margin-left:18.0000pt;
            mso-para-margin-top:0.5000gd;mso-para-margin-bottom:0.5000gd;"><span style="mso-spacerun:'yes';font-family:'Times New Roman';font-size:12.0000pt;
            mso-font-kerning:1.0000pt;"><o:p>&nbsp;</o:p></span></p></div>`
        }, 
        {
            id:11,
            title:'网络监控电视墙设计方案',
            html:`<div class="Section0" style="layout-grid:15.6000pt;"><h1 align="center" style="margin-left:0.0000pt;text-indent:0.0000pt;word-break:break-all;
            mso-pagination:widow-orphan;text-align:center;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:黑体;mso-hansi-font-family:宋体;
            color:rgb(51,51,51);letter-spacing:0.0000pt;mso-ansi-font-weight:normal;
            text-transform:none;mso-ansi-font-style:normal;font-size:15.0000pt;
            mso-font-kerning:22.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><font face="黑体">网络监控电视墙设计方案</font></span><span style="mso-spacerun:'yes';font-family:黑体;mso-hansi-font-family:宋体;
            color:rgb(51,51,51);letter-spacing:0.0000pt;mso-ansi-font-weight:normal;
            text-transform:none;mso-ansi-font-style:normal;font-size:15.0000pt;
            mso-font-kerning:22.0000pt;"><o:p></o:p></span></h1><p class="p" style="margin-left:0.0000pt;text-indent:21.0000pt;word-break:break-all;
            mso-pagination:widow-orphan;line-height:18.9000pt;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:Verdana;mso-fareast-font-family:宋体;
            color:rgb(51,51,51);letter-spacing:0.0000pt;mso-ansi-font-weight:normal;
            text-transform:none;mso-ansi-font-style:normal;font-size:10.5000pt;
            mso-font-kerning:0.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><font face="宋体">网络监控电视墙一般有两种显示方式，一种是把网络视频信号通过网络视频解码器或视频解码卡解码成模拟视频信号，再输送到模拟电视墙；另外一种是把网络视频信号直接通过</font><font face="Verdana">PC</font><font face="宋体">计算机直接解码显示到显示器上，给</font><font face="Verdana">PC</font><font face="宋体">计算机配置多路输出单显示卡或配置多显示卡可以增加显示器输出数量。上述两种方案一般都可以通过专用控制软件来对输出图像进行控制。</font></span><span style="mso-spacerun:'yes';font-family:Verdana;mso-fareast-font-family:宋体;
            color:rgb(51,51,51);letter-spacing:0.0000pt;mso-ansi-font-weight:normal;
            text-transform:none;mso-ansi-font-style:normal;font-size:10.5000pt;
            mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-left:0.0000pt;text-indent:21.0000pt;word-break:break-all;
            mso-pagination:widow-orphan;line-height:18.9000pt;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:Verdana;mso-fareast-font-family:宋体;
            color:rgb(51,51,51);letter-spacing:0.0000pt;mso-ansi-font-weight:normal;
            text-transform:none;mso-ansi-font-style:normal;font-size:10.5000pt;
            mso-font-kerning:0.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><font face="宋体">本方案介绍的是以北京百科博公司的电视客户端软件和</font><font face="Verdana">DVR</font><font face="宋体">或</font><font face="Verdana">NVR</font><font face="宋体">软件为基础的以显示器为显示终端的网络监控电视墙解决方案。</font></span><span style="mso-spacerun:'yes';font-family:Verdana;mso-fareast-font-family:宋体;
            color:rgb(51,51,51);letter-spacing:0.0000pt;mso-ansi-font-weight:normal;
            text-transform:none;mso-ansi-font-style:normal;font-size:10.5000pt;
            mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-left:0.0000pt;text-indent:21.0000pt;word-break:break-all;
            mso-pagination:widow-orphan;line-height:18.9000pt;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:Verdana;mso-fareast-font-family:宋体;
            color:rgb(51,51,51);letter-spacing:0.0000pt;mso-ansi-font-weight:normal;
            text-transform:none;mso-ansi-font-style:normal;font-size:10.5000pt;
            mso-font-kerning:0.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><font face="宋体">电视客户端是以</font><font face="Verdana">PC</font><font face="宋体">计算机作为视频图像解码设备并以显示器作为视频图像显示终端设备来显示网络传输来的网络视频信号，支持多画面分割显示、单屏显示和画面轮循切换显示等多种显示模式。</font></span><span style="mso-spacerun:'yes';font-family:Verdana;mso-fareast-font-family:宋体;
            color:rgb(51,51,51);letter-spacing:0.0000pt;mso-ansi-font-weight:normal;
            text-transform:none;mso-ansi-font-style:normal;font-size:10.5000pt;
            mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-left:0.0000pt;text-indent:21.0000pt;word-break:break-all;
            mso-pagination:widow-orphan;line-height:18.9000pt;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:Verdana;mso-fareast-font-family:宋体;
            color:rgb(51,51,51);letter-spacing:0.0000pt;mso-ansi-font-weight:normal;
            text-transform:none;mso-ansi-font-style:normal;font-size:10.5000pt;
            mso-font-kerning:0.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);">IDRS-PC&nbsp;Server<font face="宋体">、</font><font face="Verdana">NVR</font><font face="宋体">客户端或</font><font face="Verdana">IDRS-NVMS</font><font face="宋体">集中监控管理系统中的中心管理服务器、流媒体服务器和集中管理客户端都可以作为主控设备来控制电视客户端，一台主控设备可以同时控制多台电视客户端，但所有电视客户端的显示器总数不能超过</font><font face="Verdana">24</font><font face="宋体">台（即一台主控设备最多能控制由</font><font face="Verdana">24</font><font face="宋体">台显示器组建的显示器墙）。</font></span><span style="mso-spacerun:'yes';font-family:Verdana;mso-fareast-font-family:宋体;
            color:rgb(51,51,51);letter-spacing:0.0000pt;mso-ansi-font-weight:normal;
            text-transform:none;mso-ansi-font-style:normal;font-size:10.5000pt;
            mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="MsoNormal"><span style="mso-spacerun:'yes';font-family:Verdana;mso-fareast-font-family:宋体;
            color:rgb(51,51,51);letter-spacing:0.0000pt;mso-ansi-font-weight:normal;
            text-transform:none;mso-ansi-font-style:normal;font-size:10.5000pt;
            mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><o:p>&nbsp;</o:p></span></p></div>`
        },{
            id:12,
            title:'这些古代的城市名，字字如诗',
            html:'',
            refId:11
        }
        ,{
            id:13,
            title:'电子产品时代，一定要注意用眼方式',
            html:'',
            refId:11
        }
        ,{
            id:14,
            title:'平面设计基础常识汇总',
            html:'',
            refId:11
        }
        ,{
            id:15,
            title:'您真的知道工作站与传统电脑有什么',
            html:'',
            refId:11
        }
        ,{
            id:16,
            title:'电视清晰度的分别',
            html:'',
            refId:11
        }
        ,{
            id:17,
            title:'美术基础知识',
            html:'',
            refId:11
        }
        ,{
            id:18,
            title:'体育运动小常识',
            html:'',
            refId:11
        }
        ,{
            id:19,
            title:'这些商标注册骗局，你千万不要上当',
            html:'',
            refId:11
        }
        ,{
            id:20,
            title:'脱贫哭穷停发报酬 这个问题很严肃',
            html:'',
            refId:11
        }
        ,{
            id:21,
            title:'三十年河东，三十年河西，莫欺少年穷是什么意思',
            html:'',
            refId:11
        }
        ,{
            id:22,
            title:'数字谐音大全、数字含义、阿拉伯数字谐音的意思',
            html:'',
            refId:11
        }
   ]
}